import { ErrorMessage } from '@hookform/error-message'
import { useEffect, useState } from 'react'
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'

import './BookingPersons.scss'
import BookHeaderMobile from './components/book-header-mobile/BookHeaderMobile'
import BookHeader from './components/book-header/BookHeader'
import SummaryMobile from './components/summary-mobile/SummaryMobile'
import SummaryWidget from './components/SummaryWidget/SummaryWidget'
import ButtonBook from './components/ui/button-book/ButtonBook'
import { personTitleOptions, storeBooking } from './constants'

function BookingPersons() {
	const [store, setStore] = useState<storeBooking>()
	const [widgetShow, setWidgetShow] = useState(false)

	const handleWidgetShow = (val: boolean) => {
		setWidgetShow(val)
	}

	const {
		handleSubmit,
		reset,
		control,
		register,
		setValue,
		formState: { errors, isSubmitting }
	} = useForm()
	const { fields, append, prepend, remove, swap, move, insert, replace } =
		useFieldArray({
			control,
			name: 'bookPersons'
		})

	useEffect(() => {
		const storeBook: storeBooking = JSON.parse(
			localStorage.getItem('booking') || ''
		)

		if (storeBook.bookPersons) {
			setValue('bookPersons', storeBook.bookPersons)
		} else {
			setValue('bookPersons', [])
		}
		setStore(storeBook)
	}, [])

	

	const personsCount = store?.bookPerson
	const bookInfo = store?.bookInfo

	const room = store?.bookRoom

	const baggage = store?.bookBaggage

	const baggagePrice = baggage
		? baggage.reduce(
				// @ts-ignore
				(accumulator, currentValue) => accumulator + currentValue.price,
				0
			)
		: 0

	const insurance = store?.bookInsurance
	const insurancePrice = insurance
		? insurance.reduce(
				// @ts-ignore
				(accumulator, currentValue) => accumulator + currentValue.price,
				0
			)
		: 0

	// @ts-ignore
	const onSubmit = data => {
		

		const storeBook: storeBooking = JSON.parse(
			localStorage.getItem('booking') || ''
		)

		if (storeBook) {
			storeBook.bookPersons = data.bookPersons
		}

		localStorage.setItem('booking', JSON.stringify(storeBook))

		// navigate('/booking-payment')
	}

	return (
		<>
			<BookHeader />
			<SummaryMobile setWidgetShow={handleWidgetShow} widgetShow={widgetShow} />

			<BookHeaderMobile step={3} />
			<div className='booking-persons'>
				<div className='booking-persons__form'>
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* @ts-ignore */}
						<div className='booking-persons__inner'>
							<div className='booking-persons__container'>
								<div className='booking-persons__block'>
									<h1 className='booking-persons__title subtitle center blue'>
										Данные о пассажирах
									</h1>
									<p className='text center'>
										Имена должны быть введены правильно, как в паспорте. <br />{' '}
										Могут применяться сборы за изменение имени для исправления
										ошибок и разрешения на поездку.
									</p>
								</div>

								{fields.map((b, index1) => {
									return (
										<section
											key={b.id}
											className='booking-persons__section section'
										>
											<p className='booking-persons__section-title subtitle center blue'>
												Пассажир {index1 + 1}
											</p>
											<div className='booking-persons__section-block'>
												<div className='booking-persons__row'>
													<div
														key={index1}
														className='booking-persons__field booking-persons__field--title form-field'
													>
														<label className='form-field__label'>Пол</label>

														<ErrorMessage
															errors={errors}
															name={`bookPersons.${index1}.title`}
															render={({ message }) => (
																<span className='form-field__error'>
																	{message}
																</span>
															)}
														/>

														<Controller
															control={control}
															rules={{
																validate: value =>
																	value.value !== '' || 'обязательно'
															}}
															name={`bookPersons.${index1}.title`}
															render={({ field: { onChange, value, ref } }) => (
																<Select
																	ref={elem => {
																		ref({
																			...elem,
																			focus: () => {
																				elem?.inputRef?.scrollIntoView({
																					behavior: 'smooth',
																					block: 'center',
																					inline: 'nearest'
																				})
																				elem?.inputRef?.focus({
																					preventScroll: true
																				})
																			}
																		})
																	}}
																	onChange={onChange}
																	value={value}
																	classNamePrefix='select'
																	isClearable={false}
																	components={{
																		IndicatorSeparator: () => null
																	}}
																	isSearchable={true}
																	options={personTitleOptions}
																	styles={{
																		singleValue: base => ({
																			...base,
																			padding: 5,
																			color: '#002d72',
																			fontWeight: 600,
																			display: 'flex'
																		}),

																		control: base => ({
																			...base,
																			height: '68px',

																			border: '1px solid rgb(0  0 0/ 22%)',
																			boxShadow: 'none',
																			'&:hover': {
																				border: '1px solid rgb(0  0 0/ 22%)'
																			}
																		}),

																		menu: base => ({
																			...base,
																			zIndex: 2,
																			width: '88%',
																			left: '50px'
																		})
																	}}
																/>
															)}
														/>
													</div>

													<div className='booking-persons__field booking-persons__field--name form-field'>
														<label className='form-field__label'>Имя</label>
														<ErrorMessage
															errors={errors}
															name={`bookPersons.${index1}.firstName`}
															render={({ message }) => (
																<span className='form-field__error'>
																	{message}
																</span>
															)}
														/>

														<input
															{...register(`bookPersons.${index1}.firstName`, {
																required: 'обязательно'
															})}
															className='form-field__input'
														/>
													</div>
												</div>
												<div className='booking-persons__field form-field'>
													<label className='form-field__label'>Фамилия</label>
													<ErrorMessage
														errors={errors}
														name={`bookPersons.${index1}.middleName`}
														render={({ message }) => (
															<span className='form-field__error'>
																{message}
															</span>
														)}
													/>
													<input
														{...register(`bookPersons.${index1}.middleName`, {
															required: 'обязательно'
														})}
														className='form-field__input'
													/>
												</div>
												<div className='booking-persons__field form-field'>
													<label className='form-field__label'>Отчество</label>
													<ErrorMessage
														errors={errors}
														name={`bookPersons.${index1}.lastName`}
														render={({ message }) => (
															<span className='form-field__error'>
																{message}
															</span>
														)}
													/>
													<input
														{...register(`bookPersons.${index1}.lastName`, {
															required: 'обязательно'
														})}
														className='form-field__input'
													/>
												</div>
											</div>
											<div className='border'></div>
										</section>
									)
								})}
							</div>
						</div>
						<div className='booking-persons__btn'>
							<ButtonBook title='К оплате' />
						</div>
					</form>
				</div>
				<div className='booking-persons__widget-wrapper'>
					<div className='booking-persons__www'>
						<div
							className={
								widgetShow
									? 'booking-persons__widget'
									: 'booking-persons__widget booking-persons__widget--hidden'
							}
						>
							<SummaryWidget
								baggagePrice={baggagePrice}
								insurancePrice={insurancePrice}
								personsCount={personsCount ? parseInt(personsCount.value) : 0}
								tourDate={bookInfo?.dateFrom || ''}
								tourName={bookInfo?.tourName || ''}
								tourPrice={room ? room.price : 0}
								townTo={bookInfo?.townTo || ''}
							/>
							<div className='booking-persons__widget-btn '>
								<button
									className={widgetShow ? '' : 'pointer-none'}
									onClick={() => setWidgetShow(false)}
								>
									<span className='fi-rs-angle-up'></span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BookingPersons
