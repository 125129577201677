import 'photoswipe/style.css'
import { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import Pagination_ from './components/pagination/Pagination'
import RatingGraph from './components/rating-graph/RatingGraph'
import ReviewSmall from './components/review-small/ReviewSmall'
import GaleryFromButton from './components/ui/galery-from-button/GaleryFromButton'
import { pageToShowFirstLast, pageToShowMiddle, perPage } from './constants'
import { useQueryReviews } from './hooks/useQueryReviews'
import { useQueryTematicRatingBySlug } from './hooks/useQueryTematicRatingBySlug'
import './Reviews.scss'
import { EnumRating, GraphItem } from './types/types'
import { useQueryTourBySlug } from './hooks/useQueryTourBySlug'

const images = [
	{ id: 0, src: '/images/tematics/card1.jpg', width: 700, height: 500 },
	{ id: 1, src: '/images/tematics/card2.jpg', width: 700, height: 500 },
	{ id: 2, src: '/images/tematics/card3.jpg', width: 700, height: 500 },
	{ id: 3, src: '/images/tematics/card4.jpg', width: 700, height: 500 },
	{ id: 4, src: '/images/tematics/card5.jpg', width: 700, height: 500 },
	{ id: 5, src: '/images/tematics/card6.jpg', width: 700, height: 500 },
	{ id: 6, src: '/images/tematics/card7.jpg', width: 700, height: 500 },
	{ id: 7, src: '/images/tematics/card8.jpg', width: 700, height: 500 },
	{ id: 8, src: '/images/tematics/card9.jpg', width: 700, height: 500 },
	{ id: 9, src: '/images/tematics/card10.jpg', width: 700, height: 500 },
	{ id: 10, src: '/images/tematics/card11.jpg', width: 700, height: 500 },
	{ id: 11, src: '/images/tematics/card12.jpg', width: 700, height: 500 },
	{ id: 12, src: '/images/tematics/card13.jpg', width: 700, height: 500 },
	{ id: 13, src: '/images/tematics/card14.jpg', width: 700, height: 500 },
	{ id: 14, src: '/images/tematics/card15.jpg', width: 700, height: 500 },
	{ id: 15, src: '/images/tematics/card16.jpg', width: 700, height: 500 }
]

function Reviews() {
	let { slug } = useParams<'slug'>()

	let [searchParams, setSearchParams] = useSearchParams()
	let [page, setPage] = useState<number>(1)
	const [tematicGraph, setTematicGraph] = useState<GraphItem[]>([])

	useEffect(() => {
		setPage(Number(searchParams.get('page')?.toString() || '1'))
	}, [searchParams])

	const { data: tematic } = useQueryTourBySlug(slug || '')
	const { data: tematicRating } = useQueryTematicRatingBySlug(slug || '')

	const { data: reviewsList, isPending } = useQueryReviews()

	useEffect(() => {
		const graph: GraphItem[] = []
		graph.push({
			count: tematicRating?.rating_1 || 0,
			value: EnumRating.One,
			total: tematicRating?.total || 0
		})
		graph.push({
			count: tematicRating?.rating_2 || 0,
			value: EnumRating.Two,
			total: tematicRating?.total || 0
		})
		graph.push({
			count: tematicRating?.rating_3 || 0,
			value: EnumRating.Three,
			total: tematicRating?.total || 0
		})
		graph.push({
			count: tematicRating?.rating_4 || 0,
			value: EnumRating.Four,
			total: tematicRating?.total || 0
		})
		graph.push({
			count: tematicRating?.rating_5 || 0,
			value: EnumRating.Five,
			total: tematicRating?.total || 0
		})

		setTematicGraph(graph)
	}, [tematicRating])

	if (isPending) return <div>'Loading...'</div>

	return (
		<div className='reviews section'>
			<div className='reviews__container container'>
				<div className='reviews__back'>
					<Link to={`/tematics/${slug}`} className='reviews__back-link'>
						обратно к туру
					</Link>
				</div>

				<h1 className='reviews__title title left blue'>
					{tematic?.name} - отзывы
				</h1>
				<p className='reviews__count'>всего отзывов-{reviewsList?.length}</p>
				<div className='reviews__inner'>
					<div className='reviews__left'>
						<div className='reviews__top'>
							<div className='reviews__rating'>
								<span className='reviews__label'>Рейтинг тура</span>
								<div className='reviews__rating-value'>
									{tematicRating?.rating} / 5
								</div>
								<GaleryFromButton
									btnClass='reviews__btn-galery'
									btnTitle='123 фото путешественников'
									images={images}
								/>
							</div>
							<div className='reviews__graph'>
								<span className='reviews__label'>Оценки путешественников</span>

								<RatingGraph value={tematicGraph} />
							</div>
							<div className='reviews__types'>
								<span className='reviews__label'>Типы путешественников</span>
								<div className='reviews__type _alone'>
									<span className='reviews__type-icon fi-rs-person-simple'></span>
									<span className='reviews__type-name'>Одиночки</span>
								</div>
								<div className='reviews__type _couple'>
									<span className='reviews__type-icon fi-rs-people-pulling'></span>
									<span className='reviews__type-name'>Пары</span>
								</div>
								<div className='reviews__type _friends'>
									<span className='reviews__type-icon fi-rs-employees'></span>
									<span className='reviews__type-name'>Группа друзей</span>
								</div>
								<div className='reviews__type _children'>
									<span className='reviews__type-icon fi-rs-family'></span>
									<span className='reviews__type-name'>С детьми</span>
								</div>
							</div>
						</div>
						<div className='reviews__bottom'>
							{reviewsList
								?.filter(
									(r, index) =>
										r.id > (page - 1) * perPage && r.id <= page * perPage
								)
								.map(r => (
									<div key={r.id} className='reviews__review'>
										<ReviewSmall value={r} />
									</div>
								))}

							<Pagination_
								dataLength={reviewsList?.length || 0}
								page={page}
								perPage={perPage}
								pageToShowFirstLast={pageToShowFirstLast}
								pageToShowMiddle={pageToShowMiddle}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Reviews
