import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import About from './About'
import Blog from './Blog'
import BookingPersons from './BookingPersons'
import BookingRoom from './BookingRoom'
import ContactW from './components/contact-widget/ContactW'
import Follow from './components/folllows/Follow'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import MobileBar from './components/mobile-bar/MobileBar'
import Help from './Help'
import Main from './Main'
import Offers from './Offers'
import Park from './Park'
import Place from './Place'
import Places from './Places'
import Reviews from './Reviews'
import Tematic from './Tematic'
import Tematics from './Tematics'
import TematicsBookingDates from './TematicsBookingDates'
import Tour from './Tour'
import ToursBookingDates from './ToursBookingDates'
import ToursSelect from './ToursSelect'
import TravelBlog from './TravelBlog'
import { useEffect } from 'react'

export default function App() {



	const queryClient = new QueryClient()
	return (
		<QueryClientProvider client={queryClient}>
			<div className='wrapper'>
				<Header />

				<main>
					<Routes>
						<Route path='/' element={<Layout />}>
							<Route index element={<Main />} />
							<Route path='places' element={<Places />} />
							<Route path='offers' element={<Offers />} />
							<Route path='blog' element={<Blog />} />
							<Route path='help' element={<Help />} />
							<Route path='about' element={<About />} />
							<Route path='tematics' element={<Tematics />} />
							<Route path='tematics/:slug' element={<Tematic />} />
							<Route path='parks/:slug' element={<Park />} />
							<Route path='/tematics/:slug/reviews' element={<Reviews />} />
							<Route path='blog/:slug' element={<TravelBlog />} />
							<Route path='tours/:slug' element={<Tour />} />
							<Route path='places/:slug' element={<Place />} />
						</Route>
						<Route
							path='tours/:slug/booking-dates'
							element={<ToursBookingDates />}
						/>
						<Route
							path='tematics/:slug/booking-dates'
							element={<TematicsBookingDates />}
						/>
						<Route path='tours/select' element={<ToursSelect />} />
						<Route path='booking-room' element={<BookingRoom />} />
						<Route path='booking-persons' element={<BookingPersons />} />
					</Routes>
					<Footer />
					<Follow />
					<ContactW />
				</main>
			</div>
		</QueryClientProvider>
	)
}

function Layout() {
	return (
		<>
			<MobileBar />
			<Outlet />
		</>
	)
}
