import { ErrorMessage } from '@hookform/error-message'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Checkbox from '../../components/ui/checkbox/Checkbox'
import './BookTerms.scss'

const BookTerms: FC<{}> = () => {
	const {
		formState: { errors }
	} = useFormContext()
	return (
		<div className='book-terms section'>
			<div className='book-terms__container'>
				<h2 className='book-terms__title subtitle center blue'>
					Условия и положения
				</h2>

				<div className='book-terms__inner'>
					<p className='text'>
						Подтвердите, пожалуйста, свое согласие с условиями бронирования.
					</p>
					<div className='book-terms__row'>
						<Controller
							name={`bookxAgree`}
							rules={{
								validate: value => value == '1' || 'подтвердите свое согласие'
							}}
							render={({ field: { onChange, value, ref } }) => (
								<Checkbox 
								ref={(elem) => {
									ref({
									  ...elem,
									  focus: () => {
										
										elem?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
										elem?.focus({ preventScroll: true });
									  }
									});
								  }}
								value={value} onChange={onChange} />
							)}
						/>
						<p  className='text'>Я прочитал и согласен c ElephantTour</p>
					</div>
					<ErrorMessage
						errors={errors}
						name={`bookxAgree`}
						render={({ message }) => (
							<div className='book-terms__error'>
								<span>{message}</span>
							</div>
						)}
					/>
				</div>
			</div>
		</div>
	)
}

export default BookTerms
