
import bannerImg from './assets/banner-place.jpg'
import Banner from './components/banner/Banner'
import Features from './components/features/Features'
import PlacesBlog from './pages/places/PlacesBlog'
import PlacesBook from './pages/places/PlacesBook'
import PlacesHero from './pages/places/PlacesHero'
import Recommend from './pages/places/Recommend'




function Places() {
	return (
		<>
			<Banner image={bannerImg}/>
			<Features/>
			<PlacesHero/>
			<div className='border'></div>
			<Recommend />
			<div className='border'></div>
			<PlacesBook />
			<div className='border'></div>
			<PlacesBlog />
		</>
	)
}

export default Places
