import bannerImg from './assets/banner-tours.jpg'
import Banner from './components/banner/Banner'
import Features from './components/features/Features'
import Browse from './pages/tematics/Browse'
import Choose from './pages/tematics/Choose'
import Popular from './pages/tematics/Popular'
import TematicsBlog from './pages/tematics/TematicsBlog'
import TematicsHero from './pages/tematics/TematicsHero'




function Tematics() {
	return (
		<>
			<Banner image={bannerImg}/>
			<Features/>
			<TematicsHero/>
			<div className='border'></div>
			<Choose />
			<div className='border'></div>
			<Popular />
			<div className='border'></div>
			<Browse />
			<div className='border'></div>
			<TematicsBlog/>
		</>
	)
}

export default Tematics
