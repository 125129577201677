import { useQuery } from '@tanstack/react-query'
import { getAbout } from '../api/getAbout'


export const useQueryAbout = () => {
	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['about'],
		queryFn: getAbout
	})

	return { isPending, error, isFetching, data }
}
