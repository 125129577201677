import { spawn } from "child_process"
import { FC } from "react"

const Dotted: FC<{
}> =()=>{
    return(
        <span className="pagination__dotted">...</span>
    )
}

export default Dotted