import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQueryPlaceBySlug } from '../../hooks/useQueryPlaceBySlug'
import { useQueryPlaces } from '../../hooks/useQueryPlaces'
import { useQueryTownFromBySlug } from '../../hooks/useQueryTownFromBySlug'
import { useQueryTownsFrom } from '../../hooks/useQueryTownsFrom'
import { EnumPeople, EnumSearchUrl, TownsFromItem } from '../../types/types'
import Button from '../ui/button/Button'
import DateControl from './DateControl'
import PeopleControl from './PeopleControl'
import PlaceControl from './PlaceControl'
import './Search.scss'

function Search() {
	let navigate = useNavigate()

	const [errorTownsFrom, setErrorTownsFrom] = useState<boolean>(false)
	const [errorTownsTo, setErrorTownsTo] = useState<boolean>(false)

	const [townsFromShow, setTownsFromShow] = useState<boolean>(false)
	const [townsToShow, setTownsToShow] = useState<boolean>(false)
	const [dateToShow, setDateToShow] = useState<boolean>(false)
	const [peopleShow, setPeopleShow] = useState<boolean>(false)

	const [townTo, setTownTo] = useState<string>('')
	const [townFrom, setTownFrom] = useState<string>('')

	let [searchParams, setSearchParams] = useSearchParams()

	const [dateFrom, setDateTo] = useState<string>(
		searchParams.get(EnumSearchUrl.DateFrom) || dayjs().format('DD.MM.YYYY')
	)

	const [valueAdults, setValueAdults] = useState<number>(
		parseInt(searchParams.get(EnumSearchUrl.Adults) || '') || 0
	)

	const [valueYouths, setValueYouths] = useState<number>(
		parseInt(searchParams.get(EnumSearchUrl.Youths) || '') || 0
	)

	const [valueChildren, setValueChildren] = useState<number>(
		parseInt(searchParams.get(EnumSearchUrl.Children) || '') || 0
	)

	const [valueInfants, setValueInfants] = useState<number>(
		parseInt(searchParams.get(EnumSearchUrl.Infants) || '') || 0
	)

	const handleChangePeople = (type: EnumPeople, val: number) => {
		if (type === EnumPeople.Adults) {
			setValueAdults(val)
		}
		if (type === EnumPeople.Children) {
			setValueChildren(val)
		}
		if (type === EnumPeople.Infants) {
			setValueInfants(val)
		}
		if (type === EnumPeople.Youths) {
			setValueYouths(val)
		}
	}

	const handleChangeTownFrom = (val: string) => {
		setTownFrom(val)
		setErrorTownsFrom(false)
	}

	const handleChangeTownTo = (val: string) => {
		setTownTo(val)
		setErrorTownsTo(false)
	}

	const handleChangeDateTo = (val: string) => {
		setDateTo(val)
	}

	const { data: townsFrom } = useQueryTownsFrom()
	const { data: townFrom2 } = useQueryTownFromBySlug(
		searchParams.get(EnumSearchUrl.TownFrom) || ''
	)
	const { data: townsTo } = useQueryPlaces()
	const { data: townTo2 } = useQueryPlaceBySlug(
		searchParams.get(EnumSearchUrl.TownTo) || ''
	)

	useEffect(() => {
		if (townFrom2) {
			setTownFrom(townFrom2.name)
			return
		}
		if (localStorage.getItem('departure')) {
			const depart = localStorage.getItem('departure')
			if (depart) {
				const depparse: TownsFromItem = JSON.parse(depart)
				setTownFrom(depparse.name)
			}
		}
	}, [townFrom2, localStorage.getItem('departure')])

	useEffect(() => {
		setTownTo(townTo2?.name || '')
	}, [townTo2])

	return (
		<div className='search'>
			{/* <div className='search__top'>
				<div className='search__toggle'>
					<label className='search__label'>
						<input
							className='search__radio'
							type='radio'
							defaultChecked
							name='search-toggle'
						/>
						<div className='search__decor'></div>
						<div className='search__flicker'></div>
						<span className='search__text'>туда обратно</span>
					</label>
					<label className='search__label'>
						<input
							className='search__radio'
							type='radio'
							name='search-toggle'
						/>
						<div className='search__decor'></div>
						<div className='search__flicker'></div>

						<span className='search__text'>туда </span>
					</label>
				</div>
			</div> */}
			<div className='search__controls'>
				<div className='search__places'>
					<PlaceControl
						isShow={townsFromShow}
						setShow={(val: boolean) => {
							setTownsFromShow(val)
							setTownsToShow(false)
							setDateToShow(false)
							setPeopleShow(false)
						}}
						showError={errorTownsFrom}
						options={townsFrom || []}
						value={townFrom}
						onChange={handleChangeTownFrom}
						labelName='Отправление'
						iconClass='fi-rs-plane-departure'
					/>
					<PlaceControl
						isShow={townsToShow}
						setShow={(val: boolean) => {
							setTownsToShow(val)
							setTownsFromShow(false)
							setDateToShow(false)
							setPeopleShow(false)
						}}
						showError={errorTownsTo}
						options={townsTo || []}
						value={townTo}
						onChange={handleChangeTownTo}
						labelName='Направление'
						iconClass='fi-rs-plane-arrival'
					/>
				</div>
				<div className='search__date'>
					<DateControl
						isShow={dateToShow}
						setShow={(val: boolean) => {
							setDateToShow(val)
							setTownsFromShow(false)
							setTownsToShow(false)
							setPeopleShow(false)
						}}
						value={dateFrom}
						onChange={handleChangeDateTo}
						labelName='Дата'
						iconClass='fi-rs-calendar'
					/>
				</div>
				<div className='search__people'>
					<PeopleControl
						isShow={peopleShow}
						setShow={(val: boolean) => {
							setPeopleShow(val)
							setTownsToShow(false)
							setTownsFromShow(false)
							setDateToShow(false)
						}}
						onChange={handleChangePeople}
						valueAdults={valueAdults}
						valueChildren={valueChildren}
						valueInfants={valueInfants}
						valueYouths={valueYouths}
						iconClass='fi-rs-users-alt'
					/>
				</div>
				<div className='search__action'>
					<Button
						className='search__btn text-big'
						title='Найти'
						onClick={() => {
							if (townFrom === '') {
								setErrorTownsFrom(true)
							}

							if (townTo === '') {
								setErrorTownsTo(true)
							}

							if (townFrom !== '' && townTo !== '') {
								searchParams.delete(EnumSearchUrl.TownFrom)
								searchParams.delete(EnumSearchUrl.TownTo)
								searchParams.delete(EnumSearchUrl.DateFrom)
								searchParams.delete(EnumSearchUrl.DateTo)
								searchParams.delete(EnumSearchUrl.Adults)
								searchParams.delete(EnumSearchUrl.Children)
								searchParams.delete(EnumSearchUrl.Youths)
								searchParams.delete(EnumSearchUrl.Infants)

								if (townFrom !== '') {
									const slug = townsFrom?.filter(
										t => t.location === townFrom
									)[0].slug

									searchParams.set(EnumSearchUrl.TownFrom, slug || '')
								}
								if (townTo !== '') {
									const slug = townsTo?.filter(t => t.location === townTo)[0]
										.slug

									searchParams.set(EnumSearchUrl.TownTo, slug || '')
								}
								if (dateFrom !== '') {
									searchParams.set(EnumSearchUrl.DateFrom, dateFrom)
								}
								if (valueAdults !== 0) {
									searchParams.set(EnumSearchUrl.Adults, valueAdults.toString())
								}
								if (valueChildren !== 0) {
									searchParams.set(
										EnumSearchUrl.Children,
										valueChildren.toString()
									)
								}
								if (valueInfants !== 0) {
									searchParams.set(
										EnumSearchUrl.Infants,
										valueInfants.toString()
									)
								}
								if (valueYouths !== 0) {
									searchParams.set(EnumSearchUrl.Youths, valueYouths.toString())
								}

								navigate('/tours/select?' + searchParams.toString())
							}
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default Search
