import { useQuery } from '@tanstack/react-query'
import { getPlaces } from '../api/getPlaces'


export const useQueryPlaces = () => {

	const { isPending, error, data, isFetching,isSuccess } = useQuery({
		queryKey: ['places'],
		queryFn: getPlaces
	})


	return { isPending, error, isFetching, data,isSuccess}
}
