import { FC } from 'react'
import { CardTB } from '../../components/card-tb/CardTb'
import { useQueryBlogsByParams } from '../../hooks/useQueryBlogsByParams'
import './PlaceBlog.scss'

const PlaceBlog: FC<{
	location_slug: string
	name: string
}> = ({ location_slug, name }) => {
	
	const {data:blogs,isPending,error}  = useQueryBlogsByParams('',location_slug)
	console.log(location_slug);
	


	return (
		<div className='place__blog section'>
			<div className='place__blog-container container'>
				<div className='place__blog-title title center blue'>
					Отдых в {name} — обзоры, советы и рекомендации для путешественников
				</div>
				<div className='place-blog__inner'>
					{blogs?.map(item => (
						<CardTB
							key={item.id}
							image={item.image}
							imageSrc='places'
							name={item.title}
							slug={item.slug}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default PlaceBlog
