import { FC, useState } from 'react'
import './TourHeader.scss'
import { Link } from 'react-router-dom'

const TourHeader: FC<{ isShow: boolean }> = ({ isShow }) => {
	
	
	const [activeLink, setActiveLink] = useState<number>(0)

	const handleSctoll = (id: string) => {
		const el = document.getElementById(id)
		el?.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	const links = [
		{
			id: 1,
			link: '#intro',
			name: 'Основное'
		},
		{
			id: 2,
			link: '#itinerary',
			name: 'Маршрут'
		},
		{
			id: 3,
			link: '#reviews',
			name: 'Отзывы'
		},
		{
			id: 4,
			link: '#blog',
			name: 'Блог'
		}
	]
	return (
		<div
			className={
				isShow ? 'tour__header tour-header show hidden lg:block' : 'tour__header tour-header hidden lg:block'
			}
		>
			<nav className='tour-header__menu'>
				{links.map(el => (
					<Link
						key={el.id}
						className={
							activeLink === el.id ? 'tour-header__link active text' : 'tour-header__link text'
						}
						to={el.link}
						onClick={e => {
							e.preventDefault()
							setActiveLink(el.id)
							handleSctoll(el.link)
						}}
					>
						{el.name}
					</Link>
				))}
			</nav>
		</div>
	)
}

export default TourHeader
