import { useQuery } from '@tanstack/react-query'
import { getParks } from '../api/getParks'



export const useQueryParkBySlug = (slug:string) => {
	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['parks'],
		queryFn: getParks,
		select:data=>data.filter(d=>d.slug===slug)[0]
	})

	return { isPending, error, isFetching, data }
}
