import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import './BookingRoom.scss'
import BookHeaderMobile from './components/book-header-mobile/BookHeaderMobile'
import BookHeader from './components/book-header/BookHeader'
import SummaryMobile from './components/summary-mobile/SummaryMobile'
import SummaryWidget from './components/SummaryWidget/SummaryWidget'
import ButtonBook from './components/ui/button-book/ButtonBook'
import ButtonLoading from './components/ui/button-loading/ButtonLoading'
import { BookOption, PersonOption, storeBooking } from './constants'
import BookBaggage from './pages/booking-room/BookBaggage'
import BookInsurance from './pages/booking-room/BookInsurance'
import BookRoom from './pages/booking-room/BookRoom'
import BookTerms from './pages/booking-room/BookTerms'
import { ScrollToFieldError } from './components/ScrollToFieldError '

function BookingRoom() {
	const [bookInfo, setBookInfo] = useState<storeBooking['bookInfo']>()
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)

	const [widgetShow, setWidgetShow] = useState(false)

	const handleWidgetShow = (val: boolean) => {
		setWidgetShow(val)
	}

	const methods = useForm()

	const handlePerson = (val: PersonOption) => {
		methods.setValue('bookRoom', '')

		const arr: BookOption[] = []

		for (let i = 0; i < parseInt(val.value); i++) {
			arr.push({ label: '', value: '', price: 0 })
			methods.setValue('bookBaggage', arr)
		}

		const arr2: BookOption[] = []

		for (let i = 0; i < parseInt(val.value); i++) {
			arr2.push({ label: '', value: '', price: 0 })
			methods.setValue('bookInsurance', arr2)
		}
	}

	useEffect(() => {
	
		
		if(localStorage.getItem('booking')){

			const lc = JSON.parse(localStorage.getItem('booking') || '')
			if (lc.bookRoom) {
				methods.setValue('bookRoom', lc.bookRoom)
			} else {
				methods.setValue('bookRoom', '')
			}
	
			if (lc.bookInfo) {
				setBookInfo(lc.bookInfo)
			}
	
			if (lc.bookPerson) {
				methods.setValue('bookPerson', lc.bookPerson)
			} else {
				methods.setValue('bookPerson', { value: '', label: '' })
			}
	
			if (lc.bookBaggage) {
				methods.setValue('bookBaggage', lc.bookBaggage)
			} else {
				methods.setValue('bookBaggage', [])
			}
	
			if (lc.bookInsurance) {
				methods.setValue('bookInsurance', lc.bookInsurance)
			} else {
				methods.setValue('bookInsurance', [])
			}
		}
	}, [])

	const personsCount = methods.watch('bookPerson')
	const room = methods.watch('bookRoom')

	const baggage = methods.watch('bookBaggage')
	const baggagePrice = baggage
		? baggage.reduce(
				// @ts-ignore
				(accumulator, currentValue) => accumulator + currentValue.price,
				0
			)
		: 0

	const insurance = methods.watch('bookInsurance')
	const insurancePrice = insurance
		? insurance.reduce(
				// @ts-ignore
				(accumulator, currentValue) => accumulator + currentValue.price,
				0
			)
		: 0

	// @ts-ignore
	const onSubmit = data => {
		const storeBook: storeBooking = JSON.parse(
			localStorage.getItem('booking') || ''
		)

		if (storeBook) {
			storeBook.bookPerson = data.bookPerson
			storeBook.bookBaggage = data.bookBaggage
			storeBook.bookInsurance = data.bookInsurance
			storeBook.bookRoom = data.bookRoom
		}

		const arrPersons: storeBooking['bookPersons'] = []

		for (let i = 0; i < parseInt(data.bookPerson.value); i++) {
			arrPersons.push({
				email: '',
				firstName: '',
				lastName: '',
				middleName: '',
				phone: '',
				title: { label: '', value: '' }
			})
			storeBook.bookPersons = arrPersons
		}

		localStorage.setItem('booking', JSON.stringify(storeBook))

		setIsLoading(true)

		setTimeout(() => {
			setIsLoading(false)
			navigate('/booking-persons')
		}, 2000)
	}



	return (
		<>
			<BookHeader />
			<SummaryMobile setWidgetShow={handleWidgetShow} widgetShow={widgetShow} />
			<BookHeaderMobile step={2} />
			<div className='booking-room'>
				<div className='booking-room__form'>
					<FormProvider {...methods}>
						<ScrollToFieldError />
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							{/* @ts-ignore */}

							<BookRoom onChangePerson={handlePerson} />
							<div className='border' />
							<BookBaggage />
							<div className='border' />

							<BookInsurance />
							<div className='border' />

							<BookTerms />
							{isLoading ? (
								<div className='booking-room__btn'>
									<ButtonLoading />
								</div>
							) : (
								<div className='booking-room__btn'>
									<ButtonBook title='Продолжить' />
								</div>
							)}
						</form>
					</FormProvider>
				</div>

				<div className='booking-room__widget-wrapper'>
					<div className='booking-room__www'>
						<div
							className={
								widgetShow
									? 'booking-room__widget'
									: 'booking-room__widget booking-room__widget--hidden'
							}
						>
							<SummaryWidget
								baggagePrice={baggagePrice}
								insurancePrice={insurancePrice}
								personsCount={personsCount ? parseInt(personsCount.value) : 0}
								tourDate={bookInfo?.dateFrom || ''}
								tourName={bookInfo?.tourName || ''}
								tourPrice={room ? parseInt(room.price) : 0}
								townTo={bookInfo?.townTo || ''}
							/>
							<div className='booking-room__widget-btn '>
								<button
									className={widgetShow ? '' : 'pointer-none'}
									onClick={() => setWidgetShow(false)}
								>
									<span className='fi-rs-angle-up'></span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BookingRoom
