import { FC } from 'react'
import { GraphItem } from '../../types/types'
import './RatingGraph.scss'

const RatingGraph: FC<{ value: GraphItem[] }> = ({ value }) => {
	return (
		<div className='rating-graph'>
			<div className='rating-graph__scoring'>
				{value.map(v => (
					<div key={v.value} className='rating-graph__scoring-row'>
						<span className='rating-graph__scoring-value text-sm'>{v.value}</span>
						<div className='rating-graph__scoring-progress'>
							<div
								className='rating-graph__scoring-fill'
								style={{ width: Math.floor((100 * v.count) / v.total) + '%' }}
							></div>
						</div>
						<span className='rating-graph__scoring-count text-sm'>{v.count}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default RatingGraph
