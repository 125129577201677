import { FC } from 'react'
import './ButtonLink2.scss'
import { Link } from 'react-router-dom'

const ButtonLink2: FC<{
	title: string,
	link:string
	className?:string,
}> = ({ title,link,className }) => {
	return (
		<Link to={link} target="_blank" className={`${className} btn-link2`} 
		>{title}</Link>

	)
}

export default ButtonLink2