import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useQueryBlogs } from '../../hooks/useQueryBlogs'
import './TematicsBlog.scss'

const CardBT: FC<{
	name: string
	slug: string
	image: string
}> = ({ image, name, slug }) => {
	return (
		<div className='card-bt   w-100 lg:w-1/3 sm:w-1/2'>
			<Link to={`/blog/${slug}`} className='card-bt__image'>
				<p className='card-bt__text subtitle center'>{name}</p>
				<img
					className='card-bt__img'
					src={`/images/tematics/${image}`}
					alt=''
				/>
			</Link>
		</div>
	)
}

function TematicsBlog() {
	const { data, isPending, error } = useQueryBlogs()

	if (isPending) return <div>'Loading...'</div>

	return (
		<div className='tematics-blog section'>
			<div className='tematics-blog__container container'>
				<h2 className='tematics-blog__title title center blue'>
					Обзоры, советы и рекомендации для путешественников
				</h2>
				<div className='tematics-blog__block'>
					{data
						?.filter(d => d.type === 'tematic')
						.map((item, index) => (
							<CardBT
								key={index}
								image={item.image}
								name={item.title}
								slug={item.slug}
							/>
						))}
				</div>
			</div>
		</div>
	)
}

export default TematicsBlog
