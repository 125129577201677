import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import './TematicHeader.scss'

const TematicHeader: FC<{ isShow: boolean }> = ({ isShow }) => {
	const [activeLink, setActiveLink] = useState<number>(0)

	const handleSctoll = (id: string) => {
		const el = document.getElementById(id)

		const headersHeight =
			// @ts-ignore
			document.querySelector('.header').clientHeight +
			// @ts-ignore
			document.querySelector('.tematic__header').clientHeight

		// @ts-ignore
		const { top } = el.getBoundingClientRect()
		window.scrollBy({
			top: -(headersHeight - top + 30),
			behavior: 'smooth'
		})
	}

	const links = [
		{
			id: 1,
			link: 'descr',
			name: 'Описание'
		},
		{
			id: 2,
			link: 'details',
			name: 'Детали'
		},
		{
			id: 3,
			link: 'cancel',
			name: 'Отмена'
		},
		{
			id: 4,
			link: 'reviews',
			name: 'Отзывы'
		}
	]
	return (
		<div
			className={
				isShow
					? 'tematic__header tematic-header show hidden lg:block'
					: 'tematic__header tematic-header hidden lg:block'
			}
		>
			<nav className='tematic-header__menu'>
				{links.map(el => (
					<Link
						key={el.id}
						className={
							activeLink === el.id
								? 'tematic-header__link active'
								: 'tematic-header__link'
						}
						to={el.link}
						onClick={e => {
							e.preventDefault()
							setActiveLink(el.id)
							handleSctoll(el.link)
						}}
					>
						{el.name}
					</Link>
				))}
			</nav>
		</div>
	)
}

export default TematicHeader
