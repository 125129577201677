import { FC } from 'react'
import airplaneImg from '../../assets/icons/airplane.svg'
import guidImg from '../../assets/icons/guide.svg'
import moonImg from '../../assets/icons/moon.svg'
import locationImg from '../../assets/icons/placeholder.png'

import './SummaryWidget.scss'

export const SummaryWidget: FC<{
	tourDate: string
	tourName: string
	townTo: string
	tourPrice: number
	personsCount: number
	baggagePrice: number
	insurancePrice: number,
	
}> = ({
	baggagePrice,
	insurancePrice,
	personsCount,
	tourDate,
	tourPrice,
	tourName,
	townTo,
	
}) => {
	return (
		<div className='summary-widget'>
			<div className="summary-widget__wrapper">
				<h3 className='summary-widget__title subtitle left'>Цена тура</h3>
				<div className='summary-widget__row'>
					<h4 className='summary-widget__name title blue left'>{tourName}</h4>
					<div className='summary-widget__image'>
						<img
							height={100}
							width={150}
							src='/images/places/card1.jpg'
							alt=''
						/>
					</div>
				</div>
				<div className='summary-widget__location text-sm'>
					<img width={15} height={15} src={locationImg} alt='' />
					<span>{townTo}</span>
				</div>

				<div className='summary-widget__options'>
					<div className='summary-widget__option'>
						<img
							width={20}
							height={20}
							className='summary-widget__option-img'
							src={guidImg}
						/>
						<span className='summary-widget__option-text text-sm'>Fully Guided</span>
					</div>
					<div className='summary-widget__option'>
						<img
							width={20}
							height={20}
							className='summary-widget__option-img'
							src={moonImg}
						/>
						<span className='summary-widget__option-text text-sm'>7 Nights</span>
					</div>
					<div className='summary-widget__option'>
						<img
							width={20}
							height={20}
							className='summary-widget__option-img'
							src={airplaneImg}
						/>
						<span className='summary-widget__option-text text-sm'>
							Flights & Transfers
						</span>
					</div>
				</div>
				<div className='summary-widget__row summary-widget__row--flight'>
					<span className='summary-widget__flight text-big blue'>Flight</span>
					<div className='summary-widget__text text blue'>{tourDate}</div>
				</div>
				<div className='summary-widget__info'>
					<span className='summary-widget__subtitle subtitle left blue'>
						Платежная информация
					</span>
					<div className='summary-widget__row'>
						<div className='summary-widget__text text-big blue'>
							Стоимость тура: ({tourPrice}x{personsCount})
						</div>
						<div className='summary-widget__text text'>
							{tourPrice * personsCount}
						</div>
					</div>
					{baggagePrice !== 0 && (
						<div className='summary-widget__row summary-widget__row--baggage'>
							<div className='summary-widget__text  text-big blue'>Багаж</div>
							<div className='summary-widget__text text'>{baggagePrice}</div>
						</div>
					)}
					{insurancePrice !== 0 && (
						<div className='summary-widget__row'>
							<div className='summary-widget__text  text-big blue'>Страховка</div>
							<div className='summary-widget__text text'>{insurancePrice}</div>
						</div>
					)}
				</div>
				<div className='summary-widget__row summary-widget--total'>
					<div>
						<div className='summary-widget__itogo subtitle blue'>Общая стоимость</div>
						<div className='summary-widget__includes text-sm'>
							включая все налоги и сборы
						</div>
					</div>
					<div className='summary-widget__text'>
						{tourPrice * personsCount + insurancePrice + baggagePrice}
					</div>
				</div>
			</div>
			
		</div>
	)
}

export default SummaryWidget
