import { Link } from 'react-router-dom'
import facebook from '../../assets/socials/facebook.png'
import instagram from '../../assets/socials/instagram.png'
import linkedin from '../../assets/socials/linkedin.png'
import twitter from '../../assets/socials/twitter.png'
import youtube from '../../assets/socials/youtube.png'
import './Follow.scss'

function Follow() {
	return (
		<div className='follow'>
			<div className='follow__container'>
				<span className='follow__title subtitle blue'>Мы в соцсетях</span>
				<ul className='follow__socials'>
					<li className='follow__social'>
						<Link className='follow__link' to='/'>
							<img width={30} height={30} src={facebook} alt='' />
						</Link>
					</li>
					<li className='follow__social'>
						<Link className='follow__link' to='/'>
							<img width={30} height={30} src={instagram} alt='' />
						</Link>
					</li>
					<li className='follow__social'>
						<Link className='follow__link' to='/'>
							<img width={30} height={30} src={youtube} alt='' />
						</Link>
					</li>
					<li className='follow__social'>
						<Link className='follow__link' to='/'>
							<img width={30} height={30} src={twitter} alt='' />
						</Link>
					</li>
					<li className='follow__social'>
						<Link className='follow__link' to='/'>
							<img width={30} height={30} src={linkedin} alt='' />
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Follow
