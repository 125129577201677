import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import './MainTematic.scss'
import useResize from '../../hooks/useRisize'
import { useQueryTours } from '../../hooks/useQueryTours'
import { EnumTourType } from '../../types/types'

const CardTM: FC<{
	name: string
	slug: string
	image: string
}> = ({ image, name, slug }) => {
	return (
		<Link to={`/tematics/${slug}`} className='card-tm'>
			<div className='card-tm__wrapper'>
				<p className='card-tm__text text-xl center'>{name}</p>
				<img
					className='card-tm__img'
					src={`/images/tematics/${image}`}
					alt=''
				/>
			</div>
		</Link>
	)
}

function MainTematic() {
	const navigation = {
		enabled: true,
		nextEl: '.main-tematic__next',
		prevEl: '.main-tematic__prev'
	}

	const { data, isPending, error } = useQueryTours(EnumTourType.Tematics)

	const size = useResize()


	return (
		<div className='main-tematic section'>
			<h2 className='main-tematic__title title center blue'>Тематические туры</h2>
			<div className='main-tematic__container container'>
				{size[0] >= 768 ? (
					<div className='main-tematic__wrapper'>
						<Swiper
							modules={[Navigation]}
							navigation={navigation}
							slidesPerView={1}
							spaceBetween={0}
							breakpoints={{
								768: {
									slidesPerView: 3,
									spaceBetween: 0
								},
								1024: {
									slidesPerView: 4,
									spaceBetween: 0
								},
								1280: {
									slidesPerView: 5,
									spaceBetween: 0
								},
								1500: {
									slidesPerView: 6,
									spaceBetween: 0
								}
							}}
						>
							{data?.map((item, index) => (
								<SwiperSlide key={index}>
									<CardTM
										image={item.image}
										name={item.name}
										slug={item.slug}
									/>
								</SwiperSlide>
							))}
						</Swiper>
						<button className='main-tematic__prev '>
							<span className='fi-rs-angle-left'></span>
						</button>
						<button className='main-tematic__next'>
							<span className=' fi-rs-angle-right'></span>
						</button>
					</div>
				) : (
					<div className='main-tematic__list'>
						{data?.map((item, index) => (
							<CardTM key={item.id} image={item.image} name={item.name} slug={item.slug} />
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export default MainTematic
