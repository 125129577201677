import { useQuery } from '@tanstack/react-query'
import { getBlogs } from '../api/getBlogs'

export const useQueryBlogsByParams = (type?:string,location_slug?:string) => {
	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['blogs'],
		queryFn: getBlogs,
		select:data=>data.filter(d=>type?d.type ===type:d.location_slug===location_slug)
	})


	

	return { isPending, error, isFetching, data }
}
