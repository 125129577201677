import { useState } from 'react'
import { Link } from 'react-router-dom'
import bannerImg from './assets/banner-inspiration.jpg'
import './Blog.scss'
import Banner from './components/banner/Banner'
import { CardBlog } from './components/card-blog/CardBlog'
import { useQueryBlogPagination } from './hooks/useQueryBlogPagination'
import { useQueryCategoies } from './hooks/useQueryCategoies'

function Blog() {
	const [page, setPage] = useState<number>(1)
	const perPage = 9

	const { list, isPending, loadMoreShow } = useQueryBlogPagination(
		page,
		perPage
	)

	const { data: categoies } = useQueryCategoies()

	return (
		<>
			<Banner image={bannerImg} />
			<article className='blog'>
				<div className='blog__container'>
					<h1 className='blog__title title-big left blue'>
						Мотивация для вашего следующего отпуска
					</h1>

					<p className='text'>
						Если вы ищете руководство для большой поездки за границу или вы
						опытный путешественник, ищущий вдохновения для своего следующего
						отпуска, Travel Tales поможет вам найти ответ. Предлагая последние
						советы по путешествиям, истории приключений, обзоры путешествий и
						раскрывая скрытые жемчужины для вашего исследования, мы отвечаем на
						все ваши вопросы о путешествиях, чтобы убедиться, что вы испытываете
						наилучшие впечатления от путешествий по миру!
					</p>
					<div className='blog__inner'>
						<div className='blog__left'>
							<div className='blog__block'>
								{list?.map((item, index) => (
									<CardBlog
										key={index}
										image={item.image}
										name={item.title}
										slug={item.slug}
									/>
								))}
							</div>
							{loadMoreShow && (
								<div className='blog__load-more'>
									<button
										className='blog__load-btn text'
										onClick={() => setPage(page + 1)}
									>
										Load more
									</button>
								</div>
							)}
						</div>
						<div className='blog__right hidden lg:block'>
							<h3 className='subtitle left blue'>Категории</h3>
							<ul className='blog__categories'>
								{categoies?.map(c => (
									<li className='blog__categories-item' key={c.id}>
										<Link to='/blog' className='blog__categories-link'>
											<img width={30} height={30} src={`/images/categories/${c.image}`} alt='' />
											<h4 className='text-big'>{c.name}</h4>
										</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</article>
		</>
	)
}

export default Blog
