import { useEffect, useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import './Top.scss'
import CardT from '../../components/card-t/CardT'
import { EnumTourType, TourItem } from '../../types/types'
import { useQueryTours } from '../../hooks/useQueryTours'


function Top() {
	const navigation = {
		enabled: true,
		nextEl: '.top__next',
		prevEl: '.top__prev'
	}

	const {data,isPending,error}  = useQueryTours(EnumTourType.Tours)

	if (isPending) return(
		<div>'Loading...'</div>
	) 

	return (
		<div className='top section'>
			<h2 className='top__title title center blue'>
			Лучшие рекомендации 
			</h2>
			<div className='top__container container'>
				<div className='top__wrapper'>
					<Swiper
						modules={[Navigation]}
						navigation={navigation}
						slidesPerView={'auto'}
						spaceBetween={20}
						loop={true}
					>
						{data?.filter((item,index)=>index<=5).map((item, index) => (
							<SwiperSlide key={index} className='max-w-[405px]'>
								<CardT
									slug={item.slug}
									image={item.image}
									location={item.location}
									price={item.price}
									pricePrev={item.pricePrev}
									title={item.name}
								/>
							</SwiperSlide>
						))}
					</Swiper>
					<button className='top__prev hidden lg:block'>
						<span className='fi-rs-angle-left'></span>
					</button>
					<button className='top__next  hidden lg:block'>
						<span className=' fi-rs-angle-right'></span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default Top
