import { FC } from 'react'
import Review from '../../components/review/Review'
import ButtonLink from '../../components/ui/button-link/ButtonLink'
import { useQueryReviews } from '../../hooks/useQueryReviews'
import './TematicReviews.scss'
import Rating from './Rating'

const images = [
	{ id: 0, src: '/images/tematics/card1.jpg', width: 700, height: 500 },
	{ id: 1, src: '/images/tematics/card2.jpg', width: 700, height: 500 },
	{ id: 2, src: '/images/tematics/card3.jpg', width: 700, height: 500 },
	{ id: 3, src: '/images/tematics/card4.jpg', width: 700, height: 500 },
	{ id: 4, src: '/images/tematics/card5.jpg', width: 700, height: 500 },
	{ id: 5, src: '/images/tematics/card6.jpg', width: 700, height: 500 },
	{ id: 6, src: '/images/tematics/card7.jpg', width: 700, height: 500 },
	{ id: 7, src: '/images/tematics/card8.jpg', width: 700, height: 500 },
	{ id: 8, src: '/images/tematics/card9.jpg', width: 700, height: 500 },
	{ id: 9, src: '/images/tematics/card10.jpg', width: 700, height: 500 },
	{ id: 10, src: '/images/tematics/card11.jpg', width: 700, height: 500 },
	{ id: 11, src: '/images/tematics/card12.jpg', width: 700, height: 500 },
	{ id: 12, src: '/images/tematics/card13.jpg', width: 700, height: 500 },
	{ id: 13, src: '/images/tematics/card14.jpg', width: 700, height: 500 },
	{ id: 14, src: '/images/tematics/card15.jpg', width: 700, height: 500 },
	{ id: 15, src: '/images/tematics/card16.jpg', width: 700, height: 500 }
]

const TematicReviews: FC<{
	name: string
	slug: string
}> = ({ name, slug }) => {
	const { data, isPending, error } = useQueryReviews()

	if (isPending) return <div>'Loading...'</div>

	return (
		<div className='tematic-reviews'>

			<div className='tematic-reviews__container'>

			<div className='tematic-reviews__rating' id='reviews'>
				<div className='tematic-reviews__rating-subtitle'>
					<h2 className='tematic-reviews__subtitle subtitle left'>
						Отзывы наших клиентов
					</h2>
					<p>{`5 из ${data?.length} отзывов`}</p>
				</div>
				<div className='tematic-reviews__rating-rating'>
					<Rating
						value={{
							images: images,
							link: '/',
							reviewsCount: data?.length || 0,
							value: 4
						}}
					/>
				</div>
			</div>
				<div className='tematic-reviews__list'>
					{data
						?.filter((r, index) => index <= 0)
						.map(r => (
							<div key={r.id} className='tematic-reviews__item'>
								<Review value={r} />
							</div>
						))}
				</div>
				<div className='tematic-reviews__btn-wrapper'>
					<ButtonLink
						link={`/tematics/${slug}/reviews`}
						title='все отзывы'
						className='tematic-reviews__btn'
					/>
				</div>
			</div>
		</div>
	)
}

export default TematicReviews
