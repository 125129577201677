import { FC, useState } from 'react'
import BookWidget from '../../components/book-widget/BookWidget'
import { useQueryReviews } from '../../hooks/useQueryReviews'
import { EnumTourType } from '../../types/types'
import './TematicHero.scss'



const TematicHero: FC<{
	name: string | undefined
	location: string | undefined
}> = ({ name,location }) => {
	const [viewFull, setViewFull] = useState(true)
	
	const {data,isPending,error}  = useQueryReviews()

	if (isPending) return(
		<div>'Loading...'</div>
	) 
	

	return (
		<div className='tematic'>
			<div className='tematic__container container' id='descr'>
				<div className='tematic__info'>
					<div className='tematic__block'>
					<h1 className='tematic__title title-big left' id='#intro'>
						{name}
					</h1>
					<div className='tematic__location'>
						<span className='fi-rs-land-layer-location'></span>
						<span className='text-big'>{location}</span>
					</div>
						<div className='tematic__descr section' id='descr'>
							<p className='text'>
								Откройте для себя мир с одним из наших туристических отпусков. С
								нашим широким выбором направлений для отдыха весь мир у ваших
								ног.
							</p>
							<div
								className={
									viewFull ? 'tematic__collapsed' : 'tematic__collapsed open'
								}
							>
								<p className='text'>
									Ищете ли вы короткую поездку в солнечную Испанию или
									уникальное приключение в Индии, у нас есть множество
									направлений для путешествий, которые удовлетворят все ваши
									потребности.
								</p>
								<p className='text'>
									Получите инсайдерскую информацию от наших групповых туров с
									местными гидами и получите максимум от своего отпуска
									Большинство наших туров включают перелеты, размещение,
									трансферы, экскурсии и отмеченных наградами англоговорящих
									гидов. Забронируйте место для отпуска своей мечты и
									путешествие с нами уже сегодня.
								</p>
							</div>
							<button
								className='tematic__btn-collapsed text'
								onClick={() => setViewFull(!viewFull)}
							>
								{viewFull ? 'Читать больше' : 'Свернуть'}
							</button>
							<div className='border'></div>
						</div>
						<div className='tematic__details details section' id='details'>
							<h2 className='details__subtitle subtitle left'>
								Больше информации
							</h2>
							<div className='details__list'>
								<div className='details__item _duration'>
									<span className='details__icon fi-rs-duration-alt'></span>
									<h3 className='details__title subtitle'>Продолжительность</h3>
									<div className='details__description'>
										<p className='text'>3 дня</p>
									</div>
								</div>
								<div className='details__item _language'>
									<span className='details__icon fi-rs-french'></span>
									<h3 className='details__title subtitle'>Язык</h3>
									<div className='details__description'>
										<p className='text'>
											Мероприятие проходит в сопровождении гида, говорящего на
											самарском и матерном языках.
										</p>
									</div>
								</div>
								<div className='details__item _include'>
									<span className='details__icon  fi-rs-layer-plus'></span>
									<h3 className='details__title subtitle'>Включено</h3>
									<div className='details__description'>
										<p className='text'>Автобусный транспорт</p>
										<p className='text'>Англоговорящий гид</p>
										<p className='text'>Обед</p>
										<p className='text'>Прогулка на лодке между сциллой и харибдой</p>
									</div>
								</div>
								<div className='details__item _book'>
									<span className='details__icon fi-rs-reservation-smartphone'></span>
									<h3 className='details__title subtitle'>Бронирование</h3>
									<div className='details__description'>
										<p className='text'>
											Вы можете забронировать до начала, пока есть места.
											Забронируйте сейчас, чтобы гарантировать себе место.
										</p>
									</div>
								</div>
								<div className='details__item _access'>
									<span className='details__icon  fi-rs-wheelchair'></span>
									<h3 className='details__title subtitle'>Доступность</h3>
									<div className='details__description'>
										<p className='text'>
											Доступно для инвалидных колясок. Это должно быть указано в
											бронировании. Требуется сопровождающее лицо.
										</p>
									</div>
								</div>
								<div className='details__item _pets'>
									<span className='details__icon  fi-rs-dog'></span>
									<h3 className='details__title subtitle'>Животные</h3>
									<div className='details__description'>
										<p className='text'>Не разрешено</p>
									</div>
								</div>
								<div className='details__item _faq'>
									<span className='details__icon  fi-rs-comment-question'></span>
									<h3 className='details__title subtitle'>FAQ</h3>
									<div className='details__description'>
										<p className='text'>
											<span className='questions__indicator'>
												<strong>Q</strong>-
											</span>
											<span className='questions__text'>
												Зачем проводить это мероприятие с ElephantTour?
											</span>
										</p>
										<p className='text'>
											<span className='questions__indicator'>
												<strong>A</strong>-
											</span>
											<span className='questions__text'>
												В ElephantTour мы гарантируем лучшее качество и цены.
											</span>
										</p>
										<p className='text'>
											<span className='questions__indicator'>
												<strong>Q</strong>-
											</span>
											<span className='questions__text'>Как забронировать</span>
										</p>
										<p className='text'>
											<span className='questions__indicator'>
												<strong>A</strong>-
											</span>
											<span className='questions__text'>
												Чтобы зарезервировать мероприятие, выберите дату и
												заполните форму на этой странице. Вы получите
												подтверждение немедленно.
											</span>
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className='tematic__cancel section' id='cancel'>
							<h2 className='tematic__subtitle subtitle left'>
								Бесплатная отмена
							</h2>
							<p className='text'>
								Бесплатно! Бесплатная отмена за 24 часа до начала мероприятия.
								Любые отмены после этого времени или неявки в день мероприятия
								не подлежат возврату.
							</p>
						</div>
					</div>
					<div className='tematic__widget  hidden lg:block'>
					<BookWidget type={EnumTourType.Tematics} />

					</div>
				</div>
				<div className='border'></div>
			
			</div>
		</div>
	)
}

export default TematicHero
