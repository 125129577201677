import './Features.scss'
import guides from '../../assets/features/guides.png'
import group from '../../assets/features/group.webp'
import flights from '../../assets/features/flights.webp'
import planed from '../../assets/features/planed.png'

function Features() {
	return (
		<div className='features section'>
			<div className='features__container container'>
				<div className='features__option w-1/2 lg:w-1/4'>
					<img width={32} height={32} src={guides} alt='' />
					<h3 className='features__option-title  text-big blue'>Местные эксперты-гиды</h3>
				</div>
				<div className='features__option w-1/2 lg:w-1/4'>
					<img width={32} height={32} src={group} alt='' />
					<h3 className='features__option-title  text-big blue'>Путешествуйте с группой</h3>
				</div>
				<div className='features__option w-1/2 lg:w-1/4'>
					<img width={32} height={32} src={flights} alt='' />
					<h3 className='features__option-title  text-big blue'>Авиабилеты, отели и туры</h3>
				</div>
				<div className='features__option w-1/2 lg:w-1/4'>
					<img width={32} height={32} src={planed} alt='' />
					<h3 className='features__option-title text-big blue'>Все самое лучшее</h3>
				</div>
			</div>
		</div>
	)
}

export default Features
