import { FC } from 'react'
import { Link } from 'react-router-dom'
import parksImg from '../../assets/parks.png'
import './ElParks.scss'
import { useQueryParks } from '../../hooks/useQueryParks'

const CardElPark: FC<{
	name: string
	location: string
	slug: string
	image: string
}> = ({ image, name, location, slug }) => {
	return (
		<div className='card-elpark'>
			<Link to={`/parks/${slug}`}>
				<p className='card-elpark__text text center'>{name}</p>
				<img
					className='card-elpark__img'
					src={`/images/parks/${image}`}
					alt=''
				/>
			</Link>
		</div>
	)
}

function ElParks() {
	const { data, isPending, error } = useQueryParks()

	if (isPending) return <div>'Loading...'</div>

	return (
		<div className='elparks'>
			<div className='elparks__container container'>
				<div className='elparks__left'>
					<div className='elparks__logo'>
						<img width={120} height={100} src={parksImg} alt='' />
						<div className='elparks__wrapper'>
							<h3 className='elparks__title title'>ElParks</h3>
							<span className='elparks__subtitle subtitle'>Holidays</span>
						</div>
					</div>
					<p className='elparks__text'>
						Вы страстный путешественник с душой для животных? Если вы мечтаете о
						незабываемом приключении, которое сочетает в себе захватывающие дух
						пейзажи, культурные исследования и возможность общения с
						величественными существами, то Таиланд должен быть наверху вашего
						списка путешествий. И знаете что? Вы можете сделать свое путешествие
						еще более особенным, посетив этические заповедники слонов. Каждый
						отпуск сопровождается увлеченным местным гидом и призван предложить
						аутентичные, уникальные впечатления.
					</p>
				</div>
				<div className='elparks__right'>
					{data?.map((item, index) => (
						<CardElPark
							key={index}
							image={item.image}
							name={item.name}
							location={item.location}
							slug={item.slug}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default ElParks
