import { FC, useEffect, useState } from 'react'
import Dotted from './Dotted'
import Link_ from './Link_'
import Next from './Next'
import './Pagination.scss'
import Prev from './Prev'

const Pagination_: FC<{
	page: number
	perPage: number
	dataLength: number
	pageToShowFirstLast: number
	pageToShowMiddle: number
}> = ({
	dataLength,
	page = 1,
	perPage,
	pageToShowFirstLast = 5,
	pageToShowMiddle = 3
}) => {
	const [totalPages, setTotalPages] = useState<number>(0)


	useEffect(() => {
		setTotalPages(
			Math.floor(dataLength / perPage) < dataLength / perPage
				? Math.floor(dataLength / perPage) + 1
				: Math.floor(dataLength / perPage)
		)
	}, [perPage, dataLength])

	if (totalPages === 1) {
		return <></>
	}

	if (totalPages <= pageToShowFirstLast) {
		const pArray = Array.from(
			{
				length: totalPages
			},
			(_, index) => index + 1
		)
		return (
			<div className='pagination'>
				<div className='pagination__middle'>
					{pArray.map(p => (
						<Link_ key={p} page={page} pageLink={p} />
					))}
				</div>
			</div>
		)
	}

	if (page + 1 <= pageToShowFirstLast) {
		const pArray = Array.from(
			{
				length: pageToShowFirstLast
			},
			(_, index) => index + 1
		)
		return (
			<div className='pagination'>
				<div className='pagination__left'>
					<Prev
						page={page}
						isDisabled={page === 1}
					/>
				</div>
				<div className='pagination__middle'>
					{pArray.map(p => (
						<Link_ key={p} page={page} pageLink={p} />
					))}

					<Dotted />
					<Link_ page={page} pageLink={totalPages} />
				</div>
				<div className='pagination__right'>
					<Next
						page={page}
                        totalPages={totalPages}
						isDisabled={false}
					/>
				</div>
			</div>
		)
	}

	if (page + pageToShowFirstLast > totalPages+1) {
		const pArray = Array.from(
			{
				length: pageToShowFirstLast
			},
			(_, index) => totalPages - (pageToShowFirstLast - index - 1)
		)
		return (
			<div className='pagination'>
				<div className='pagination__left'>
					<Prev
						page={page}
						isDisabled={false}
					/>
				</div>
				<div className='pagination__middle'>
					<Link_ page={page} pageLink={1} />
					<Dotted />

					{pArray.map(p => (
						<Link_ key={p} page={page} pageLink={p} />
					))}
				</div>
				<div className='pagination__right'>
					<Next
						page={page}
                        totalPages={totalPages}
						isDisabled={page === totalPages}
					/>
				</div>
			</div>
		)
	}

	const pArray = Array.from(
		{
			length: pageToShowMiddle
		},
		(_, index) => page + index - 1
	)

	return (
		<div className='pagination'>
			<div className='pagination__left'>
				<Prev
					page={page}
					isDisabled={false}
				/>
			</div>
			<div className='pagination__middle'>
				<Link_ page={page} pageLink={1} />
				<Dotted />

				{pArray.map(p => (
					<Link_ key={p} page={page} pageLink={p} />
				))}
				<Dotted />
				<Link_  page={page} pageLink={totalPages} />
			</div>
			<div className='pagination__right'>
				<Next
					page={page}
                    totalPages={totalPages}
					isDisabled={false}
				/>
			</div>
		</div>
	)
}

export default Pagination_
