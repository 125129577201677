import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './SummaryMobile.scss'

const SummaryMobile: FC<{widgetShow:boolean, 
	setWidgetShow:(val:boolean)=>void}> = ({setWidgetShow,widgetShow}) => {
	const [linkBack, setLinkBack] = useState<string>('/')

	useEffect(() => {
		const lc = JSON.parse(localStorage.getItem('booking') || '')
		setLinkBack(lc.backUrl || '')
	}, [])

	return (
		<div className='summary-mobile xl:hidden'>
			<div className='summary-mobile__container'>
				<Link to={linkBack} className='summary-mobile__item'>
					<span className='summary-mobile__back-icon fi-rs-arrow-small-left'></span>
					<span className='summary-mobile__back-name text-sm'>Назад</span>
				</Link>
				<button className='summary-mobile__item'
				onClick={()=>setWidgetShow(!widgetShow)}
				>
					<span className='summary-mobile__btn-name text-sm'>Отчет</span>
					<span className={
						widgetShow?
						'summary-mobile__btn-icon fi-rs-angle-down up'
					:'summary-mobile__btn-icon fi-rs-angle-down'}></span>
				</button>
			</div>
		</div>
	)
}

export default SummaryMobile
