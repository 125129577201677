import PhotoSwipe from 'photoswipe'
import { FC } from 'react'
import { GaleryImageItem } from '../../../types/types'
import './GaleryFromButton.scss'

const GaleryFromButton: FC<{
	images: GaleryImageItem[]
	btnClass: string
	btnTitle: string
}> = ({ images, btnClass, btnTitle }) => {

	const options = {}

	// @ts-ignore
	options.dataSource = images
	// @ts-ignore
	options.index = 0
	// @ts-ignore
	options.showHideAnimationType = 'none'

	return (
		<button
			className={`${btnClass} btn-galery`}
			type='button'
			onClick={e => {
				const pswp = new PhotoSwipe(options);
				pswp.on('uiRegister', function() {
				
					// @ts-ignore
					pswp.ui.registerElement({
					  name: 'bulletsIndicator',
					  className: 'pswp__bullets-indicator',
					  appendTo: 'wrapper',
					  onInit: (el, pswp) => {
						const bullets:HTMLDivElement[] = [];
						let bullet;
						let prevIndex = -1;
				  
						for (let i = 0; i < pswp.getNumItems(); i++) {
						  bullet = document.createElement('div');
						  bullet.className = 'pswp__bullet';
       
						  bullet.style.backgroundImage=`url("${images[i].src}")`
						  bullet.style.backgroundRepeat="no-repeat"
						  bullet.style.backgroundSize="cover"
						  bullet.onclick = (e) => {
							// @ts-ignore
							pswp.goTo(bullets.indexOf(e.target));
						  };
						  el.appendChild(bullet);
						  bullets.push(bullet);
						}
				  
						pswp.on('change', (a,) => {
						  if (prevIndex >= 0) {
							bullets[prevIndex].classList.remove('pswp__bullet--active');
						  }
						  bullets[pswp.currIndex].classList.add('pswp__bullet--active');
						  prevIndex = pswp.currIndex;
						});
					  }
					});
				  });
				pswp.init(); // initializing PhotoSwipe core adds it to DOM
			  
			}}
		>
			<span className='fi-rs-picture'></span>
			{btnTitle}
		</button>
	)
}

export default GaleryFromButton
