import { ErrorMessage } from '@hookform/error-message'
import { FC, useEffect } from 'react'
import {
	Controller,
	useFieldArray,
	useFormContext,
	useWatch
} from 'react-hook-form'
import Select from 'react-select'
import { baggageOptions, BookOption } from '../../constants'
import './BookBaggage.scss'

const BookBaggage: FC<{ }> = ({
}) => {
	const {
		formState: { errors },
	} = useFormContext()

	const { fields, append, prepend, remove, swap, move, insert, replace } =
		useFieldArray({
			name: 'bookBaggage'
		})




	return (
		<div className='book-baggage section'>
			<div className='book-baggage__container container'>
				<h2 className='book-baggage__title subtitle center blue'>Багаж</h2>

				<h3 className='subtitle center blue'>Ручная кладь</h3>
				<div className='book-baggage__item'>
					<span className='fi-rs-check-circle'></span>
					<p className='text'>
						В стоимость тура включена 1 небольшая сумка (например, дамская
						сумочка, ноутбук и т. д., которая должна помещаться под сиденьем
						перед вами) для провоза на борту.
					</p>
				</div>

				<h3 className='subtitle center blue'>Регистрируемый багаж</h3>
				<div className='book-baggage__item'>
					<span className='fi-rs-check-circle'></span>
					<p className='text'>
						Включено 1 регистрируемое место багажа на пассажира. Максимальный
						вес: 10 кг. Для дополнительного багажа выберите один из вариантов
						ниже.
					</p>
				</div>

				<div className='book-baggage__inner'>
					{fields.map((b, index1) => (
						<div key={b.id} className='book-baggage__field form-field'>
							<label className='form-field__label'>Пассажир {index1 + 1}</label>

							<ErrorMessage
								errors={errors}
								name={`bookBaggage.${index1}`}
								render={({ message }) => (
									<span className='form-field__error'>{message}</span>
								)}
							/>

							<Controller
								name={`bookBaggage.${index1}`}
								rules={{
									validate: value => value.value !== '' || 'выберите багаж'
								}}
								render={({ field: { onChange, value, ref } }) => (
									<Select
									ref={(elem) => {
										ref({
										  ...elem,
										  focus: () => {
											elem?.inputRef?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
											elem?.inputRef?.focus({ preventScroll: true });
										  }
										});
									  }}
										onChange={e => {
											onChange(e)
										}}
										value={value}
										classNamePrefix='select'
										isClearable={false}
										components={{
											IndicatorSeparator: () => null
										}}
										isSearchable={true}
										options={baggageOptions}
										styles={{
											singleValue: base => ({
												...base,
												padding: 5,
												color: '#002d72',
												fontWeight: 600,
												display: 'flex'
											}),

											control: base => ({
												...base,
												height: '68px',

												border: '1px solid rgb(0  0 0/ 22%)',
												boxShadow: 'none',
												'&:hover': {
													border: '1px solid rgb(0  0 0/ 22%)'
												}
											}),

											menu: base => ({
												...base,
												zIndex: 2,
												width: '88%',
												left: '50px'
											})
										}}
									/>
								)}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default BookBaggage
