import { FC } from 'react'
import { Link } from 'react-router-dom'
import './CardTb.scss'

export const CardTB: FC<{
	name: string
	slug: string
	image: string
	imageSrc: string
}> = ({ image, name, slug, imageSrc }) => {
	return (
		<div className='card-tb  w-100 xl:w-1/3 md:w-1/2'>
			<Link to={`/blog/${slug}`} className='card-tb__image'>
				<img src={`/images/${imageSrc}/${image}`} alt='' />
			</Link>
			<div className='card-tb__tags'>
				<Link to={`/blog/${slug}`} className='card-tb__tag text'>
					Lorem, ipsum.
				</Link>
				<Link to={`/blog/${slug}`} className='card-tb__tag text'>
					Lorem, ipsum.
				</Link>
			</div>
			<h3>
				<Link to={`/blog/${slug}`} className='card-tb__name subtitle blue'>
					{name}
				</Link>
			</h3>
			<div className='card-tb__author'>
				<span
					itemProp='author'
					itemScope={true}
					itemType='https://schema.org/Person'
				>
					<span itemProp='name text'>Diana Slon</span>
				</span>

				<time itemProp='datePublished' dateTime='15 August 2024'>
					15 August 2024
				</time>
			</div>
		</div>
	)
}
