import { Link, useParams } from 'react-router-dom'
import bannerImg from './assets/banner-blog.jpg'
import Banner from './components/banner/Banner'
import { CardTB } from './components/card-tb/CardTb'
import { useQueryBlogBySlug } from './hooks/useQueryBlogBySlug'
import { useQueryBlogs } from './hooks/useQueryBlogs'
import { useQueryBlogsByParams } from './hooks/useQueryBlogsByParams'
import './TravelBlog.scss'
import { useQueryCategoies } from './hooks/useQueryCategoies'

function TravelBlog() {
	let { slug } = useParams<'slug'>()

	const {
		data: dataBlog,
		isFetching,
		isPending,
		error,
		isSuccess
	} = useQueryBlogBySlug(slug || '')
	const { data: dataBlogs } = useQueryBlogs()
	const { data: list } = useQueryBlogsByParams(
		dataBlog?.type,
		dataBlog?.location_slug
	)

	const { data: categoies } = useQueryCategoies()


	const imageSrc = dataBlog?.type === 'tematic' ? 'tematics' : 'places'

	return (
		<>
			<Banner image={bannerImg} />
			<article className='travel-blog'>
				<div className='travel-blog__container container'>
					<h1 className='travel-blog__title title-big left blue'>
						{dataBlog?.title}
					</h1>

					<p className='travel-blog__author'>
						<span
							itemProp='author'
							itemScope={true}
							itemType='https://schema.org/Person'
						>
							<span itemProp='name'>Diana Slon</span>
						</span>

						<time itemProp='datePublished' dateTime='15 August 2024'>
							15 August 2024
						</time>
					</p>
					<div className='travel-blog__inner'>
						<div className='travel-blog__left'>
							<p className='text'>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
								libero, et necessitatibus culpa maiores ducimus praesentium
								consequatur qui reprehenderit perspiciatis.
							</p>
							<p className='text'>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
								vitae saepe, dicta atque excepturi asperiores ullam quis enim
								magnam illum accusamus fuga ratione culpa a ipsa tenetur, autem
								placeat consequuntur.
							</p>
							<p className='text'>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
								distinctio illo quam voluptate voluptas ipsa similique? Aliquam
								laborum error quidem.
							</p>

							<h2 className='title left blue'>Lorem ipsum dolor sit amet.</h2>
							<img className='travel-blog__img' src={`/images/${imageSrc}/card1.jpg`} alt='' />
							<p className='text'>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
								vitae saepe, dicta atque excepturi asperiores ullam quis enim
								magnam illum accusamus fuga ratione culpa a ipsa tenetur, autem
								placeat consequuntur.
							</p>
							<p className='text'>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
								distinctio illo quam voluptate voluptas ipsa similique? Aliquam
								laborum error quidem.
							</p>
							<ul className='travel-blog__list'>
								<li className='travel-blog__item'>
									<span className='fi-rs-check-circle'></span>
									<p className='text'>
										Lorem, ipsum dolor sit amet consectetur adipisicing elit.
										Eius, temporibus blanditiis. Recusandae ex numquam amet ea!
										Corrupti autem pariatur quisquam.
									</p>
								</li>
								<li className='travel-blog__item'>
									<span className='fi-rs-check-circle'></span>
									<p className='text'>
										Lorem, ipsum dolor sit amet consectetur adipisicing elit.
										Eius, temporibus blanditiis. Recusandae ex numquam amet ea!
										Corrupti autem pariatur quisquam.
									</p>
								</li>
								<li className='travel-blog__item'>
									<span className='fi-rs-check-circle'></span>
									<p className='text'>
										Lorem, ipsum dolor sit amet consectetur adipisicing elit.
										Eius, temporibus blanditiis. Recusandae ex numquam amet ea!
										Corrupti autem pariatur quisquam.
									</p>
								</li>
								<li className='travel-blog__item'>
									<span className='fi-rs-check-circle'></span>
									<p className='text'>
										Lorem, ipsum dolor sit amet consectetur adipisicing elit.
										Eius, temporibus blanditiis. Recusandae ex numquam amet ea!
										Corrupti autem pariatur quisquam.
									</p>
								</li>
							</ul>
							<h2 className='title left blue'>Lorem ipsum dolor sit amet.</h2>
							<img className='travel-blog__img' src={`/images/${imageSrc}/card2.jpg`} alt='' />
							<p className='text'>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
								vitae saepe, dicta atque excepturi asperiores ullam quis enim
								magnam illum accusamus fuga ratione culpa a ipsa tenetur, autem
								placeat consequuntur.
							</p>
							<h2 className='title left blue'>Lorem ipsum dolor sit amet.</h2>
							<img className='travel-blog__img' src={`/images/${imageSrc}/card3.jpg`} alt='' />
							<p className='text'>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
								vitae saepe, dicta atque excepturi asperiores ullam quis enim
								magnam illum accusamus fuga ratione culpa a ipsa tenetur, autem
								placeat consequuntur.
							</p>
							<h2 className='title left blue'>Lorem ipsum dolor sit amet.</h2>
							<img className='travel-blog__img' src={`/images/${imageSrc}/card4.jpg`} alt='' />
							<p className='text'>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
								vitae saepe, dicta atque excepturi asperiores ullam quis enim
								magnam illum accusamus fuga ratione culpa a ipsa tenetur, autem
								placeat consequuntur.
							</p>
						</div>
						<div className='travel-blog__right'>
							<h3 className='subtitle left blue'>Категории</h3>
							<ul className='travel-blog__categories'>
							{categoies?.map(c => (
									<li className='blog__categories-item' key={c.id}>
										<Link to='/blog' className='blog__categories-link'>
											<img width={30} height={30} src={`/images/categories/${c.image}`} alt='' />
											<h4 className='text-big'>{c.name}</h4>
										</Link>
									</li>
								))}
							</ul>
						</div>
					</div>

					<h2 className='title center blue'>Вам также может понравиться</h2>
					<div className='travel-blog__block'>
						{list
							?.filter((el, index) => index <= 3)
							.map((item, index) => (
								<CardTB
									key={index}
									image={item.image}
									imageSrc={imageSrc}
									name={item.title}
									slug={item.slug}
								/>
							))}
					</div>
				</div>
			</article>
		</>
	)
}

export default TravelBlog
