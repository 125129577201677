import dayjs from 'dayjs'
import customParseFormat from "dayjs/plugin/customParseFormat";

import { FC } from 'react'
import './picker-item.scss'


dayjs.extend(customParseFormat);

export const PickerItem: FC<{
  onChange: (val: string) => void
  value: string
  selectedValue: string
}> = ({ value, onChange, selectedValue }) => {


  return (
    <div
      className={value===selectedValue?'picker-item__month-day active':'picker-item__month-day'}
      onClick={(e) =>{onChange(value);e.stopPropagation()}}
    >
      { dayjs(value,'DD.MM.YYYY').date()}
    </div>
  )
}
