import { ErrorMessage } from '@hookform/error-message'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Select, { components } from 'react-select'
import userImg from '../../assets/user.svg'
import usersImg from '../../assets/users.svg'
import {
	BookOption,
	PersonOption,
	personOptions,
	roomOptions
} from '../../constants'
import './BookRoom.scss'

const BookRoom: FC<{
	onChangePerson: (val: PersonOption) => void
}> = ({ onChangePerson }) => {
	// @ts-ignore
	const ControlAdults = ({ children, ...props }) => (
		// @ts-ignore
		<components.Control {...props}>
			<span className=' custom-select__icon fi-rs-user'></span> {children}
		</components.Control>
	)
	// @ts-ignore
	const ControlRoom = ({ children, ...props }) => (
		// @ts-ignore
		<components.Control {...props}>
			<span className=' custom-select__icon fi-rs-bed-alt'></span> {children}
		</components.Control>
	)

	// @ts-ignore
	const MyOption = props => {
		const { innerProps, innerRef } = props
		return (
			<div ref={innerRef} {...innerProps} className='book-room__option '>
				{props.data.label}

				{props.data.value === '1' ? (
					<img
						width={20}
						height={20}
						src={userImg}
						className='book-room__option-icon '
					></img>
				) : (
					<img
						width={20}
						height={20}
						src={usersImg}
						className='book-room__option-icon '
					></img>
				)}
			</div>
		)
	}

	const {
		reset,
		formState: { errors },
		setValue
	} = useFormContext()

	return (
		<div className='book-room section'>
			<div className='book-room__container'>
				<h2 className='book-room__title subtitle center blue'>
					Выберите тип номера
				</h2>

				<div className='book-room__inner'>
					<div className='book-room__field form-field'>
						<label className='form-field__label'>Взрослых</label>
						<Controller
							name='bookPerson'
							render={({ field: { onChange, value, ref } }) => (
								<Select
									ref={elem => {
										ref({
											...elem,
											focus: () => {
												elem?.inputRef?.scrollIntoView({
													behavior: 'smooth',
													block: 'center',
													inline: 'nearest'
												})
												elem?.inputRef?.focus({ preventScroll: true })
											}
										})
									}}
									classNamePrefix='select'
									value={value}
									isClearable={false}
									components={{
										Control: ControlAdults,
										IndicatorSeparator: () => null
									}}
									isSearchable={false}
									options={personOptions}
									onChange={e => {
										onChange(e)
										// @ts-ignore
										onChangePerson(e)
									}}
									styles={{
										singleValue: base => ({
											...base,
											padding: 5,
											color: '#002d72',
											fontWeight: 600,
											display: 'flex'
										}),

										control: base => ({
											...base,

											border: '1px solid rgb(0  0 0/ 22%)',
											boxShadow: 'none',
											'&:hover': {
												border: '1px solid rgb(0  0 0/ 22%)'
											}
										}),

										menu: base => ({
											...base,
											zIndex: 2,
											width: '88%',
											left: '50px'
										})
									}}
								/>
							)}
						/>
					</div>
					<div className='book-room__field  form-field'>
						<label className='form-field__label'>Тип номера</label>
						<ErrorMessage
							errors={errors}
							name={`bookRoom`}
							render={({ message }) => (
								<span className='form-field__error'>{message}</span>
							)}
						/>

						<Controller
							name='bookRoom'
							rules={{ required: 'выберите номер' }}
							render={({ field: { onChange, value, ref } }) => (
								<Select
								ref={elem => {
									ref({
										...elem,
										focus: () => {
											elem?.inputRef?.scrollIntoView({
												behavior: 'smooth',
												block: 'center',
												inline: 'nearest'
											})
											elem?.inputRef?.focus({ preventScroll: true })
										}
									})
								}}
									onChange={onChange}
									value={value}
									isClearable={false}
									classNamePrefix='select'
									components={{
										Option: MyOption,
										Control: ControlRoom,
										IndicatorSeparator: () => null
									}}
									isSearchable={false}
									options={roomOptions}
									styles={{
										singleValue: base => ({
											...base,
											padding: 5,
											color: '#002d72',
											fontWeight: 600,
											display: 'flex'
										}),

										control: base => ({
											...base,

											border: '1px solid rgb(0  0 0/ 22%)',
											boxShadow: 'none',
											'&:hover': {
												border: '1px solid rgb(0  0 0/ 22%)'
											}
										}),

										menu: base => ({
											...base,

											width: '88%',
											left: '50px'
										})
									}}
								/>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BookRoom
