import { FC } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

const Next: FC<{
	page: number,
	totalPages:number
	isDisabled: boolean
}> = ({ page, isDisabled, totalPages}) => {

    const [paramsLast, setParams] = useSearchParams()
    const [paramsNext, setParamsNext] = useSearchParams()
	const location = useLocation()
	paramsLast.set('page', totalPages.toString())
	paramsNext.set('page', (page+1).toString())
	
	return (
		<>
			<Link
				to={location.pathname + '?' + paramsNext.toString()}
				className={
					isDisabled
						? 'pagination__link  pagination__link--navigation disabled'
						: 'pagination__link  pagination__link--navigation'
				}
			>
				<span className='fi-rs-angle-small-right'></span>
			</Link>
			<Link
				to={location.pathname + '?' + paramsLast.toString()}
				className={
					isDisabled
						? 'pagination__link  pagination__link--navigation disabled'
						: 'pagination__link  pagination__link--navigation'
				}
			>
				<span className='fi-rs-angle-double-small-right'></span>
			</Link>
		</>
	)
}

export default Next
