import { useQuery } from '@tanstack/react-query'
import { BlogItem } from '../types/types'
import { getBlogs } from '../api/getBlogs'



export const useQueryBlogBySlug = (slug:string) => {
	const { isPending, error, data, isFetching,isSuccess } = useQuery({
		queryKey: ['blogs'],
		queryFn: getBlogs,
		select:data=>data.filter(d=>d.slug ===slug)[0]
	})

	return { isPending, error, isFetching, data ,isSuccess}
}


