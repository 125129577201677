import { useEffect } from "react";

export const useBodyScrollLock = () => {
    useEffect(() => {
        let paddingOffset = window.innerWidth - document.body.offsetWidth + 'px';
        let pagePosition = window.scrollY;
      
        document.body.style.paddingRight = paddingOffset;

        const originalStyle = window.getComputedStyle(document.body).overflow;
        document.body.style.overflow = 'hidden';
         return () =>{document.body.style.overflow = originalStyle;
            document.body.style.paddingRight = '0px';
         };
    }, []);
};