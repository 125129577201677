import { useQuery } from '@tanstack/react-query'
import { getTematicsRating } from '../api/getTematicsRating'



export const useQueryTematicRatingBySlug = (slug:string) => {

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['tematics-rating'],
		queryFn: getTematicsRating,
		select:data=>data.filter(d=>d.slug===slug)[0]
	})

	return { isPending, error, isFetching, data}
}
