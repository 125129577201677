import { memo, useState, FC, useRef, useEffect } from 'react'
import './Steps.scss'

interface StepItem {
	id: number
	name: string
	text: string
}

const Steps = memo( function Steps() {
	const [activeStep, setActiveStep] = useState(0)

	const handleChangeStep = (val: number) => {
		setActiveStep(val)
	}
	const steps = [
		{
			id: 1,
			name: 'Lorem, ipsum dolor',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		},
		{
			id: 2,
			name: 'Lorem, ipsum dolor',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		},
		{
			id: 3,
			name: 'Lorem, ipsum dolor',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		},
		{
			id: 4,
			name: 'Lorem, ipsum dolor',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		},
		{
			id: 5,
			name: 'Lorem, ipsum dolor',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		}
	]
	return (
		<div className='steps'>
			<div className='steps__label subtitle'>День</div>
			<div className='steps__list'>
				{steps.map(s => (
					<Step
						key={s.id}
						setValue={handleChangeStep}
						value={activeStep}
						step={s}
					/>
				))}
			</div>
		</div>
	)
})

const Step: FC<{
	step: StepItem
	value: number
	setValue: (val: number) => void
}> = ({ setValue, value, step }) => {

	const refStep = useRef<HTMLDivElement>(null)

	useEffect(()=>{
	
		
		if(value===step.id){
			setTimeout(()=>{

				const headersHeight =
					// @ts-ignore
					document.querySelector('.header').clientHeight +
					// @ts-ignore
					document.querySelector('.tour__header').clientHeight

				// @ts-ignore
				const { top } = refStep.current.getBoundingClientRect()
				window.scrollBy({
					top: -(headersHeight - top+30),
					behavior: 'smooth'
				})
			},400)
		}

	},[value,step])

	return (
		<div
			className={
				value === step.id ? 'steps__step step open' : 'steps__step step'
			}
			ref={refStep}
		>
			<div className='step__number text'>{step.id}</div>

			<div
				className={'step__title'}
				onClick={e => {

					
					// @ts-ignore

					 setValue(value === step.id ? 0 : step.id)
				}}
			>
				<h3 className='step__name subtitle'>{step.name}</h3>
				<span className='fi-rs-angle-small-down'></span>
			</div>
			<div className='step__text'>
				<p   className='text'>{step.text}</p>
				<p  className='text'>{step.text}</p>
				<p  className='text'>{step.text}</p>
			</div>
		</div>
	)
}

export default Steps