import dayjs from 'dayjs'
import { FC } from 'react'
import { TourItem } from '../../types/types'
import CardSelBD from './CardSelBD'
import './ToursResultBD.scss'

const ToursResultBD: FC<{
	townFrom: string
	townTo: string
	dates: dayjs.Dayjs[]
	tour: TourItem | undefined
}> = ({ townFrom, townTo, tour, dates }) => {
	return (
		<div className='tours-result-bd section'>
			<div className='tours-result-bd__container'>
				<h2 className='tours-result-bd__title title center blue'>
					Доступные варианты
				</h2>
				<div className='tours-result-bd__cards   g-col-1 md:grid-cols-2'>
					{dates.map(d => (
						<CardSelBD
							key={d.format('DD.MM.YYYY')}
							townFrom={townFrom}
							date={d.format('DD MMM YYYY')}
							image={tour?.image || ''}
							location={tour?.location || ''}
							name={tour?.name || ''}
							price={tour?.price || 0}
							pricePrev={tour?.pricePrev || 0}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default ToursResultBD
