import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQueryPlaces } from '../../hooks/useQueryPlaces'
import { useQueryTownsFrom } from '../../hooks/useQueryTownsFrom'
import { PlaceItem, TownsFromItem } from '../../types/types'
import MobileDate from './MobileDate'
import MobileDeparture from './MobileDeparture'
import MobileDestination from './MobileDestination'
import './ToursFiltersMobile.scss'

const ToursFiltersMobile: FC<{}> = ({}) => {
	const [departureShow, setDepartureShow] = useState(false)
	const { data: departureOptions, isSuccess } = useQueryTownsFrom()

	const [destinationShow, setDestinationShow] = useState(false)
	const { data: destinationOptions, isSuccess: destSuccess } = useQueryPlaces()

	const [dateShow, setDateShow] = useState(false)

	let [searchParams, setSearchParams] = useSearchParams()
	let navigate = useNavigate()

	return (
		<div className='tours-filters-mobile'>
			<div className='tours-filters-mobile__inner'>
				<button
					className='tours-filters-mobile__btn text-sm'
					onClick={() => setDateShow(true)}
				>
					<span className='fi-rs-calendar  tours-filters-mobile__icon'></span>
					<span className='tours-filters-mobile__btn-name'>Даты</span>
				</button>
				<button
					className='tours-filters-mobile__btn text-sm'
					onClick={() => setDepartureShow(true)}
				>
					<span className='fi-rs-plane-departure tours-filters-mobile__icon'></span>
					<span className='tours-filters-mobile__btn-name'>Отправление</span>
				</button>
				<button
					className='tours-filters-mobile__btn text-sm'
					onClick={() => setDestinationShow (true)}
				>
					<span className='fi-rs-plane-arrival tours-filters-mobile__icon'></span>
					<span className='tours-filters-mobile__btn-name'>Прилет</span>
				</button>
			</div>

			{departureShow &&
				createPortal(
					<MobileDeparture
						show={departureShow}
						setShow={(val: boolean) => setDepartureShow(val)}
						onApply={(val: TownsFromItem) => {
							setDepartureShow(false)
						}}
						options={departureOptions || []}
					/>,
					document.body
				)}
			{destinationShow &&
				createPortal(
					<MobileDestination
						show={destinationShow}
						setShow={(val: boolean) => setDestinationShow(val)}
						onApply={(val: PlaceItem) => {
							setDestinationShow(false)
						}}
						options={destinationOptions || []}
					/>,
					document.body
				)}
			{dateShow &&
				createPortal(
					<MobileDate
						show={dateShow}
						setShow={(val: boolean) => setDateShow(val)}
						onApply={(val: string) => {
							setDateShow(false)
							// searchParams.set(
							// 	EnumSearchUrl.TownFrom,
							// 	departureValue?.slug || ''
							// )
							// searchParams.set(
							// 	EnumSearchUrl.TownTo,
							// 	destinationValue?.slug || ''
							// )
							// searchParams.set(
							// 	EnumSearchUrl.DateFrom,
							// 	val
							// )

							// navigate('/tours/select?' + searchParams.toString())
						}}
					/>,
					document.body
				)}
		</div>
	)
}

export default ToursFiltersMobile
