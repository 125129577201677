import { FC } from 'react'
import './Banner.scss'

const Banner: FC<{
	image: string
}> = ({ image }) => {
	return (
		<div className='banner'>
			<img className='banner__image' src={image} alt='' />
		</div>
	)
}

export default Banner
