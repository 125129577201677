import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import BookFilters from './components/book-filters/BookFilters'
import BookHeader from './components/book-header/BookHeader'
import { LoaderCss } from './components/ui/loader/LoaderCss'
import { useDelay } from './hooks/useDelay'
import { useQueryPlaceBySlug } from './hooks/useQueryPlaceBySlug'
import { useQueryTourBySlug } from './hooks/useQueryTourBySlug'
import { useQueryTownFromBySlug } from './hooks/useQueryTownFromBySlug'
import ToursResultBD from './pages/tours-booking-dates/ToursResultBD'
import { EnumSearchUrl } from './types/types'
import BookHeaderMobile from './components/book-header-mobile/BookHeaderMobile'
import SummaryMobile from './components/summary-mobile/SummaryMobile'

function ToursBookingDates() {
	let [searchParams, setSearchParams] = useSearchParams()
	const [dateFrom, setDateFrom] = useState<string>('')
	const [dates, setDates] = useState<dayjs.Dayjs[]>([])
	const [datesOptions, setDatesOptions] = useState<dayjs.Dayjs[]>([])
	const { slug } = useParams()

	const handleChange = (val: dayjs.Dayjs[]) => {
		setDates(val)
	}

	const { isLoading } = useDelay(slug, 2000)

	const { data: tour, isPending, error } = useQueryTourBySlug(slug || '')
	const { data: townFrom } = useQueryTownFromBySlug(
		searchParams.get(EnumSearchUrl.TownFrom) || ''
	)
	const { data: townTo } = useQueryPlaceBySlug(
		searchParams.get(EnumSearchUrl.TownTo) || ''
	)

	useEffect(() => {
		const arrDates = tour?.dates.map(d => dayjs(d, 'DD.MM.YYYY', true))
		setDates(arrDates || [])
		setDatesOptions(arrDates || [])
	}, [tour, isPending])

	if (isLoading) return <LoaderCss />

	return (
		<>
			<BookHeader />
			<BookHeaderMobile step={1} />
			<BookFilters
				dates={dates}
				datesOptions={datesOptions}
				onChange={handleChange}
			/>

			<ToursResultBD
				townFrom={townFrom?.name || ''}
				townTo={townTo?.name || ''}
				dates={dates}
				tour={tour}
			/>
		</>
	)
}

export default ToursBookingDates
