import { CardHelp } from './components/card-help/CardHelp'
import './Help.scss'
import { useQueryHelp } from './hooks/useQueryHelp'

function Help() {
	const { data, isPending, error } = useQueryHelp()

	if (isPending) return <div>'Loading...'</div>

	return (
		<>
			<div className='help section'>
				<div className='help__container container'>
					<h1 className='help__title title center'>Центр помощи</h1>

					<p className='help__text subtitle center'>
						Команда нашего отдела путешествий всегда готова помочь.
					</p>
					<div className='help__inner'>
						{data?.map((item, index) => (
							<CardHelp key={index} image={item.image} name={item.name} />
						))}
					</div>
				</div>
			</div>
		</>
	)
}

export default Help
