import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TownsFromItem } from '../../types/types'
import './ModalDeparture.scss'
import { useBodyScrollLock } from '../../hooks/useBodyScrollLock '

const ModalDeparture: FC<{
	isOpen: boolean
	options: TownsFromItem[]|[]
	value: TownsFromItem
	onNext: (val: TownsFromItem) => void
	onBack: () => void
}> = ({ onNext,onBack, value, options, isOpen }) => {

	useBodyScrollLock()


	const [dep,setDep]=useState<TownsFromItem>(value)


	return (
		<div className={isOpen ? 'modal-departure open' : 'modal-departure '}>
			<div className='modal-departure__content'>
				<div className='modal-departure__top'>
					<div className='modal-departure__list'>
						<h3 className='modal-departure__title title left blue'>
							Выберите город отправления
						</h3>
						{options.map(o => (
							<div key={o.id} 
							className={dep.id===o.id?'modal-departure__item checked':'modal-departure__item'}
							onClick={()=>setDep(o)}
							>
								<span className='fi-rs-check'></span>
								<div className='text'>{o.name}</div>
							</div>
						))}
					</div>
				</div>
				<div className='modal-departure__bottom'>
					<button className='modal-departure__back text-sm'
					onClick={()=>onBack()}
					>
						<span className='fi-rs-arrow-small-left'></span>
						Назад
					</button>
					<button className='modal-departure__next text-sm'
					onClick={()=>onNext(dep)}
					>
						Далее
						<span className='fi-rs-arrow-small-right'></span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default ModalDeparture
