import { FC } from 'react'
import { Link } from 'react-router-dom'
import './Choose.scss'
import { useQueryTours } from '../../hooks/useQueryTours'
import { EnumTourType } from '../../types/types'

const CardC: FC<{
	name: string
	slug: string
	image: string
}> = ({ image, name,slug }) => {
	return (
		<div className='card-c  w-100 md:w-1/2  xl:w-1/4 '>
			<Link to={`/tematics/${slug}`} className='card-c__image'>
				<img src={`/images/tematics/${image}`} alt='' />
			</Link>
			<h3 className='card-c__title'>
				<Link to={`/tematics/${slug}`}>{name}</Link>
			</h3>
			<p className='card-c__text'>
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut corporis
				sapiente impedit soluta vero, officiis a deserunt amet beatae
				perspiciatis!
			</p>
			<Link to={`/tematics/${slug}`} className='card-c__blog'>
			  <span>Смотреть описание</span>
			  <span className='fi-rs-arrow-small-right'></span>
			</Link>
		</div>
	)
}

function Choose() {
	const {data,isPending,error}  = useQueryTours(EnumTourType.Tematics)

	if (isPending) return(
		<div>'Loading...'</div>
	) 

	return (
		<div className='choose section'>
			<div className='choose__container container'>
				<h2 className='choose__title title center'>По советам проффесионалов</h2>
				<div className='choose__block'>
					{data?.filter((el,index) => index <= 4)
						.map((item, index) => (
							<CardC key={index} image={item.image} 
							name={item.name} 
							slug={item.slug}
							/>
						))}
				</div>
			</div>
		</div>
	)
}

export default Choose
