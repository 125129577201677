import { FC } from 'react'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'

const Link_: FC<{
	pageLink: number
	page: number
}> = ({ page, pageLink }) => {
	
	const [params, setParams] = useSearchParams()
	const location = useLocation()
	params.set('page', pageLink.toString())
	

	return (
		<Link
			to={location.pathname + '?' + params.toString()}
			className={
				page === pageLink ? 'pagination__link active' : 'pagination__link'
			}
		>
			{pageLink}
		</Link>
	)
}

export default Link_
