import { FC, useEffect, useState } from 'react'
import labelImg from '../../assets/icons/card-label.svg'
import locationImg from '../../assets/icons/placeholder.png'
import guidImg from '../../assets/icons/guide.svg'
import moonImg from '../../assets/icons/moon.svg'
import airplaneImg from '../../assets/icons/airplane.svg'

import './CardT.scss'
import { Link } from 'react-router-dom'

const months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Okt',
	'Nov',
	'Dec'
]

const Month: FC<{
	name: string
	year: number
	month: number
}> = ({ year, name, month }) => {
	const [valActive, setValActive] = useState<boolean>(false)

	useEffect(() => {
		const currentMonth = new Date().getMonth()
		setValActive(false)
		if (year === 2024) {
			if (currentMonth < month) {
				setValActive(true)
			}
		}

		if (year === 2025) {
			setValActive(true)
		}
	}, [year, month])

	return (
		<div className={valActive ? 'month active' : 'month'}>
			<span className='text-xs'>{name}</span>
		</div>
	)
}

const CardT: FC<{
	title: string
	location: string
	slug: string
	image: string
	pricePrev: number
	price: number
}> = ({ image, location, title, price, pricePrev,slug }) => {
	const [valYear, setValYear] = useState<number>(2024)

	return (
		<div className='card-t'>
			<div className='card-t__label'>
				<img src={labelImg} alt='' />
				<span className='card-t__label-text text-xs'>Save €70</span>
			</div>
			<div className='card-t__image'>
				<img src={`/images/places/${image}`} alt='' />
				<div className='card-t__from text-sm'>FROM</div>
				<div className='card-t__prices text-big'>
					<span className='card-t__price-prev '>&#8381;{pricePrev} </span>
					&nbsp;
					<span className='card-t__price'>&#8381;{price}</span>
				</div>
			</div>
			<div className='card-t__info'>
				<div className='card-t__location text'>
					<img width={20} height={20} src={locationImg} alt='' />
					<span>{location}</span>
				</div>
				<h4 className='card-t__title subtitle blue'>{title}</h4>
				<div className='card-t__switch-wrapper'>
					<div className='card-t__switch'>
						<button
							className={
								valYear === 2024
									? 'card-t__btn  card-t__btn--left active text2'
									: 'card-t__btn card-t__btn--left text2'
							}
							onClick={() => setValYear(2024)}
						>
							2024
						</button>
						<button
							className={
								valYear === 2025
									? 'card-t__btn card-t__btn--right  active text2'
									: 'card-t__btn card-t__btn--right text2'
							}
							onClick={() => setValYear(2025)}
						>
							2025
						</button>
					</div>
				</div>
				<div className='card-t__months'>
					{months.map((y, index) => (
						<Month name={y} key={index} year={valYear} month={index} />
					))}
				</div>

				<div className='card-t__options'>
					<div className='card-t__option'>
						<img
							width={20}
							height={20}
							className='card-t__option-img'
							src={guidImg}
						/>
						<span className='card-t__option-text text-sm blue center'>Fully Guided</span>
					</div>
					<div className='card-t__option'>
						<img
							width={20}
							height={20}
							className='card-t__option-img'
							src={moonImg}
						/>
						<span className='card-t__option-text text-sm blue'>7 Nights</span>
					</div>
					<div className='card-t__option'>
						<img
							width={20}
							height={20}
							className='card-t__option-img'
							src={airplaneImg}
						/>
						<span className='card-t__option-text text-sm blue'>Flights & Transfers</span>
					</div>
				</div>
			</div>
			<Link className='card-t__link' to={`/tours/${slug}`}></Link>
		</div>
	)
}

export default CardT
