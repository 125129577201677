import { FC, useEffect, useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import CardT from '../../components/card-t/CardT'
import { TourItem } from '../../types/types'
import './PlaceTop.scss'
import { useParams } from 'react-router-dom'

const PlaceTop: FC<{
	name: string | undefined
}> = ({ name }) => {
	
	const { slug } = useParams()

	const [listTours, setListTours] = useState<TourItem[]>([])
	const navigation = {
		enabled: true,
		nextEl: '.place-top__next',
		prevEl: '.place-top__prev'
	}

	useEffect(() => {
		fetch(`${process.env.PUBLIC_URL}/tours.json`)
			.then(response => {
				return response.json()
			})
			.then(data => {
				//@ts-ignore
				setListTours(data.filter(el => el.location_slug === slug))
			})
	}, [])

	return (
		<div className='place-top section'>
			<div className='place-top__container container'>
				<h2 className='place-top__title title center blue'>
					Популярные туры в {name}
				</h2>
				<div className='place-top__wrapper'>
					<Swiper
						modules={[Navigation]}
						navigation={navigation}
						centerInsufficientSlides={true}
						slidesPerView={'auto'}
						spaceBetween={20}
					>
						{listTours.map((item, index) => (
							<SwiperSlide key={index}  className='sm:max-w-[405px]'>
								<CardT
									image={item.image}
									slug={item.slug}
									location={item.location}
									price={item.price}
									pricePrev={item.pricePrev}
									title={item.name}
								/>
							</SwiperSlide>
						))}
					</Swiper>
					<button className='place-top__prev  hidden lg:block'>
						<span className='fi-rs-angle-left'></span>
					</button>
					<button className='place-top__next  hidden lg:block'>
						<span className=' fi-rs-angle-right'></span>
					</button>
				</div>
			</div>

			<div className='border'></div>
		</div>
	)
}

export default PlaceTop
