import { FC } from 'react'
import { useKeyEscape } from '../../hooks/useKeyEscape'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { PlaceItem, TownsFromItem } from '../../types/types'
import './PlaceList.scss'



const PlaceList: FC<{
	options: PlaceItem[]|TownsFromItem[]
	onChange: (value: string) => void
	isShow: boolean
	setShow: (val: boolean) => void
}> = ({ onChange, isShow, setShow,options }) => {
	

	useKeyEscape(() => {
		setShow(false)
		
	})



	return (
		<div className={isShow ? 'place-list open' : 'place-list '} >
			<div className='place-list__wrapper'>
				<ul className='place-list__items'>
					{options.map(item => (
						<li
							key={item.id}
							className='place-list__item'
							onClick={e => {
								onChange(item.name)
								setShow(false)
								e.stopPropagation()
							}}
						>
							{item.name}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export default PlaceList
