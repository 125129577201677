import { useQuery } from '@tanstack/react-query'
import { getReviews } from '../api/getReviews'



export const useQueryReviews = () => {

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['reviews'],
		queryFn: getReviews
	})

	return { isPending, error, isFetching, data}
}
