import { FC, useRef, useEffect } from 'react'
import './RatingStars.scss'

const RatingStars: FC<{ value: number }> = ({ value }) => {
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (ref.current) {
			ref.current.style.width = Math.floor((100 * value) / 5) + '%'
		}
	}, [value])

	return (
		<div className='rating-stars'>
			<div className='rating-stars__stars'>
				<span
					className='rating-stars__star-empty'
					style={{
						backgroundImage: `url("/images/star.svg")`
					}}
				></span>
				<span
					className='rating-stars__star-empty'
					style={{
						backgroundImage: `url("/images/star.svg")`
					}}
				></span>
				<span
					className='rating-stars__star-empty'
					style={{
						backgroundImage: `url("/images/star.svg")`
					}}
				></span>
				<span
					className='rating-stars__star-empty'
					style={{
						backgroundImage: `url("/images/star.svg")`
					}}
				></span>
				<span
					className='rating-stars__star-empty'
					style={{
						backgroundImage: `url("/images/star.svg")`
					}}
				></span>
			</div>
			<div className='rating-stars__stars-fill' ref={ref}>
				<span className='rating-stars__star-fill'
                style={{
						backgroundImage: `url("/images/star-fill.svg")`
					}}></span>
				<span className='rating-stars__star-fill'
                style={{
						backgroundImage: `url("/images/star-fill.svg")`
					}}></span>
				<span className='rating-stars__star-fill'
                style={{
						backgroundImage: `url("/images/star-fill.svg")`
					}}></span>
				<span className='rating-stars__star-fill'
                style={{
						backgroundImage: `url("/images/star-fill.svg")`
					}}></span>
				<span className='rating-stars__star-fill'
                style={{
						backgroundImage: `url("/images/star-fill.svg")`
					}}></span>
			</div>
		</div>
	)
}

export default RatingStars
