import { useQuery } from '@tanstack/react-query'
import { getPlaces } from '../api/getPlaces'


export const useQueryPlaceBySlug = (slug:string
) => {

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['places'],
		queryFn: getPlaces,
		select:data=>data.filter(d=>d.slug===slug)[0]

	})

	return { isPending, error, isFetching, data}
}
