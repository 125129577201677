import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import dayjs from 'dayjs'

import './BookFilters.scss'
import calendarImg from './calendar.svg'
import closeImg from './close.svg'
import ModalDates from './ModalDates'

export interface IDatesFilter{
	year:number
	months:number[]
}

const BookFilters: FC<{
	dates: dayjs.Dayjs[]
	datesOptions: dayjs.Dayjs[]
	onChange: (val: dayjs.Dayjs[]) => void
}> = ({ dates, onChange, datesOptions }) => {
	const [showModalDates, setShowModalDates] = useState<boolean>(false)
	const [filterYear, setFilterYear] = useState<number|undefined>()
	const [filterMonths, setFilterMonths] = useState<number[]>([])


	const handleShowModalDates = (val: boolean) => {
		setShowModalDates(val)
	}
	

	const changeFilterYear = (val: number) => {
		setFilterYear(val)
	}
	
	const changeFilterMonths = (val: number[]) => {
		setFilterMonths(val)
	}

	const handleFilter=(val:number)=>{
		if(filterMonths.length===1){
			onChange(datesOptions)
			setFilterYear(undefined)
			setFilterMonths([])
		}else{
			setFilterMonths(filterMonths.filter(m=>m!==val))
			onChange(dates.filter(d=>!d.isSame(`${filterYear}-0${val + 1}-01`, 'month')))
		}
	}

	

	return (
		<div className='book-filters'>
			<div className='book-filters__container'>
				<div className='book-filters__top'>
					<button
						className='book-filters__btn text'
						onClick={() => setShowModalDates(true)}
					>
						<img width={20} height={20} src={calendarImg} alt='' />
						<span>Даты</span>
					</button>
				</div>
				<div className='book-filters__bottom'>
					<div className='book-filters__list'>
						{filterMonths?.map(m => (
							<div key={m} className='book-filters__filter'>
								Даты: {dayjs(`${filterYear}-${m + 1}-01`).format('MMM YYYY')}
								<img width={10} height={10} src={closeImg} alt="" 
								onClick={()=>handleFilter(m)}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			{showModalDates &&
				createPortal(
					<ModalDates
						show={showModalDates}
						onShow={handleShowModalDates}
						dates={dates}
						datesOptions={datesOptions}
						onChange={onChange}
						onChangeFilterYear={changeFilterYear}
						onChangeFilterMonths={changeFilterMonths}
					/>,
					document.body
				)}
		</div>
	)
}

export default BookFilters
