import { FC, useEffect, useState } from 'react'
import './picker.scss'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { PickerItem } from './picker-item/PickerItem'
import utc from 'dayjs/plugin/utc' // ES 2015
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
// import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015

import { ChevronLeft, ChevronRight } from 'lucide-react'
dayjs.extend(customParseFormat)
// import cn from 'classnames'
dayjs.extend(isoWeek)
dayjs.extend(utc)
dayjs.extend(timezone)

export const Picker: FC<{
	value: string
	onChange: (v: string) => void
}> = ({ value, onChange }) => {
	const currentDate = dayjs()

	const [choicesDate, setChoicesDate] = useState<dayjs.Dayjs>(
		dayjs(value, 'DD.MM.YYYY', true)
	)

	const countDaysMonth = choicesDate.daysInMonth()
	const countDaysOffset = choicesDate.startOf('month').isoWeekday()

	const handleNext = () => {
		setChoicesDate(choicesDate.add(1, 'month'))
	}

	const handlePrev = () => {
		setChoicesDate(choicesDate.subtract(1, 'month'))
	}

	const handleItemChange = (val: string) => {
		onChange(val)
	}

	const months = [
		{ value: 0, name: 'Январь' },
		{ value: 1, name: 'Февраль' },
		{ value: 2, name: 'Март' },
		{ value: 3, name: 'Апрель' },
		{ value: 4, name: 'Май' },
		{ value: 5, name: 'Июнь' },
		{ value: 6, name: 'Июль' },
		{ value: 7, name: 'Август' },
		{ value: 8, name: 'Сентябрь' },
		{ value: 9, name: 'Октябрь' },
		{ value: 10, name: 'Ноябрь' },
		{ value: 11, name: 'Декабрь' }
	]
	return (
		<div className='picker'>
			<div className='picker__list'>
				<div className='picker__top'>
					<button
						disabled={
							choicesDate.month() === 11 &&
							choicesDate.year() === currentDate.year() + 1
						}
						className='picker__next'
						onClick={handleNext}
					>
						<ChevronRight />
					</button>
					<button
						disabled={
							choicesDate.month() === currentDate.month() &&
							choicesDate.year() === currentDate.year()
						}
						className='picker__prev'
						onClick={handlePrev}
					>
						<ChevronLeft />
					</button>
					<div className='picker__month'>
						<select
							className='picker__select-month text-big'
							tabIndex={-1}
							value={choicesDate.month()}
							onChange={e => {
								setChoicesDate(choicesDate.month(+e.target.value))
							}}
						>
							{months.map(m => (
								<option
									key={m.value}
									className='text'
									value={m.value}
									disabled={
										m.value < currentDate.month() &&
										choicesDate.year() === currentDate.year()
									}
								>
									{m.name}
								</option>
							))}
						</select>

						<select
							className='picker__select-year text-big'
							tabIndex={-1}
							value={choicesDate.year()}
							onChange={e => {
								setChoicesDate(choicesDate.year(+e.target.value))
							}}
						>
							<option className='text' value={currentDate.year()}>{currentDate.year()}</option>
							<option className='text' value={currentDate.year() + 1}>
								{currentDate.year() + 1}
							</option>
						</select>
					</div>
				</div>
				<div className='picker__week-days'>
					<span className='picker__week-day'>Пн</span>
					<span className='picker__week-day'>Вт</span>
					<span className='picker__week-day'>Ср</span>
					<span className='picker__week-day'>Чт</span>
					<span className='picker__week-day'>Пт</span>
					<span className='picker__week-day weekend'>Сб</span>
					<span className='picker__week-day weekend'>Вс</span>
				</div>

				<div className='picker__month-days'>
					{countDaysOffset > 1 && (
						<div
							className='picker__month-day-offset'
							style={{ gridColumn: `${countDaysOffset - 1} span` }}
						></div>
					)}
					{Array.from<number>({ length: countDaysMonth }).map((m, index) => (
						<PickerItem
							key={index}
							value={choicesDate
								.startOf('month')
								.add(index, 'day')
								.format('DD.MM.YYYY')}
							onChange={handleItemChange}
							selectedValue={value}
						/>
					))}
				</div>
			</div>
		</div>
	)
}
