import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Recommend.scss'
import { useQueryPlaces } from '../../hooks/useQueryPlaces'

interface Item {
	id: number
	image: string
	location: string
	slug: string
}

const CardR: FC<{
	location: string
	slug: string
	image: string
}> = ({ image, location,slug }) => {
	return (
		<div className='card-r w-100 lg:w-1/5 sm:w-1/2  md:w-1/4'>
			<Link to={`/places/${slug}`} className='card-r__image'>
				<img
					
					src={`images/places/${image}`}
					alt=''
				/>
			</Link>
			<Link to={`/places/${slug}`} className='card-r__text subtitle center blue'>{location}</Link>
		</div>
	)
}

function Recommend() {
	const {data,isPending,error}  = useQueryPlaces()

	if (isPending) return(
		<div>'Loading...'</div>
	) 
	

	return (
		<div className='recommend section'>
			<div className='recommend__container container'>
				<h2 className='recommend__title title center blue'>
					Рекомендуемые направления для отдыха в 2025 году
				</h2>
				<div className='recommend__block'>
					{data?.filter(el => el.id <= 10)
						.map((item, index) => (
							<CardR key={index} image={item.image} location={item.location} slug={item.slug} />
						))}
				</div>
			</div>
		</div>
	)
}

export default Recommend
