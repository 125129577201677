import { FC } from 'react'
import './AvatarReview.scss'


const AvatarReview: FC<{ name: string, location:string }> = ({
    location,name }) => {
	return (
		<div className='avatar-review'>
					<div className='avatar-review__top'>
						<div className='avatar-review__logo subtitle'>{name.slice(0, 1)}</div>
						<div className='avatar-review__author'>
							<div className='avatar-review__name text-big'>{name}</div>
							<div className='avatar-review__from text'>{location}</div>
						</div>
					</div>
				</div>
	)
}

export default AvatarReview
