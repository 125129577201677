import bannerImg from './assets/banner.webp'
import Features from './components/features/Features'

import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate, useSearchParams } from 'react-router-dom'
import './Main.scss'
import ModalDate from './components/ModalDate/ModalDate'
import ModalDeparture from './components/ModalDeparture/ModalDeparture'
import ModalDestination from './components/ModalDestination/ModalDestination'
import Banner from './components/banner/Banner'
import Search from './components/search/Search'
import { useQueryPlaces } from './hooks/useQueryPlaces'
import { useQueryTownsFrom } from './hooks/useQueryTownsFrom'
import ElParks from './pages/main/ElParks'
import Experts from './pages/main/Experts'
import MainTematic from './pages/main/MainTematic'
import Slogan from './pages/main/Slogan'
import Top from './pages/main/Top'
import { EnumSearchUrl, PlaceItem, TownsFromItem } from './types/types'
import { depPlaceholder, destPlaceholder } from './constants'

function Main() {


	const [departureShow, setDepartureShow] = useState(false)
	const [departureValue, setDepartureValue] = useState<TownsFromItem>()
	const { data: departureOptions, isSuccess } = useQueryTownsFrom()

	const [destinationShow, setDestinationShow] = useState(false)
	const [destinationValue, setDestinationValue] = useState<PlaceItem>()
	const { data: destinationOptions, isSuccess: destSuccess } = useQueryPlaces()

	const [dateShow, setDateShow] = useState(false)
	const [dateValue, setDateValue] = useState<string>(
		dayjs().format('DD.MM.YYYY')
	)

	let [searchParams, setSearchParams] = useSearchParams()
	let navigate = useNavigate()

	useEffect(() => {
		if (isSuccess) {
			if (departureOptions) {
				setDepartureValue(departureOptions[0])
			}
		}
	}, [isSuccess])

	useEffect(() => {
		if (isSuccess) {
			if (destinationOptions) {
				setDestinationValue(destinationOptions[0])
			}
		}
	}, [destSuccess])

	return (
		<div className='main-page'>
			<Banner image={bannerImg} />
			<Slogan />
			<div className='main-page__wrapper'>
				<div className='search-page__wrapper  hidden lg:block'>
					<Search />
				</div>
				<div className='search-mobile' onClick={() => setDepartureShow(true)}>
					<span className='search-mobile__text'>Куда вы хотите полететь?</span>
					<button>
						<span className='fi-rs-search'></span>
					</button>
				</div>
			</div>
			<Features />
			<Experts />
			<div className='border'></div>
			<Top />
			<div className='border'></div>
			<MainTematic />
			<div className='border'></div>
			<ElParks />
			{departureShow &&
				createPortal(
					<ModalDeparture
						isOpen={departureShow}
						onNext={(val: TownsFromItem) => {
							setDepartureValue(val)
							setDepartureShow(false)
							setDestinationShow(true)
						}}
						onBack={() => setDepartureShow(false)}
						value={departureValue || depPlaceholder}
						options={departureOptions || []}
					/>,
					document.body
				)}
			{destinationShow &&
				createPortal(
					<ModalDestination
						isOpen={destinationShow}
						onNext={(val: PlaceItem) => {
							setDestinationValue(val)
							setDestinationShow(false)
							setDateShow(true)
						}}
						onBack={() => {
							setDepartureShow(true)
							setDestinationShow(false)
						}}
						value={destinationValue || destPlaceholder}
						options={destinationOptions || []}
					/>,
					document.body
				)}
			{dateShow &&
				createPortal(
					<ModalDate
						isOpen={dateShow}
						onNext={(val: string) => {
							setDateValue(val)
							setDateShow(false)
							searchParams.set(
								EnumSearchUrl.TownFrom,
								departureValue?.slug || ''
							)
							searchParams.set(
								EnumSearchUrl.TownTo,
								destinationValue?.slug || ''
							)
							searchParams.set(
								EnumSearchUrl.DateFrom,
								val
							)

							navigate('/tours/select?' + searchParams.toString())

						}}
						onBack={() => {
							setDestinationShow(true)
							setDateShow(false)
						}}
						value={dateValue || ''}
					/>,
					document.body
				)}
		</div>
	)
}

export default Main
