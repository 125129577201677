import { FC, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './About.scss'
import bannerImg from './assets/banner-about.jpg'
import chooseImg from './assets/elephant_sanctuary.jpg'
import charitiesImg from './assets/сharities.jpg'
import expectsImg from './assets/expects.jpg'
import Banner from './components/banner/Banner'
import { useQueryAbout } from './hooks/useQueryAbout'
import ButtonLink2 from './components/ui/button-link2/ButtonLink2'

const CardAbout: FC<{
	title: string
	text: string
	image: string
	url: string
}> = ({ image, title, text, url }) => {
	return (
		<div className='card-about   w-100 xl:w-1/4 md:w-1/2'>
			<Link to={url} className='card-about__img'>
				<img src={`/images/about/${image}`} alt='' />
			</Link>
			<h2 className='card-about__title subtitle center blue'>
				<Link to={url}>{title}</Link>
			</h2>
			<p className='card-about__text text center'>{text}</p>
			<div className='card-about__link text'>
				<Link to={url}>
					{title}
					<span className='fi-rs-arrow-right'></span>
				</Link>
			</div>
		</div>
	)
}

function About() {
	const { data, isPending, error } = useQueryAbout()

	const location = useLocation()

	if (isPending) return <div>'Loading...'</div>

	return (
		<>
			<Banner image={bannerImg} />
			<section className='hero-about'>
				<div className='hero-about__container container'>
					<h1 className='hero-about__title title center blue'>О нас</h1>
					<h2 className='hero-about__subtitle subtitle left blue'>
						Кто мы и с чего мы начинали
					</h2>
					<p className='text'>
						ElephantTour, является ведущим поставщиком групповых туров с гидом.
						После исследования и приключений в сотнях направлений с 1996 года мы
						увидели большинство прекрасных мест, которые может предложить мир, и
						наша миссия всегда состоит в том, чтобы делиться с вами самым
						лучшим. Поэтому мы предоставляем профессионально спланированные
						маршруты по самым невероятным направлениям мира и создаем для вас
						прекрасные воспоминания, которые останутся с вами на всю жизнь. Наша
						команда экспертов создает туры с нуля и включает экскурсии по самым
						лучшим достопримечательностям в каждом месте в сопровождении
						местного эксперта-гида. Наши туры обычно включают перелеты,
						трансферы, размещение, экскурсии и местного эксперта-гида на
						протяжении каждого тура. Кроме того, как поставщик групповых туров,
						вы будете отдыхать с единомышленниками и делиться опытом.
					</p>
				</div>
				<div className='border'></div>
			</section>
			<section className='choose-about section'>
				<div className='container'>
					<h2 className='choose-about__title subtitle left blue'>
						Почему стоит выбрать ElephantTour?
					</h2>
					<p className='text'>
						Наш возраст и опыт. Мы можем предложить вам отдых, который подойдет
						вам, вашим датам, вашим направлениям и любому виду впечатлений,
						которые вы ищете!
					</p>
				</div>
				<div className='choose-about__inner choose-block'>
					<div className='choose-block__image'>
						<img className='choose-block__img' src={chooseImg} alt='' />
					</div>
					<div className='choose-block__block'>
						<p className='text'>
							<strong>Это просто:</strong>
							мы все планируем для вас и делаем так, чтобы вам было легко
							наслаждаться отпуском – полностью. Мы будем думать, планировать,
							проверять, но мы оставим упаковку вещей вам.
						</p>
						<p className='text'>
							<strong>Мы эксперты:</strong>
							вы откроете для себя гораздо больше, воспользовавшись знаниями,
							идеями и скрытыми сокровищами от наших местных опытных гидов.
						</p>
						<p className='text'>
							<strong>Мы друзья: </strong>
							вы будете путешествовать с группой единомышленников, разделяя
							вместе прекрасные впечатления, но вы также сможете насладиться
							свободным временем для себя и исследовать больше в свое
							удовольствие.
						</p>
						<p className='text'>
							<strong>Больше выбора: </strong>
							более 140 направлений для исследования по всему миру.
						</p>
						<p className='text'>
							<strong>Мы знаем толк в путешествиях:</strong>
							более 28 лет опыта в организации отдыха на любой вкус…
						</p>
					</div>
				</div>
			</section>
			<section className='expects section'>
				<div className='expects__container container'>
					<div className='expects__left'>
						<img src={expectsImg} alt='' />
					</div>
					<div className='expects__right'>
						<h2 className='expects__subtitle subtitle left blue'>Чего ожидать?</h2>
						<p className='text'>
							В ElephantTour мы специалисты по отдыху, у нас большой опыт, мы
							чрезвычайно увлечены тем, что мы делаем. У нас непревзойденная
							репутация отличного обслуживания клиентов, качественного отдыха и
							ценности. Наша команда из множества разных стран и говорит на
							многих языках, создавая многонациональную, многокультурную и
							веселую рабочую среду. Когда мы не создаем фантастические отпуска
							для других людей, мы находимся в самолете, поезде, на лодке или
							пешком, исследуя мир. Вместе с командой мы побывали почти во всех
							уголках земного шара, и мы используем наши знания и опыт, чтобы
							создавать фантастические впечатления для наших клиентов.
						</p>
					</div>
				</div>
				<div className='border'></div>
			</section>
			<section className='team'>
				<div className='team__container container'>
					<div className='team__cards'>
						{data?.map(d => (
							<CardAbout
								key={d.id}
								image={d.image}
								text={d.text}
								title={d.title}
								url={location.pathname}
							/>
						))}
					</div>
				</div>
				<div className='border'></div>
			</section>
			<section className='charities'>
				<div className='charities__container container'>
					<div className='charities__left'>
						<img src={charitiesImg} alt='' />
					</div>
					<div className='charities__right'>
						<div>
							<h2 className='charities__subtitle subtitle left blue'>
								Поддержка благотворительности
							</h2>
							<p className='text'>
								Наше благотворительное партнерство дает нашим сотрудникам и
								клиентам возможность оказывать поддержку и собирать средства на
								достойные цели.
							</p>
							<div className='charities__btn left'>
								<ButtonLink2
									link='https://www.charitywatch.org/top-rated-charities/human-rights'
									title='Узнать больше'
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default About
