import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { BlogItem } from '../types/types'
import { getBlogs } from '../api/getBlogs'


export const useQueryBlogPagination = (page: number, perPage: number) => {
	const [list, setList] = useState<BlogItem[]>([])
	const [loadMoreShow, setLoadMoreShow] = useState<boolean>(false)

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['blogPagination'],
		queryFn: getBlogs,
		select: data =>
			data.filter(d => d.id > (page - 1) * perPage && d.id <= page * perPage)
	})

	useEffect(() => {
		if (data) {
			const len = data[0].total
			setLoadMoreShow(page * perPage < len)
			setList([...list, ...data])
		}
	}, [data])

	return { isPending, error, isFetching, list, loadMoreShow }
}
