import { useQuery } from '@tanstack/react-query'
import { getTours } from '../api/getTours'
import { EnumTourType } from '../types/types'


export const useQueryTours = (type:EnumTourType) => {

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['tours',type],
		queryFn: getTours,
		select:data=>data.filter(d=>d.type===type)
	})

	return { isPending, error, isFetching, data}
}
