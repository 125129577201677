import { useQuery } from '@tanstack/react-query'
import { getTours } from '../api/getTours'
import { sleep } from '../utils'



export const useQueryTourBySlug =  (slug:string) => {

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['tours',slug],
		queryFn: getTours,
		select:data=>data.filter(d=>d.slug===slug)[0]
	})



	return { isPending, error, isFetching, data}
}
