import { FC, useState } from 'react'
import { useBodyScrollLock } from '../../hooks/useBodyScrollLock '
import { TownsFromItem } from '../../types/types'
import './MobileDeparture.scss'
import { depPlaceholder } from '../../constants'

const MobileDeparture: FC<{
	show: boolean
	setShow: (val: boolean) => void
	options: TownsFromItem[] | []
	onApply: (val: TownsFromItem) => void
}> = ({ onApply, options, show, setShow }) => {
	useBodyScrollLock()

	const [dep, setDep] = useState<TownsFromItem>(depPlaceholder)

	return (
		<div className={show ? 'mobile-departure open' : 'mobile-departure '}>
			<div className='mobile-departure__content'>
				<div className='mobile-departure__close' onClick={() => setShow(false)}>
					<span className='fi-rs-cross-small'></span>
				</div>
				<div className='mobile-departure__border'></div>
				<div className='mobile-departure__list'>
					<h3 className='mobile-departure__title title left blue'>
						Выберите город отправления
					</h3>
					{options.map(o => (
						<div
							key={o.id}
							className={
								dep.id === o.id
									? 'mobile-departure__item checked'
									: 'mobile-departure__item'
							}
							onClick={() => setDep(o)}
						>
							<span className='fi-rs-check'></span>
							<div className='text'>{o.name}</div>
						</div>
					))}
				</div>
				<div className='mobile-departure__bottom'>
					<button
						className='mobile-departure__btn text-sm'
						onClick={() => onApply(dep)}
					>
						Применить
					</button>
				</div>
			</div>
		</div>
	)
}

export default MobileDeparture
