import { FC } from 'react'
import { ReviewItem } from '../../types/types'
import AvatarReview from '../avatar-review/AvatarReview'
import RatingStars from '../ui/rating-stars/RatingStars'
import './ReviewSmall.scss'

const ReviewSmall: FC<{ value: ReviewItem }> = ({ value }) => {
	return (
		<div className='review-small'>
			<div className='review-small__left'>
				<div className='review-small__id text'>{value.id}</div>
				<RatingStars value={value.rating} />
				<p className='review-small__date text'>{value.date}</p>
			</div>
			<div className='review-small__right'>
				<div className='review-small__right-top'>
					<div className='review-small__avatar'>
						<AvatarReview location={value.location} name={value.name} />
					</div>
					<div className='review-small__travel-type text'>
						Я путешествовал с семьей
						<span className='review-small__icon fi-rs-family'></span>
					</div>
				</div>
				<p className='review-small__text text'>
					Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus
					et unde hic aliquid, tenetur maiores ipsam incidunt repellendus nulla
					est dolor animi aperiam reprehenderit fugiat libero explicabo tempore
					rerum beatae veritatis aut in consequuntur error esse commodi? Sunt
					voluptas, aliquid mollitia quia sed enim culpa, fugiat ullam,
					voluptate itaque repellat.
				</p>
			</div>
		</div>
	)
}

export default ReviewSmall
