import { TownsFromItem, PlaceItem } from "./types/types"

export const perPage = 20
export const pageToShowFirstLast = 3
export const pageToShowMiddle = 3

export interface BookWidgetOption {
	readonly value: string
	readonly label: string
}

export interface BookOption {
	value: string
	label: string
	price: number
}

export interface PersonOption {
	value: string
	label: string
}


export interface storeBooking {
	backUrl?: string
	bookPerson?: PersonOption
	bookInfo?: {
		dateFrom: string
		townFrom: string
		townTo: string
		tourName: string
		price: number
	}
	bookBaggage?: BookOption[]
	bookInsurance?: BookOption[]
	bookRoom?: BookOption
	bookPersons?: {
		title?: PersonOption
		firstName: string
		middleName: string
		lastName: string
		phone: string
		email: string
	}[]
}

export const personOptions:  PersonOption[] = [
	{ value: '1', label: '1' },
	{ value: '2', label: '2' },
	{ value: '3', label: '3' },
	{ value: '4', label: '4' },
	{ value: '5', label: '5' },
	{ value: '6', label: '6' },
	{ value: '7', label: '7' },
	{ value: '8', label: '8' }
]

export const roomOptions:  BookOption[] = [
	{ value: '1', label: 'Одноместный номер', price: 1500 },
	{ value: '2', label: 'Двухместный номер(+1500)', price: 3000 },
	{ value: '3', label: 'Двухместный номер с видом на море(+2500р)', price: 4000 }
]

export const baggageOptions: readonly BookOption[] = [
	{
		value: '1',
		label: 'Мне не требуется дополнительный регистрируемый багаж',
		price: 0
	},
	{ value: '2', label: '1 х Сумка (макс. вес 20 кг): 800 рублей', price: 800 },
	{ value: '3', label: '1 х Сумка (макс. вес 25 кг): 1000 рублей', price: 1000 }
]

export const insuranceOptions: readonly BookOption[] = [
	{ value: '1', label: 'У меня есть собственная страховка.', price: 0 },
	{
		value: '2',
		label:
			'Страхование взрослых Таиланд 10 дней 19 - 69 Premium Plus (3800 рублей)',
		price: 3800
	},
	{
		value: '3',
		label:
			'Страхование взрослых по Таиланду на 10 дней с 19 до 69 лет Premium Plus (3400 рублей)',
		price: 3400
	},
	{
		value: '4',
		label:
			'Страхование взрослых по Таиланду на 10 дней 70–75 с VHI  (5800 рублей)',
		price: 5800
	},
	{
		value: '5',
		label:
			'Страхование взрослых по Таиланду на 10 дней с 19 до 69 лет(4500 рублей)',
		price: 4500
	}
]

export const personTitleOptions:  PersonOption[] = [
	{ label: 'М',value:'М' },
	{ label: 'Ж',value:'Ж' }
]

export const depPlaceholder: TownsFromItem = {
	flag: '',
	id: 0,
	label: '',
	location: '',
	name: '',
	slug: '',
	value: 0
}

export const destPlaceholder: PlaceItem = {
	id: 0,
	location: '',
	name: '',
	slug: '',
	image: ''
}