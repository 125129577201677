import { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import './ContactW.scss'
import useResize from '../../hooks/useRisize'

export enum ButtonSize {
	Full = 'full',
	Small = 'small'
}

function ContactW() {
	const [buttonSize, setButtonSize] = useState<ButtonSize>(ButtonSize.Full)
	const [popupOpen, setPopupOpen] = useState<boolean>(false)
	const [over, setOver] = useState<boolean>(false)

	const { pathname } = useLocation()

	const size = useResize()

	useEffect(() => {
		setTimeout(() => {
			const el: HTMLDivElement | null = document.querySelector(
				'.book-widget-mobile'
			)
			if (el) {
				const compStyles = window.getComputedStyle(el)
				const displ = compStyles.getPropertyValue('display')
			
				if (displ === 'block') {
					setOver(true)
				} else {
					setOver(false)
				}
			} else {
				setOver(false)
			}
		}, 1000)
	}, [pathname, size[0]])

	return (
		<div className={over ? 'contact-w over' : 'contact-w '}>
			<button
				className={
					buttonSize === ButtonSize.Small ? 'contact-btn small' : 'contact-btn'
				}
				onClick={() => {
					setButtonSize(
						buttonSize === ButtonSize.Small ? ButtonSize.Full : ButtonSize.Small
					)
					setPopupOpen(!popupOpen)
				}}
			>
				<span className='contact-btn__icon fi-rs-envelope'></span>
				<span className='contact-btn__title hidden sm:block'>Contact us</span>
				<span className='contact-btn__close fi-rs-cross'></span>
			</button>
			<div className={popupOpen ? 'contact-popup open' : 'contact-popup'}>
				<p className='contact-popup__title  center'>Напишите нам</p>
				<ContactForm />
			</div>
		</div>
	)
}

interface IForm {
	email: string
	name: string
	phone: string
}

const validEmail =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ContactForm: FC<{}> = () => {
	const {
		register,
		handleSubmit,
		reset,

		formState: { errors }
	} = useForm<IForm>()

	const onSubmit: SubmitHandler<IForm> = () => {
		reset()
	}

	return (
		<div className='contact-w__form'>
			<form className='form-w' onSubmit={handleSubmit(onSubmit)}>
				<label className='form-w__field'>
					<span className='form-w__label'>Имя*</span>
					<input
						{...register('name', {
							required: 'Имя обзательно'
						})}
						className='form-w__input form-control'
						autoComplete='on'
						placeholder='Введите ваше имя...'
					/>
					{errors.name && (
						<p className='form-w__error'>{errors.name.message}</p>
					)}
				</label>
				<label className='form-w__field'>
					<span className='form-w__label'>Почта*</span>
					<input
						{...register('email', {
							required: 'Email обязателен',
							pattern: {
								value: validEmail,
								message: 'Не верный email'
							}
						})}
						className='form-w__input form-control'
						autoComplete='on'
						placeholder='Введите ваш email...'
					/>
					{errors.email && (
						<p className='form-w__error'>{errors.email.message}</p>
					)}
				</label>

				<label className='form-w__field'>
					<span className='form-w__label'>Телефон</span>
					<input
						{...register('phone', {})}
						className='form-w__input form-control'
						autoComplete='on'
						placeholder='Введите ваш телефон...'
					/>
				</label>

				<div className='form-w__wrapper'>
					<button className='form-w__btn btn text-big' type='submit'>
						Отправить
					</button>
				</div>
			</form>
		</div>
	)
}

export default ContactW
