import { FC } from 'react'
import './Slogan.scss'

const Slogan: FC<{}> = () => {
	return (
		<div className='slogan'>
			<div className='slogan__container container'>
				<h2 className='slogan__subtitle title'>
					Большим слонам-большие скидки
				</h2>
				<h1 className='slogan__title  title-big'>Получи выгоду в 500%</h1>
			</div>
		</div>
	)
}

export default Slogan
