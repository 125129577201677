import 'photoswipe/style.css'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Galery from './components/ui/galery/Galery'
import useScroll from './hooks/useScroll'
import TematicHeader from './pages/tematic/TematicHeader'
import TematicHero from './pages/tematic/TematicHero'
import TematicReviews from './pages/tematic/TematicReviews'
import './Tematic.scss'
import BookWidgetMobile from './components/book-widget/BookWidgetMobile'
import { EnumTourType } from './types/types'
import { useQueryTourBySlug } from './hooks/useQueryTourBySlug'
import GalerySlick from './components/ui/galery/GalerySlick'

function Tematic() {
	const images = [
		{ id: 0, src: '/images/tematics/card1.jpg', width: 700, height: 500 },
		{ id: 1, src: '/images/tematics/card2.jpg', width: 700, height: 500 },
		{ id: 2, src: '/images/tematics/card3.jpg', width: 700, height: 500 },
		{ id: 3, src: '/images/tematics/card4.jpg', width: 700, height: 500 }
	]

	const [headerShow, setHeaderShow] = useState<boolean>(false)

	let { slug } = useParams<'slug'>()

	const {data:tematic,isPending,error}  = useQueryTourBySlug(slug||'')

	
	const scrollPosition = useScroll()

	if (scrollPosition > 100 && !headerShow) {
		setHeaderShow(true)
	}

	if (scrollPosition < 100 && headerShow) {
		setHeaderShow(false)
	}

	
	if (isPending) return(
		<div>'Loading...'</div>
	) 

	return (
		<div className='tematic'>
			<div className='tematic__galery'>
				<Galery images={images}/>
				
			</div>
			<div className='tematic__galery-slick'>
				<GalerySlick images={images}/>
				
			</div>
			<TematicHeader isShow={headerShow} />
			<TematicHero name={tematic?.name} location={tematic?.location}/>
			<TematicReviews name={tematic?.name||''} slug={tematic?.slug||''} />
			<BookWidgetMobile type={EnumTourType.Tematics} />
			
		</div>
	)
}

export default Tematic