import { useState } from 'react'
import './PlacesHero.scss'

function PlacesHero() {
	const [viewFull, setViewFull] = useState(true)
	return (
		<div className='places-hero section'>
			<div className='places-hero__container container'>
				<h1 className='places-hero__title title center'>
					Отвезем вас к местам отдыха вашей мечты
				</h1>
				<p>
					Откройте для себя мир с одним из наших туристических отпусков. С нашим
					широким выбором направлений для отдыха весь мир у ваших ног. Выберите
					регион, который вы хотели бы исследовать, и присоединяйтесь к нам в
					новом приключении. От Европы до Америки, от Ближнего Востока до Азии
					вы можете путешествовать по миру и исследовать страны, которые вы
					никогда не видели раньше.
				</p>
				<div
					className={
						viewFull ? 'places-hero__collapsed' : 'places-hero__collapsed open'
					}
				>
					<p>
						Ищете ли вы короткую поездку в солнечную Испанию или уникальное
						приключение в Индии, у нас есть множество направлений для
						путешествий, которые удовлетворят все ваши потребности.
					</p>
					<p>
						Получите инсайдерскую информацию от наших групповых туров с местными
						гидами и получите максимум от своего отпуска Большинство наших туров
						включают перелеты, размещение, трансферы, экскурсии и отмеченных
						наградами англоговорящих гидов. Забронируйте место для отпуска своей
						мечты и путешествие с нами уже сегодня.
					</p>
				</div>
				<button
					className='places-hero__btn-collapsed'
					onClick={() => setViewFull(!viewFull)}
				>
					{viewFull ? 'Читать больше' : 'Свернуть'}
				</button>
				<div className='border'></div>

					<h2 className='places-hero__subtitle title center'>
						Большим слонам-Большие скидки
					</h2>
					<ul className='places-hero__list'>
						<li className='places-hero__item'>
							<span className='fi-rs-check-circle'></span>

							<p>
								<strong>Сохрани €75 </strong>
								при бронировании 7 ночей в 2025. Используй промокод: ELEPHANT25
							</p>
						</li>
						<li className='places-hero__item'>
							<span className='fi-rs-check-circle'></span>

							<p>
								<strong>Сохрани €275 </strong>
								при бронировании 8 ночей в 2025. Используй промокод: ELEPHANT258
							</p>
						</li>
					</ul>
					<h4>Предложение ограничено. Не откладывай!</h4>
			</div>
		</div>
	)
}

export default PlacesHero
