import { useEffect, useRef, useState } from 'react'

export const useDelay = (key: any,timeDelay:number) => {
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		// declare the data fetching function
		const delay = async () => {
			setIsLoading(true)
			await new Promise(resolve => {
				setTimeout(resolve, timeDelay)
			})
			setIsLoading(false)

		}
		delay()
	}, [key])

	return {isLoading}
}
