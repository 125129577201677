import { FC, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import docImg from '../../assets/docs.svg'
import { insuranceOptions } from '../../constants'
import './BookInsurance.scss'
import { ErrorMessage } from '@hookform/error-message'

const BookInsurance: FC<{}> = () => {
	const { fields, append, prepend, remove, swap, move, insert, replace } =
		useFieldArray({
			name: 'bookInsurance'
		})
	const {
		formState: { errors }
	} = useFormContext()

	return (
		<div className='book-insurance section'>
			<div className='book-insurance__container'>
				<h2 className='book-insurance__title subtitle center blue'>
					Страхование путешествий
				</h2>

				<div className='book-insurance__item'>
					<p className='text'>
						Все путешествующие с нами клиенты должны иметь соответствующую
						туристическую страховку.
					</p>
				</div>
				<div className='book-insurance__item'>
					<p className='text'>
						Мы продаем комплексную политику, включая возмещение невозвратных
						расходов на проезд и проживание в связи с отменой или сокращением.
						Мы предлагаем вам воспользоваться этим.
					</p>
				</div>

				<div className='book-insurance__docs'>
					<div className='book-insurance__docs-item'>
						<div className='book-insurance__row'>
							<img src={docImg} width={40} height={40} alt='' />
							<div className='book-insurance__column'>
								<span className='text'>Полные положения и условия</span>
								<Link className='book-insurance__docs-link text' to='/'>
									Буклет о страховании путешествий
								</Link>
							</div>
						</div>
					</div>
					<div className='book-insurance__docs-item'>
						<div className='book-insurance__row'>
							<img src={docImg} width={40} height={40} alt='' />
							<div className='book-insurance__column'>
								<span className='text'>Краткое содержание</span>
								<Link className='book-insurance__docs-link text' to='/'>
									Информация о страховом продукте
								</Link>
							</div>
						</div>
					</div>
					<div className='book-insurance__docs-item'>
						<div className='book-insurance__row'>
							<img src={docImg} width={40} height={40} alt='' />
							<div className='book-insurance__column'>
								<span className='text'>Соблюдение страховых норм</span>
								<Link className='book-insurance__docs-link text' to='/'>
									Сертификат соответствия
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className='book-insurance__inner'>
					{fields.map((p, index1) => (
						<div key={p.id} className='book-insurance__field form-field'>
							<label className='form-field__label text'>
								Пассажир {index1 + 1}
							</label>
							<ErrorMessage
								errors={errors}
								name={`bookInsurance.${index1}`}
								render={({ message }) => (
									<span className='form-field__error'>{message}</span>
								)}
							/>
							<Controller
								name={`bookInsurance.${index1}`}
								rules={{
									validate: value => value.value !== '' || 'выберите страховку'
								}}
								render={({ field: { onChange, value, ref } }) => (
									<Select
										ref={elem => {
											ref({
												...elem,
												focus: () => {
													elem?.inputRef?.scrollIntoView({
														behavior: 'smooth',
														block: 'center',
														inline: 'nearest'
													})
													elem?.inputRef?.focus({ preventScroll: true })
												}
											})
										}}
										onChange={onChange}
										value={value}
										classNamePrefix='select'
										isClearable={false}
										components={{
											IndicatorSeparator: () => null
										}}
										isSearchable={true}
										options={insuranceOptions}
										styles={{
											singleValue: base => ({
												...base,
												padding: 5,
												color: '#002d72',
												fontWeight: 600,
												display: 'flex'
											}),

											control: base => ({
												...base,
												height: '68px',

												border: '1px solid rgb(0  0 0/ 22%)',
												boxShadow: 'none',
												'&:hover': {
													border: '1px solid rgb(0  0 0/ 22%)'
												}
											}),

											menu: base => ({
												...base,
												zIndex: 2,
												width: '88%',
												left: '50px'
											})
										}}
									/>
								)}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default BookInsurance
