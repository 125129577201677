import { useEffect } from 'react'
import './TematicsHero.scss'

function TematicsHero() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, [])

	return (
		<div className='tematics-hero'>
			<div className='tematics-hero__container container'>
				<div className='section'>
					<h1 className='tematics-hero__title title center'>
						Лучшие тематические туры
					</h1>
					<p>
						Узнайте больше о том, что вас интересует, в одном из наших
						захватывающих тематических туров. Независимо от того, какой тип вы
						выберете, подавляющее большинство наших туров включают перелеты,
						размещение, трансферы, туры и не отмеченных интелектом гидов.
						Независимо от вашего выбора, ваш ментальный багаж пополнится
						знаниями о огромных карасях Волги и посевных массивах волгоградщины
					</p>
				</div>

				<div className='section'>
					<h2 className='tematics-hero__subtitle title center'>
						Большим слонам-Большие скидки
					</h2>
					<ul className='tematics-hero__list'>
						<li className='tematics-hero__item'>
							<span className='fi-rs-check-circle'></span>

							<p>
								<strong>Сохрани €75 </strong>
								при бронировании 7 ночей в 2025. Используй промокод: ELEPHANT25
							</p>
						</li>
						<li className='tematics-hero__item'>
							<span className='fi-rs-check-circle'></span>

							<p>
								<strong>Сохрани €275 </strong>
								при бронировании 8 ночей в 2025. Используй промокод: ELEPHANT258
							</p>
						</li>
					</ul>
				</div>
				<h4>Предложение ограничено. Не откладывай!</h4>
			</div>
		</div>
	)
}
export default TematicsHero
