import { Link } from 'react-router-dom'
import Select, { components } from 'react-select'
import bookingImg from '../../assets/booking.svg'
import { useQueryTownsFrom } from '../../hooks/useQueryTownsFrom'
import emailImg from './email.png'
import './Header.scss'
import logoImg from './logo.png'
import phoneImg from './phone.png'
import Hamburger from 'hamburger-react'
import { useEffect, useState } from 'react'
import { TownsFromItem } from '../../types/types'
// @ts-ignore
const MyOption = props => {
	const { innerProps, innerRef } = props
	return (
		<div ref={innerRef} {...innerProps} className='header-select__option'>
			<img
				width={20}
				height={20}
				src={`/images/flags/${props.data.flag}`}
				className='header-select__option-icon '
			></img>
			{props.data.label}
		</div>
	)
}

// @ts-ignore
const SingleValueDeparture = ({ children, ...props }) => (
	// @ts-ignore
	<components.SingleValue {...props}>
		<img
			width={20}
			height={20}
			src={`/images/flags/${props.data.flag}`}
			className='header-select__option-icon '
		></img>
		{children}
	</components.SingleValue>
)

function Header() {
	const [departure, setDeparture] = useState<TownsFromItem | null>()
	const { data: townsFrom, isSuccess } = useQueryTownsFrom()

	const [isOpen, setOpen] = useState(false)

	const handleChange = (val: TownsFromItem) => {
		localStorage.setItem('departure', JSON.stringify(val))
		setDeparture(val)
	}

	useEffect(() => {
		if (isSuccess) {
			const depart = localStorage.getItem('departure')
			if (depart) {
				setDeparture(JSON.parse(depart))
				return
			}
			// @ts-ignore
			localStorage.setItem('departure', JSON.stringify(townsFrom[0]))
			// @ts-ignore
			setDeparture(townsFrom[0])
		}
	}, [isSuccess])

	return (
		<header className='header'>
			<div className='header__top'>
				<Link to='/offers' className='header__top-link text2'>
					Скидки — последний шанс!
					<span className='fi-rs-angle-small-right'></span>
				</Link>
			</div>
			<div className='header__navbar navbar lg:max-w-[1740px]'>
				<div className='navbar__left'>
					<button className='navbar__burger lg:hidden'>
						<Hamburger toggled={isOpen} toggle={setOpen}
						color='#002d72'
						size={20}
						/>
					</button>
					<div className='navbar__logo '>
						<Link className='' to='/'>
							<img className='navbar__logo-icon' src={logoImg} alt='' />
						</Link>
					</div>
					<div className='navbar__menu'>
						<div className={isOpen?'menu open':'menu'}>
							<ul className='menu__list'>
								<li className='menu__item'>
									<Link
										className='menu__link menu__link--left text blue'
										to='/places'
										onClick={()=>setOpen(false)}
									>
										Направления
									</Link>
								</li>
								<li className='menu__item'>
									<Link
										className='menu__link menu__link--left  text blue'
										to='/tematics'
										onClick={()=>setOpen(false)}
									>
										Тематические туры
									</Link>
								</li>
								<li className='menu__item'>
									<Link
										className='menu__link menu__link--about text blue'
										to='/about'
										onClick={()=>setOpen(false)}
									>
										О нас
									</Link>
								</li>
								<li className='menu__item'>
									<Link
										className='menu__link menu__link--inspiration text blue'
										to='/blog'
										onClick={()=>setOpen(false)}
									>
										Наша мотивация
									</Link>
								</li>
								<li className='menu__item'>
									<Link
										className='menu__link menu__link--help text blue'
										to='/help'
										onClick={()=>setOpen(false)}
									>
										Помощь
									</Link>
								</li>
						
							</ul>
						</div>
					</div>
				</div>
				<div className='navbar__right'>
					<div className='navbar__top'>
						<div className='navbar__departure'>
							<span className='text-sm'>Отправление:</span>
							<Select
								isClearable={false}
								components={{
									SingleValue: SingleValueDeparture,
									Option: MyOption,
									IndicatorSeparator: () => null
								}}
								isSearchable={false}
								value={departure}
								options={townsFrom}
								// @ts-ignore
								onChange={e => handleChange(e)}
								styles={{
									singleValue: base => ({
										...base,
										color: '#002d72',
										fontWeight: 600,
										fontSize: '14px',
										display: 'flex'
									}),

									control: base => ({
										...base,
										width: '150px',
										boxShadow: 'none',
										border: 'none',
										background: 'transparent'
									}),

									menu: base => ({
										...base,
										width: '100%',
										paddingLeft: '5px',
										background: '#f3f3f3'
									})
								}}
							/>
						</div>
						<Link className='navbar__link navbar__link--about text' to='/about'>
							О нас
						</Link>
						<Link
							className='navbar__link navbar__link--book text'
							to='/help'
						>
							<img width={15} height={15} src={bookingImg} alt='' />
							Помощь
						</Link>
					</div>
					<div className='navbar__bottom'>
						<Link
							className='navbar__link hidden lg:inline-block text-big'
							to='/blog'
						>
						    Наша мотивация
						</Link>
				
						<Link
							className='navbar__link navbar__link--phone text-big'
							to='tel:66666666'
						>
							<img width={22} height={22} src={phoneImg} alt='' />
							<span className='hidden xl-block'>66666666</span>
						</Link>
						<Link
							className='navbar__link navbar__link--email text-big'
							to='mailto:info@elephant.com'
						>
							<img width={22} height={22} src={emailImg} alt='' />
							<span className='hidden xl-block'>info@elephant.com</span>
						</Link>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
