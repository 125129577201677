import { FC } from 'react'
import { Link } from 'react-router-dom'
import './CardBlog.scss'

export const CardBlog: FC<{
	name: string
	slug: string
	image: string
}> = ({ image, name, slug }) => {
	return (
		<div className='card-blog  w-100 xl:w-1/3 md:w-1/2'>
			<Link to={`/blog/${slug}`} className='card-blog__image'>
				<img src={`/images/places/${image}`} alt='' />
			</Link>
		
			<h3 className='subtitle left blue'>
				<Link to={`/blog/${slug}`} className='card-blog__name'>
					{name}
				</Link>
			</h3>
			
		</div>
	)
}
