import { forwardRef } from 'react'
import './Checkbox.scss'

interface IInputProps extends React.HTMLProps<HTMLInputElement> {
    onChange:(val:any)=>void
}

const Checkbox = forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
	const { onChange, value } = props

	return (
		<label className='checkbox'>
			<input
				value={value}
				ref={ref}
				className='checkbox__input'
				type='checkbox'
				onChange={e => {
                    if(onChange){
                        e.target.checked ? onChange('1') : onChange('0')
                    }
				}}
			/>
			<div className='checkbox__decor'>
				<span className='fi-rs-check'></span>
			</div>
		</label>
	)
})

export default Checkbox
