import { useQuery } from '@tanstack/react-query'
import { getTownsFrom } from '../api/getTownsFrom'


export const useQueryTownsFrom = () => {

	const { isPending, error, data, isFetching ,isSuccess} = useQuery({
		queryKey: ['towns-from'],
		queryFn: getTownsFrom
	})

	return { isPending, error, isFetching, data,isSuccess}
}
