export interface PlaceItem {
	id: number
	image: string
	name: string
	location: string
	slug: string
}

export interface TownsFromItem {
	id: number
	value: number
	name: string
	location: string
	label: string
	flag: string
	slug: string
}

export interface ParkItem {
	id: number
	image: string
	name: string
	slug: string
	location: string
}

export interface BlogItem {
	id: number
	total: number
	location: string
	location_slug: string
	slug: string
	image: string
	title: string
	type: string
}

export interface AboutItem {
	id: number
	image: string
	title: string
	text: string
}

export interface CategoryItem {
	id: number
	image: string
	name: string
}

export interface HelpItem {
	id: number
	image: string
	name: string
}

export interface TematicRating {
	id: number
	name: string
	slug: string
	rating: number
	total: number
	rating_5: number
	rating_4: number
	rating_3: number
	rating_2: number
	rating_1: number
	image: string
}

export enum EnumPeople {
	Adults = 'Adults',
	Youths = 'Youths',
	Children = 'Children',
	Infants = 'Infants'
}

export enum EnumRating {
	Five = '5',
	Four = '4',
	Three = '3',
	Two = '2',
	One = '1'
}

export enum EnumSearchUrl {
	Adults = 'adt',
	Youths = 'yth',
	Children = 'chd',
	Infants = 'inf',
	TownFrom = 'from',
	TownTo = 'to',
	DateTo = 'dateto',
	DateFrom = 'datefrom'
}

export enum EnumTourType {
	Tematics = 'tematics',
	Tours = 'tours'
}

export interface TourItem {
	id: number
	type: EnumTourType
	location: string
	location_slug: string
	slug: string
	image: string
	name: string
	pricePrev: number
	price: number
	dates: string[]
}

export interface CardExpertsItem {
	id: number
	image: string
	classPrefix: string
	title: string
	text: string
	url: string
}

export interface ImageItem {
	id: number
	image: string
	src: string
	width: number
	height: number
}

export interface GaleryImageItem {
	id: number
	src: string
	width: number
	height: number
}

export interface RatingItem {
	images: GaleryImageItem[]
	value: number
	reviewsCount: number
	link: string
}

export interface ReviewItem {
	rating: number
	id: number
	date: string
	name: string
	location: string
	text: string
}

export interface GraphItem {
	value: EnumRating
	count: number
	total: number
}

export interface BookItem {
	value: string
	label: string
}
