import { FC, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select, { components } from 'react-select'
import { personOptions } from '../../constants'
import { useQueryPlaceBySlug } from '../../hooks/useQueryPlaceBySlug'
import { useQueryTourBySlug } from '../../hooks/useQueryTourBySlug'
import { EnumSearchUrl, EnumTourType } from '../../types/types'
import './BookWidgetMobile.scss'

export const BookWidgetMobile: FC<{ type: EnumTourType }> = ({ type }) => {
	const { slug } = useParams()

	const { data: tour, isPending, error } = useQueryTourBySlug(slug || '')
	const { data: townTo } = useQueryPlaceBySlug(tour?.location_slug || '')

	return (
		<div className='book-widget-mobile lg:hidden'>
			<div className='book-widget-mobile__top left text-sm'>
				От
				<span> от &#8381;3444</span>
			</div>

			<div className='book-widget-mobile__btn'>
				<Link
					to={`/${type}/${slug}/booking-dates?${EnumSearchUrl.TownFrom}=london
				&${EnumSearchUrl.TownTo}=${townTo?.slug}`}
					className=' btn-widget-mobile text'
				>
					Даты и цены
				</Link>
			</div>
		</div>
	)
}

export default BookWidgetMobile
