import { FC } from 'react'
import { Link } from 'react-router-dom'
import './CardPark.scss'

export const CardPark: FC<{
	name: string
	location: string
	slug: string
	image: string
}> = ({ image, name, slug,location }) => {
	return (
		<div className='card-park   w-100 xl:w-1/3 md:w-1/2'>
			<Link to={`/parks/${slug}`} className='card-park__image'>
				<img src={`/images/parks/${image}`} alt='' />
			</Link>

			<div>
				<span className='card-park__location text'>{location}</span>
			</div>
			
			<Link to={`/parks/${slug}`} className='card-park__name subtitle blue'>
				{name}
			</Link>
		
		</div>
	)
}
