import { Link } from 'react-router-dom'
import './MobileBar.scss'

function MobileBar() {
	return (
		<div className='mobile-bar  lg:hidden'>
				<ul className='mobile-bar__list'>
					<li className='mobile-bar__item center'>
						<Link className='mobile-bar__link ' to='/places'>
							Направления
						</Link>
					</li>
					<li className='mobile-bar__item center'>
						<Link className='mobile-bar__link ' to='/tematics'>
							Тематические туры
						</Link>
					</li>
					<li className='mobile-bar__item center'>
						<Link className='mobile-bar__link ' to='/blog'>
							Мотивация
						</Link>
					</li>
				</ul>
		</div>
	)
}

export default MobileBar
