
import bannerImg from './assets/falling.jpg'
import Banner from './components/banner/Banner'
import Features from './components/features/Features'
import OffersHero from './pages/offers/OffersHero'
import PlacesBlog from './pages/places/PlacesBlog'
import PlacesBook from './pages/places/PlacesBook'
import Recommend from './pages/places/Recommend'




function Offers() {
	return (
		<>
			<Banner image={bannerImg}/>
			<Features/>
			<OffersHero/>
			<div className='border'></div>
			<Recommend />
			<div className='border'></div>
			<PlacesBook />
			<div className='border'></div>
			<PlacesBlog />
		</>
	)
}

export default Offers
