import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import bannerImg from './assets/banner-place.jpg'
import Banner from './components/banner/Banner'
import Features from './components/features/Features'
import Faq from './pages/place/Faq'
import PlaceHero from './pages/place/PlaceHero'
import PlaceTop from './pages/place/PlaceTop'
import { PlaceItem } from './types/types'
import PlaceBlog from './pages/place/PlaceBlog'
import { useQueryPlaceBySlug } from './hooks/useQueryPlaceBySlug'

function Place() {
	let { slug } = useParams()

	const {data:place,isPending,error}  = useQueryPlaceBySlug(slug||'')

	if (isPending) return(
		<div>'Loading...'</div>
	) 


	return (
		<>
			<Banner image={bannerImg} />
			<Features />
			<PlaceHero name={place?.name} />
			<PlaceTop name={place?.name} />
			<Faq name={place?.name} />
			<PlaceBlog location_slug={place?.slug||''} name={place?.name||''} />

		</>
	)
}

export default Place
