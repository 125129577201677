import { FC } from 'react'
import { Link } from 'react-router-dom'
import './CardHelp.scss'

export const CardHelp: FC<{
	name: string
	image: string
}> = ({ image, name }) => {
	return (
		<div className='card-help    w-100 xl:w-1/4 lg:w-1/3 sm:w-1/2'>
			<Link to={`/help`} className='card-help__link'>
				<div className='card-help__image'>
					<img width={70} height={70} src={`/images/help/${image}`} alt='' />
				</div>

				<h3 className='card-help__name subtitle center'>{name}</h3>
				<div className='card-help__more text'>Узнать больше</div>
			</Link>
		</div>
	)
}
