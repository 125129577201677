import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlaceItem } from '../../types/types'
import './ModalDestination.scss'
import { useBodyScrollLock } from '../../hooks/useBodyScrollLock '

const ModalDestination: FC<{
	isOpen: boolean
	options: PlaceItem[]|[]
	value: PlaceItem
	onNext: (val: PlaceItem) => void
	onBack: () => void
}> = ({ onNext,onBack, value, options, isOpen }) => {

	useBodyScrollLock()

	let navigate = useNavigate()

	const [dest,setDest]=useState<PlaceItem>(value)


	return (
		<div className={isOpen ? 'modal-destination open' : 'modal-destination'}>
			<div className='modal-destination__content'>
				<div className='modal-destination__top'>
					<div className='modal-destination__list'>
						<h3 className='modal-destination__title title left blue'>
							Выберите направление
						</h3>
						{options.map(o => (
							<div key={o.id} 
							className={dest.id===o.id?'modal-destination__item checked':'modal-destination__item'}
							onClick={()=>setDest(o)}
							>
								<span className='fi-rs-check'></span>
								<div className='text'>{o.name}</div>
							</div>
						))}
					</div>
				</div>
				<div className='modal-destination__bottom'>
					<button className='modal-destination__back text-sm'
					onClick={()=>onBack()}
					>
						<span className='fi-rs-arrow-small-left'></span>
						Назад
					</button>
					<button className='modal-destination__next text-sm'
					onClick={()=>onNext(dest)}
					>
						Далее
						<span className='fi-rs-arrow-small-right'></span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default ModalDestination
