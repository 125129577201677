import { FC } from 'react'
import './Button.scss'

const Button: FC<{
	title: string,
	onClick: () => void
	className?:string,
}> = ({ title,onClick,className }) => {
	return (
		<button className={`${className} btn`} type="button" 
		onClick={(e)=>{onClick()}}
		>{title}</button>

	)
}

export default Button