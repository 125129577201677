import { FC } from 'react'
import airplaneImg from '../../assets/icons/airplane.svg'
import labelImg from '../../assets/icons/card-label.svg'
import guidImg from '../../assets/icons/guide.svg'
import moonImg from '../../assets/icons/moon.svg'
import locationImg from '../../assets/icons/placeholder.png'

import { Link, useLocation } from 'react-router-dom'
import './CardSelT.scss'
import { storeBooking } from '../../constants'

const CardSelT: FC<{
	location: string
	name: string
	townFrom: string
	image: string
	date: string
	pricePrev: number
	price: number
}> = ({ image, location, price, pricePrev, date, townFrom ,name}) => {
	
	const location1 = useLocation()
	

	const storeSave = () => {
		localStorage.removeItem('booking')
		const storeBook: storeBooking = {
			bookInfo: { dateFrom: date, townFrom: townFrom, townTo: location,tourName:name,price:price },
			bookPerson:{value:'2', label:'2'},
			bookBaggage:[{label:'',price:0,value:''},{label:'',price:0,value:''}],
			bookInsurance:[{label:'',price:0,value:''},{label:'',price:0,value:''}],
			bookPersons:[
				{
					
					firstName: '',
					middleName: '',
					lastName: '',
					phone: '',
					email: ''
				},
				{
					
					firstName: '',
					middleName: '',
					lastName: '',
					phone: '',
					email: ''
				}
			],
			backUrl:location1.pathname+location1.search
		}
		localStorage.setItem('booking', JSON.stringify(storeBook))
	}


	return (
		<div
			
			className='card-sel-t'
		>
			<div className='card-sel-t__townfrom text-big blue'>
				Отправление:&nbsp; 
				<span>
					{townFrom}| {date}
				</span>
			</div>
			<div className='card-sel-t__image'>
				<img src={`/images/places/${image}`} alt='' />
			</div>
			<div className='card-sel-t__info'>
				<div className='card-sel-t__location'>
					<img width={15} height={15} src={locationImg} alt='' />
					<span>{location}</span>
				</div>
				<h2 className="card-sel-t__name subtitle blue">{name}</h2>

				<div className='card-sel-t__options'>
					<div className='card-sel-t__option'>
						<img
							width={20}
							height={20}
							className='card-sel-t__option-img'
							src={guidImg}
						/>
						<span className='card-sel-t__option-text text-sm blue'>Fully Guided</span>
					</div>
					<div className='card-sel-t__option'>
						<img
							width={20}
							height={20}
							className='card-sel-t__option-img'
							src={moonImg}
						/>
						<span className='card-sel-t__option-text text-sm blue'>7 Nights</span>
					</div>
					<div className='card-sel-t__option'>
						<img
							width={20}
							height={20}
							className='card-sel-t__option-img'
							src={airplaneImg}
						/>
						<span className='card-sel-t__option-text text-sm blue'>Flights & Transfers</span>
					</div>
				</div>
				<div className='card-sel-t__flight'>
					<div className='card-sel-t__row'>
						<img width={30} height={30} src={airplaneImg} alt='' />
						<span>Flight</span>
					</div>
					<div className='card-sel-t__date text blue'>{date} - 06:45</div>
				</div>
				<div className='card-sel-t__bottom'>
					<div className='card-sel-t__label'>
						<img src={labelImg} alt='' />
						<span className='card-sel-t__label-text text-xs'>Save €70</span>
					</div>
					<div className='card-sel-t__price'>₽ {price}</div>
					<Link
						to='/booking-room'
						className='card-sel-t__btn text'
						onClick={() => storeSave()}
					>
						Выбрать и продолжить
					</Link>
				</div>
			</div>
		</div>
	)
}

export default CardSelT
