import { FC, useState } from 'react'
import './Faq.scss'


interface FaqItem {
	id: number
	title: string
	text: string
}

const Quest: FC<{
	quest: FaqItem
}> = ({ quest }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	return (
		<div className={isOpen ? 'faq__quest quest open' : 'faq__quest quest'}>
			<div
				className='quest__top'
				onClick={e => {
					setIsOpen(!isOpen)
				}}
			>
				<h3 className='quest__title text'>{quest.title}</h3>
				<span className='quest__icon fi-rs-plus'></span>
			</div>
			<div className='quest__text'>
				<div className='quest__wrapper'>
					<p>{quest.text}</p>
				</div>
			</div>
		</div>
	)
}

const Faq: FC<{
	name: string | undefined
}> = ({ name }) => {
	const quests = [
		{
			id: 1,
			title: 'А что делать, если возникла чрезвычайная ситуация?',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		},
		{
			id: 2,
			title: 'Нужна ли мне виза?',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		},
		{
			id: 3,
			title: 'Каков размер депозита и когда необходимо внести полную оплату?',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		},
		{
			id: 4,
			title: 'Встретят ли меня в аэропорту?',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		},
		{
			id: 5,
			title: 'Что включено в мой тур?',
			text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius,
					temporibus blanditiis. Recusandae ex numquam amet ea! Corrupti autem
					pariatur quisquam.`
		}
	]

	return (
		<div className='faq section'>
			<div className='faq__container container'>
				<h1 className='faq__title title center blue'>{name}:FAQ</h1>
				<div className='faq__quests'>
					{quests.map(q => (
						<Quest key={q.id} quest={q} />
					))}
				</div>
			</div>
			<div className="border"></div>
		</div>
	)
}

export default Faq
