import { useEffect, useRef } from 'react'

export const useKeyEscape = (callback: () => void) => {
	
	useEffect(() => {
		const handleEscape = (e: KeyboardEvent) => {
			if (e.code == 'Escape') {
				callback()
			}
		}
		document.addEventListener('keydown', handleEscape)

		return () => {
			document.removeEventListener('keydown', handleEscape)
		}
	}, [callback])

}
