import { FC, useState } from "react"
import './DateControl.scss'
import { DatePicker } from "../date-picker/DatePicker"

const DateControl: FC<{
	isShow:boolean,
	setShow:(val:boolean)=>void,
	value: string
	onChange: (val: string) => void
	labelName: string
	iconClass: string
}> = ({ labelName, iconClass, value, onChange,isShow,setShow }) => {

	const handleChangeList = (val: string) => {
		onChange(val)
	}


	return (
		<div
			className='date-control'
		
		>
			<div className='date-control__inner '
				onClick={() => {
					setShow(!isShow)
				}}
			>
				<span className={`fi ${iconClass} date-control__icon`}></span>
				<input
					className='date-control__input text-sm'
					readOnly
					type='text'
					value={value}
					onChange={e => {
						onChange(e.target.value)
					}}
				/>
				<span
					className={
						value !== '' || isShow
							? 'date-control__name date-control__name--top text'
							: 'date-control__name text'
					}
				>
					{labelName}
				</span>
			</div>
			<DatePicker
				value={value}
				onChange={handleChangeList}
				isShow={isShow}
				setShow={(val:boolean)=>setShow(val)}
			/>
		</div>
	)
}

export default DateControl
