import { FC } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

const Prev: FC<{
	page: number
	isDisabled: boolean
}> = ({ page, isDisabled }) => {

    const [paramsFirst, setParams] = useSearchParams()
    const [paramsPrev, setParamsPrev] = useSearchParams()
	const location = useLocation()
	paramsFirst.set('page', '1')
	paramsPrev.set('page', (page-1).toString())
    
	return (
		<>
			<Link
				to={location.pathname + '?' + paramsFirst.toString()}
				className={
					isDisabled
						? 'pagination__link  pagination__link--navigation disabled'
						: 'pagination__link  pagination__link--navigation'
				}
			>
				<span className='fi-rs-angle-double-small-left'></span>
			</Link>
			<Link
				to={location.pathname + '?' + paramsPrev.toString()}
				className={
					isDisabled
						? 'pagination__link  pagination__link--navigation disabled'
						: 'pagination__link  pagination__link--navigation'
				}
			>
				<span className='fi-rs-angle-small-left'></span>
			</Link>
		</>
	)
}

export default Prev
