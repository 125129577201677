import { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './BookHeader.scss'

const BookHeader: FC<{}> = () => {

	const location = useLocation()

	const [linkBack,setLinkBack] = useState<string>('/')

	useEffect(() => {
		if(localStorage.getItem('booking')){
			const lc = JSON.parse(localStorage.getItem('booking') || '')
			setLinkBack(lc.backUrl||'')

		}else{

			setLinkBack('')
		}
		
	}, [])

	




	if (location.pathname === '/booking-room') {
		return (
			<div className='book-header hidden xl:block'>
				<div className='book-header__container'>
					<nav className='book-header__menu text-sm'>
						<Link
							className='book-header__link'
								to= {linkBack}
						>
							Назад к выбору даты
							<span className='fi-rs-angle-small-right'></span>
						</Link>
						<span className='book-header__link active'>
							Выбрать номер
							<span className='fi-rs-angle-small-right'></span>
						</span>
						<span className='book-header__link'>
							Данные пассажиров
							<span className='fi-rs-angle-small-right'></span>
						</span>
						<span className='book-header__link'>
							Оплата
							<span className='fi-rs-angle-small-right'></span>
						</span>
					</nav>
				</div>
			</div>
		)
	}

	if (location.pathname === '/booking-persons') {
		return (
			<div className='book-header  hidden xl:block'>
				<div className='book-header__container'>
					<nav className='book-header__menu text-sm'>
						<Link
							className='book-header__link'
							to= {linkBack}
						>
							Назад к выбору даты
							<span className='fi-rs-angle-small-right'></span>
						</Link>
						<Link className='book-header__link' to='/booking-room'>
							Выбрать номер
							<span className='fi-rs-angle-small-right'></span>
						</Link>
						<span className='book-header__link active'>
							Данные пассажиров
							<span className='fi-rs-angle-small-right'></span>
						</span>
						<span className='book-header__link'>
							Оплата
							<span className='fi-rs-angle-small-right'></span>
						</span>
					</nav>
				</div>
			</div>
		)
	}

	if (location.pathname === '/booking-payment') {
		return (
			<div className='book-header  hidden xl:block'>
				<div className='book-header__container'>
					<nav className='book-header__menu text-sm'>
						<Link
							className='book-header__link'
							to= {linkBack}
						>
							Назад к выбору даты
							<span className='fi-rs-angle-small-right'></span>
						</Link>
						<Link className='book-header__link' to='/booking-room'>
							Выбрать номер
							<span className='fi-rs-angle-small-right'></span>
						</Link>
						<Link className='book-header__link' to='/booking-persons'>
							Данные пассажиров
							<span className='fi-rs-angle-small-right'></span>
						</Link>
						<span className='book-header__link active'>
							Оплата
							<span className='fi-rs-angle-small-right'></span>
						</span>
					</nav>
				</div>
			</div>
		)
	}


	return (
		<div className='book-header hidden xl:block'>
			<div className='book-header__container'>
				<nav className='book-header__menu text-sm'>
					<span
						className='book-header__link active'
					>
						Выбор даты
						<span className='fi-rs-angle-small-right'></span>
					</span>
						<span className='book-header__link'>
							Выбрать номер
							<span className='fi-rs-angle-small-right'></span>
						</span>

					<span className='book-header__link'>
						Данные пассажиров
						<span className='fi-rs-angle-small-right'></span>
					</span>
					<span className='book-header__link' >
						Оплата
						<span className='fi-rs-angle-small-right'></span>
					</span>
				</nav>
			</div>
		</div>
	)
}

export default BookHeader
