import { FC } from 'react'
import { Link } from 'react-router-dom'
import GaleryFromButton from '../../components/ui/galery-from-button/GaleryFromButton'
import RatingStars from '../../components/ui/rating-stars/RatingStars'
import { RatingItem } from '../../types/types'
import './Rating.scss'

const Rating: FC<{ value: RatingItem}> = ({
	value
}) => {
	return (
		<div className='rating'>
			<div className='rating__top'>
				<div className='rating__title'>{value.value} / 5</div>
				<div className='rating__stars'>
					<RatingStars value={value.value} />
				</div>
			</div>
			<div className='rating__middle'>
				<Link to={value.link}> отзывов: {value.reviewsCount} </Link>
			</div>
			<div className='rating__bottom'>
				<GaleryFromButton
					btnClass='rating__btn'
					btnTitle={`${value.images.length} фото путешественников`}
					images={value.images}
				/>
			</div>
		</div>
	)
}

export default Rating
