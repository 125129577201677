import { FC } from 'react'
import { useKeyEscape } from '../../hooks/useKeyEscape'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { EnumPeople } from '../../types/types'
import Button from '../ui/button/Button'
import './PeopleList.scss'

const PeopleItem: FC<{
	title: string
	subtitle: string
	value: number
	onChange: (val: number) => void
}> = ({ onChange, value, title, subtitle }) => {
	return (
		<li className='people-list__item pc'>
			<div className='pc__left'>
				<span className='pc__title text-sm'>{title}</span>
				<span className='pc__subtitle text-sm'>{subtitle}</span>
			</div>
			<div className='pc__actions'>
				<button
					className='pc__minus'
					disabled={value === 0}
					onClick={e => {
						onChange(value - 1)
					}}
				>
					<span className='fi-rs-minus-circle'></span>
				</button>
				<input
					className='pc__value'
					type='text'
					readOnly
					value={value}
					onChange={e => onChange(Number(e.target.value))}
				/>
				<button
					className='pc__plus'
					onClick={e => {
						onChange(value + 1)
					}}
				>
					<span className='fi-rs-add'></span>
				</button>
			</div>
		</li>
	)
}

const PeopleList: FC<{
	valueInfants: number
	valueChildren: number
	valueYouths: number
	valueAdults: number
	onChange: (type: EnumPeople, val: number) => void
	isShow: boolean
	setShow: (val: boolean) => void
}> = ({
	isShow,
	onChange,
	setShow,
	valueAdults,
	valueChildren,
	valueInfants,
	valueYouths
}) => {

	useKeyEscape(() => {
		setShow(false)
	})

	const handleChange = (type: EnumPeople, val: number) => {
		onChange(type, val)
	}

	return (
		<div className={isShow ? 'people-list open' : 'people-list '} >
			<div className='people-list__wrapper'>
				<div className='people-list__title text-big'>Кто принимает участие?</div>
				<ul className='people-list__items'>
					<PeopleItem
						onChange={val => handleChange(EnumPeople.Adults, val)}
						title='Adults'
						subtitle=' From 15 years old'
						value={valueAdults}
					/>
					<PeopleItem
						onChange={val => handleChange(EnumPeople.Youths, val)}
						title={EnumPeople.Youths}
						subtitle='12 to 14 years old'
						value={valueYouths}
					/>
					<PeopleItem
						onChange={val => handleChange(EnumPeople.Children, val)}
						title={EnumPeople.Children}
						subtitle='2 - 11 years old'
						value={valueChildren}
					/>
					<PeopleItem
						onChange={val => handleChange(EnumPeople.Infants, val)}
						title={EnumPeople.Infants}
						subtitle='Under 2 years old'
						value={valueInfants}
					/>
				</ul>
				<div className='people-list__btn text'>
					<Button
						title='Применить'
						onClick={() => {
							setShow(false)
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default PeopleList
