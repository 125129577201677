import './OffersHero.scss'

function OffersHero() {
	return (
		<div className='offers-hero section'>
			<div className='offers-hero__container container'>
				<h1 className='offers-hero__title title center'>
					Падающие листья, падающие цены…
				</h1>
				<p>
					С изменением сезона меняется и ландшафт возможностей для путешествий.
					Почему бы этой осенью не насладиться мировой красотой и культурным
					разнообразием с помощью наших специально подобранных групповых туров с
					гидом? От наслаждения прекрасными пейзажами Италии до путешествий за
					ее пределы и отметки в списке желаний, таких как Индия и Вьетнам, есть
					из чего выбирать.
				</p>
				<ul className='offers-hero__list'>
					<li className='offers-hero__item'>
						<span className='fi-rs-check-circle'></span>

						<p>
							<strong>Сохрани €75 </strong>
							при бронировании 7 ночей в 2025. Используй промокод: ELEPHANT25
						</p>
					</li>
					<li className='offers-hero__item'>
						<span className='fi-rs-check-circle'></span>

						<p>
							<strong>Сохрани €275 </strong>
							при бронировании 8 ночей в 2025. Используй промокод: ELEPHANT258
						</p>
					</li>
				</ul>
				<h4>Предложение ограничено. Не откладывай!</h4>
			</div>
		</div>
	)
}

export default OffersHero
