import { FC } from 'react'
import './ModalVideo.scss'

const ModalVideo: FC<{
	link: string
	value: boolean
	onChange: (val: boolean) => void
}> = ({ onChange, value, link }) => {
	return (
		<div
			className={value ? 'modal-video open' : 'modal-video'}
			onClick={() => onChange(false)}
		>
			<div className='modal-video__close' onClick={() => onChange(false)}>
				<span className='fi-rs-cross-small'></span>
			</div>
			<div className='modal-video__content'>
				<iframe
					width='100%'
					height='100%'
					src='https://www.youtube-nocookie.com/embed/yEB6UYP_mQg?si=2UtWzILiewdTftPC'
					title='YouTube video player'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					referrerPolicy='strict-origin-when-cross-origin'
					allowFullScreen
				></iframe>
			</div>
		</div>
	)
}

export default ModalVideo
