import { useQuery } from '@tanstack/react-query'
import { getHelp } from '../api/getHelp'



export const useQueryHelp = () => {

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['help'],
		queryFn: getHelp
	})

	return { isPending, error, isFetching, data}
}
