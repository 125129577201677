import { useQuery } from '@tanstack/react-query'
import { getParks } from '../api/getParks'



export const useQueryParks = () => {
	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['parks'],
		queryFn: getParks
	})

	return { isPending, error, isFetching, data }
}
