import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LoaderCss } from './components/ui/loader/LoaderCss'
import { useDelay } from './hooks/useDelay'
import { useQueryPlaceBySlug } from './hooks/useQueryPlaceBySlug'
import { useQueryTownFromBySlug } from './hooks/useQueryTownFromBySlug'
import ToursFilters from './pages/tours-select/ToursFilters'
import ToursResult from './pages/tours-select/ToursResult'
import { EnumSearchUrl } from './types/types'
import ToursFiltersMobile from './pages/tours-select/ToursFiltersMobile'

function ToursSelect() {
	let [searchParams, setSearchParams] = useSearchParams()

	const [dateFrom, setDateFrom] = useState<string>('')
	const [valueAdults, setValueAdults] = useState<number>(0)
	const [valueYouths, setValueYouths] = useState<number>(0)
	const [valueChildren, setValueChildren] = useState<number>(0)
	const [valueInfants, setValueInfants] = useState<number>(0)

	useEffect(() => {
		setValueAdults(parseInt(searchParams.get(EnumSearchUrl.Adults) || '') || 0)
		setValueYouths(parseInt(searchParams.get(EnumSearchUrl.Youths) || '') || 0)
		setValueChildren(
			parseInt(searchParams.get(EnumSearchUrl.Children) || '') || 0
		)
		setValueInfants(
			parseInt(searchParams.get(EnumSearchUrl.Infants) || '') || 0
		)
		setDateFrom(searchParams.get(EnumSearchUrl.DateFrom) || '')
	}, [searchParams])

	const {data:townFrom,isPending:townToPending}  = useQueryTownFromBySlug(searchParams.get(EnumSearchUrl.TownFrom)||'')
	const {data:townTo,isPending:townFromPending}  = useQueryPlaceBySlug(searchParams.get(EnumSearchUrl.TownTo)||'')

    const { isLoading } = useDelay(searchParams.toString(), 2000)


	if (isLoading) return <LoaderCss />

	return (
		<>
			<ToursFilters
				dateFrom={dateFrom}
				persons={valueAdults + valueChildren + valueInfants + valueYouths}
				townFrom={townFrom?.location||''}
				townTo={townTo?.location||''}
			/>

			<ToursFiltersMobile/>
			<ToursResult dateFrom={dateFrom} townFrom={townFrom?.slug||''} townTo={townTo?.slug||''} />
		</>
	)
}

export default ToursSelect
