import { FC } from 'react'
import { Link } from 'react-router-dom'
import './Browse.scss'
import { useQueryTours } from '../../hooks/useQueryTours'
import { EnumTourType } from '../../types/types'


const CardBR: FC<{
	name: string
	slug: string
	image: string
}> = ({ image, name,slug }) => {
	return (
		<div className='card-br w-100 lg:w-1/3 sm:w-1/2 '>
			<Link  to={`/tematics/${slug}`} className='card-br__image'>
				<img
					
					src={`images/tematics/${image}`}
					alt=''
				/>
			</Link>
			<Link to={`/tematics/${slug}`} className='card-br__text'>{name}</Link>
		</div>
	)
}

function Browse() {
	const {data,isPending,error}  = useQueryTours(EnumTourType.Tematics)

	if (isPending) return(
		<div>'Loading...'</div>
	) 

	return (
		<div className='browse section'>
			<div className='browse__container container'>
				<h2 className='browse__title title center'>
				Больше тематических туров
				</h2>
				<div className='browse__block'>
					{data?.filter((el,index) => index>6&&index <= 20)
						.map((item, index) => (
							<CardBR key={index} image={item.image}
							 name={item.name} 
							 slug={item.slug} 
							 />
						))}
				</div>
			</div>
		</div>
	)
}

export default Browse
