import { FC } from 'react'
import Search from '../../components/search/Search'
import './ModalEdit.scss'

const ModalEdit: FC<{
	value: boolean
	onChange: (val: boolean) => void
}> = ({ onChange, value }) => {
	return (
		<div
			className={value ? 'modal-edit open' : 'modal-edit'}
			onClick={() => onChange(false)}
		>
			<div className='modal-edit__content'
			onClick={(e)=>{e.stopPropagation();
				
				// @ts-ignore
				if(e.target.classList.contains('search__btn')){
					onChange(false)
				}
			}}
			>
				<div className='modal-edit__close' onClick={() => onChange(false)}>
					<span className='fi-rs-cross-small'></span>
				</div>
				<Search />
			</div>
		</div>
	)
}

export default ModalEdit
