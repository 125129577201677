import { useState } from 'react'
import { Link } from 'react-router-dom'
import conversation from '../../assets/footer/conversation.png'
import flame from '../../assets/footer/flame.png'
import map from '../../assets/footer/map.png'
import { useQueryPlaces } from '../../hooks/useQueryPlaces'
import './Footer.scss'
import { useQueryTours } from '../../hooks/useQueryTours'
import { EnumTourType } from '../../types/types'

function Footer() {
	const { data: places, isPending, error } = useQueryPlaces()
	const { data: tematics } = useQueryTours(EnumTourType.Tematics)

	const [open1, setOpen1] = useState(false)
	const [open2, setOpen2] = useState(false)
	const [open3, setOpen3] = useState(false)

	
	

	if (isPending) return <div>'Loading...'</div>

	return (
		<footer className='footer'>
			<div className='footer__container g-col-1 lg:g-col-3'>
				<div className='footer__column'>
					<div className='footer__wrapper' onClick={() => setOpen1(!open1)}>
						<img width={40} height={40} src={flame} alt='' />
						<h2 className='footer__title subtitle'>Популярные туры</h2>
						<span className='fi-rs-angle-small-down lg:hidden'></span>
					</div>
					<div
						className={
							open1
								? 'footer__list-wrapper open opacity-0 max-h-0 lg:opacity-1 lg:max-h-100'
								: 'footer__list-wrapper opacity-0 max-h-0 lg:opacity-1 lg:max-h-100'
						}
					>
						<ul className='footer__list'>
							{tematics
								?.filter((t,index) => index <= 10)
								.map(t => (
									<li className='footer__item' key={t.id}>
										<Link to={`/tematics/${t.slug}`} className='footer__link text'>
											{t.name}
										</Link>
									</li>
								))}
						</ul>
						<Link to='/tematics' className='footer__all text'>
							Все типы
							<svg
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								width='12'
								height='12'
								viewBox='0 0 512 512'
							>
								<path
									d='M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z'
									fill='currentColor'
								></path>
							</svg>
						</Link>
					</div>
				</div>
				<div className='footer__column'>
					<div className='footer__wrapper' onClick={() => setOpen2(!open2)}>
						<img width={40} height={40} src={map} alt='' />
						<h2 className='footer__title subtitle'>
							Популярные направления
						</h2>
						<span className='fi-rs-angle-small-down lg:hidden'></span>
					</div>
					<div
						className={
							open2
								? 'footer__list-wrapper open opacity-0 max-h-0 lg:opacity-1 lg:max-h-100'
								: 'footer__list-wrapper opacity-0 max-h-0 lg:opacity-1 lg:max-h-100'
						}
					>
						<ul className='footer__list'>
							{places
								?.filter(t => t.id <= 10)
								.map(t => (
									<li className='footer__item' key={t.id}>
										<Link to={`/places/${t.slug}`} className='footer__link text'>
											{t.location}
										</Link>
									</li>
								))}
						</ul>
						<Link to='/places' className='footer__all text'>
							Все направления
							<svg
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								width='12'
								height='12'
								viewBox='0 0 512 512'
							>
								<path
									d='M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z'
									fill='currentColor'
								></path>
							</svg>
						</Link>
					</div>
				</div>
				<div className='footer__column'>
					<div className='footer__wrapper' onClick={() => setOpen3(!open3)}>
						<img width={40} height={40} src={conversation} alt='' />
						<h2 className='footer__title subtitle'>Нужна помощь?</h2>
						<span className='fi-rs-angle-small-down lg:hidden'></span>
					</div>
					<div
						className={
							open3
								? 'footer__list-wrapper open opacity-0 max-h-0 lg:opacity-1 lg:max-h-100'
								: 'footer__list-wrapper opacity-0 max-h-0 lg:opacity-1 lg:max-h-100'
						}
					>
						<ul className='footer__list'>
							<li className='footer__item'>
								<Link to='/' className='footer__link text'>
									О нас
								</Link>
							</li>
							<li className='footer__item'>
								<Link to='/' className='footer__link text'>
									Наши контакты
								</Link>
							</li>
							<li className='footer__item'>
								<Link to='/' className='footer__link text'>
									FAQ
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
