import { FC, useState } from "react"
import PeopleList from "./PeopleList"
import './PeopleControl.scss'
import { EnumPeople } from "../../types/types"


const PeopleControl: FC<{
	isShow:boolean,
	setShow:(val:boolean)=>void,
	valueInfants: number
	valueChildren: number
	valueYouths: number
	valueAdults: number
	onChange: (type: EnumPeople, val: number) => void
	iconClass: string
}> = ({
	iconClass,
	onChange,
	valueAdults,
	valueChildren,
	valueInfants,
	valueYouths,isShow,setShow
}) => {


	return (
		<div className='people-control'>
			<div
				className='people-control__inner '
				onClick={e => {
					
					setShow(!isShow)
				}}
			>
				<span className={`fi ${iconClass} people-control__icon`}></span>
				<div className='people-control__count text-sm'>
					<span>
						{valueAdults + valueChildren + valueInfants + valueYouths}
					</span>
				</div>
			</div>

			<PeopleList
				valueAdults={valueAdults}
				valueChildren={valueChildren}
				valueInfants={valueInfants}
				valueYouths={valueYouths}
				onChange={onChange}
				isShow={isShow}
				setShow={(val:boolean)=>setShow(val)}
			/>
		</div>
	)
}

export default PeopleControl