import PhotoSwipeLightbox from 'photoswipe/lightbox'
import { FC, useEffect } from 'react'
import loopImg from '../../../assets/icons/loop.svg'
import './Galery.scss'
import { GaleryImageItem } from '../../../types/types'


const Galery: FC<{
	images: GaleryImageItem[]
}> = ({ images }) => {
	useEffect(() => {
		const galleryEl = document.querySelector('#galery')

		let lightbox = new PhotoSwipeLightbox({
			dataSource: images,
			initialZoomLevel: 'fit',
			secondaryZoomLevel: 1.5,
			maxZoomLevel: 1,
			pswpModule: () => import('photoswipe')
		})

		//@ts-ignore
		lightbox.addFilter('thumbEl', (thumbEl, data, index) => {
			//@ts-ignore
			const el = galleryEl.querySelector('[data-id="' + data.id + '"] img')

			if (el) {
				return el
			}
			return thumbEl
		})

		lightbox.init()

		//@ts-ignore
		window.pswpLightbox = lightbox

		return () => {
			lightbox.destroy()
			//  @ts-ignore
			lightbox = null
		}
	}, [])

	

	return (
		<div className='galery'>
				<div
					className='galery__view'
					onClick={() => {
						//@ts-ignore
						pswpLightbox.loadAndOpen(0)
						return false
					}}
				>
					<span>Смотреть фото</span>
				</div>
				<div className='galery__images' id='galery'>
					{images.map(m => (
						<div
							key={m.id}
							className='galery__image'
							data-id={m.id}
							onClick={() => {
								//@ts-ignore
								pswpLightbox.loadAndOpen(m.id)
								return false
							}}
						>
							<img className='galery__img' src={m.src} alt='' />
							<div className='galery__shadow'>
								<img width={40} height={40} src={loopImg} alt='' />
							</div>
						</div>
					))}
				</div>
			</div>
	)
}

export default Galery