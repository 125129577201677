import { FC, useState } from 'react'
import thumbnailImg from '../../assets/park-thumbnail.jpg'
import playImg from '../../assets/youtube.png'
import './ParkVideoBlock.scss'
import { createPortal } from 'react-dom'
import ModalVideo from '../../components/ModalVideo/ModalVideo'

const ParkVideoBlock: FC<{ title: string; text: string }> = ({
	title,
	text
}) => {
	const [showModalVideo, setShowModalVideo] = useState<boolean>(false)

	const handleShowModalVideo = (val: boolean) => {
		setShowModalVideo(val)
	}

	return (
		<div className='park-video'>
			<div className='park-video__left'>
				<h2 className='pakk-video__title subtitle left'>{title}</h2>
				<p className='pakk-video__text left'>{text}</p>
			</div>
			<div className='park-video__right'>
				<div className='park-video__block'
                onClick={()=>{handleShowModalVideo(true)}}
                >
					<img className='park-video__thumbnail' src={thumbnailImg} alt='' />
					<img
						width={140}
						height={100}
						className='park-video__btn'
						src={playImg}
						alt=''
					/>
				</div>

				{showModalVideo &&
					createPortal(
						<ModalVideo
							value={showModalVideo}
							onChange={handleShowModalVideo}
							link=''
						/>,
						document.body
					)}
			</div>
		</div>
	)
}
export default ParkVideoBlock
