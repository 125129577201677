import { FC } from 'react'
import { GaleryImageItem } from '../../../types/types'
import './GalerySlick.scss'
// @ts-ignore
import Slider from 'react-slick'

const GalerySlick: FC<{
	images: GaleryImageItem[]
}> = ({ images }) => {
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	}

	return (
		<div className='galery-slick'>
			<Slider {...settings}>
				{images.map(m => (
					<div key={m.id} className='galery-slick__image'>
						<img className='galery-slick__img' src={m.src} alt='' />
					</div>
				))}
			</Slider>
		</div>
	)
}

export default GalerySlick
