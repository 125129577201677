import { useQuery } from '@tanstack/react-query'
import { BlogItem } from '../types/types'
import { getBlogs } from '../api/getBlogs'


export const useQueryBlogs = () => {
	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['blogs'],
		queryFn: getBlogs
	})

	return { isPending, error, isFetching, data }
}
