import { FC, useState } from "react"
import './PlaceHero.scss'

const PlaceHero: FC<{
	name: string | undefined
}> = ({ name }) => {
	const [viewFull, setViewFull] = useState(true)
	return (
		<div className='place-hero section'>
			<div className='place-hero__container container'>
				<h1 className='place-hero__title title center blue'>{name}</h1>
				<p  className='text'>
					Откройте для себя мир с одним из наших туристических отпусков. С нашим
					широким выбором направлений для отдыха весь мир у ваших ног. Выберите
					регион, который вы хотели бы исследовать, и присоединяйтесь к нам в
					новом приключении. От Европы до Америки, от Ближнего Востока до Азии
					вы можете путешествовать по миру и исследовать страны, которые вы
					никогда не видели раньше.
				</p>
				<div className={viewFull ? 'place-hero__collapsed' : 'place-hero__collapsed open'}>
					<p  className='text'>
						Ищете ли вы короткую поездку в солнечную Испанию или уникальное
						приключение в Индии, у нас есть множество направлений для
						путешествий, которые удовлетворят все ваши потребности.
					</p>
					<p  className='text'>
						Получите инсайдерскую информацию от наших групповых туров с местными
						гидами и получите максимум от своего отпуска Большинство наших туров
						включают перелеты, размещение, трансферы, экскурсии и отмеченных
						наградами англоговорящих гидов. Забронируйте место для отпуска своей
						мечты и путешествие с нами уже сегодня.
					</p>
				</div>
				<button
					className='place-hero__btn-collapsed text-big'
					onClick={() => setViewFull(!viewFull)}
				>
					{viewFull ? 'Читать больше' : 'Свернуть'}
				</button>
			</div>
			<div className='border'></div>
		</div>
	)
}

export default PlaceHero