import { useQuery } from '@tanstack/react-query'
import { getTours } from '../api/getTours'
import { sleep } from '../utils'
import { EnumTourType } from '../types/types'



export const useQueryToursByLocation = (location_slug:string,type:EnumTourType) => {

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['tours',location_slug,type],
		queryFn: getTours,
		select:data=>data.filter(d=>d.location_slug===location_slug&&d.type===type)
	})


	return { isPending, error, isFetching, data}
}
