import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import ModalEdit from './ModalEdit'
import './ToursFilters.scss'

const  ToursFilters:FC<{townFrom:string, townTo:string,dateFrom:string,persons:number}>=({
	townFrom,townTo,dateFrom,persons})=> {
	const [showModalEdit, setShowModalEdit] = useState<boolean>(false)

	const handleShowModalEdit=(val:boolean)=>{
		setShowModalEdit(val)
	}




	return (
		<div className='tours-filters hidden lg:flex'>
			<div className='tours-filters__filter'>
				<span className='fi-rs-plane-departure tours-filters__icon'></span>
				<span className='tours-filters__value text-big'>{townFrom}</span>
			</div>
			<div className='tours-filters__filter'>
				<span className='fi-rs-plane-arrival tours-filters__icon'></span>
				<span className='tours-filters__value text-big'>{townTo}</span>
			</div>
			<div className='tours-filters__filter'>
				<span className='fi-rs-plane-departure tours-filters__icon'></span>

				<span className='fi-rs-calendar tours-filters__icon'></span>

				<span className='tours-filters__value text-big'>{dateFrom}</span>
			</div>
			<div className='tours-filters__filter'>
				<span className='fi-rs-users-alt tours-filters__icon'></span>
				<span className='tours-filters__value text-big'>
					{persons}
				</span>
			</div>
			<div className='tours-filters__edit'>
				<span className='fi-rs-pencil tours-filters__icon'></span>
				<button className='tours-filters__edit-btn text'
				onClick={()=>{handleShowModalEdit(true)}}
				>Изменить</button>
			</div>
			{showModalEdit &&
				createPortal(
					<ModalEdit  value={showModalEdit} onChange={handleShowModalEdit}/>,
					document.body
				)}
		</div>
	)
}

export default ToursFilters
