import { useQuery } from '@tanstack/react-query'
import { getTownsFrom } from '../api/getTownsFrom'


export const useQueryTownFromBySlug = (slug:string) => {

	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['towns-from'],
		queryFn: getTownsFrom,
		select:data=>data.filter(d=>d.slug===slug)[0]
	})
	

	return { isPending, error, isFetching, data}
}
