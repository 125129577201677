import { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import circleImg from './circle.svg'
import circleCheckImg from './check-circle.svg'

import './BookHeaderMobile.scss'

const BookHeaderMobile: FC<{ step: number }> = ({ step }) => {
	const [linkBack, setLinkBack] = useState<string>('/')

	useEffect(() => {
		if(localStorage.getItem('booking')){
			const lc = JSON.parse(localStorage.getItem('booking') || '')
			setLinkBack(lc.backUrl || '')

		}else{
			setLinkBack('')

		}
	}, [])

	const links = [
		{ step: 1, link: linkBack, text: 'Выбор даты' },
		{ step: 2, link: '/booking-room', text: 'Выбор номера' },
		{ step: 3, link: '/booking-persons', text: 'Пассажиры' },
		{ step: 4, link: '/booking-payment', text: 'Оплата' }
	]

	const classNameItem = (step: number, stepLink: number) => {
		const active = step === stepLink ? ' active' : ''

		return 'book-header-mobile__item' +  active
	}

	const classNameLink = (step: number, stepLink: number) => {
		const cursorDef = stepLink >= step ? ' cursor-default ' : ''
		const checked = stepLink <= step ? ' checked' : ''

		return 'book-header-mobile__link' + cursorDef+checked
	}

	return (
		<div className='book-header-mobile xl:hidden'>
			<div className='book-header-mobile__container'>
				<nav className='book-header-mobile__menu'>
					{links.map(el => (
						<div key={el.step} className={classNameItem(step, el.step)}>
							<Link
								to={el.link}
								key={el.step}
								className={classNameLink(step, el.step)}
								onClick={e => {
									if (el.step >= step) {
										e.preventDefault()
									}
								}}
							>
								{step >= el.step
								?<img width={20} height={20} src={circleCheckImg} alt="" />
							:<img width={20} height={20} src={circleImg} alt="" />}
							</Link>
							<span className='book-header-mobile__name text-sm'>{el.text}</span>
						</div>
					))}
				</nav>
			</div>
		</div>
	)
}

export default BookHeaderMobile
