import { FC } from 'react'
import { ReviewItem } from '../../types/types'
import AvatarReview from '../avatar-review/AvatarReview'
import RatingStars from '../ui/rating-stars/RatingStars'
import './Review.scss'

const Review: FC<{ value: ReviewItem }> = ({ value }) => {
	return (
		<div className='review'>
			<div className='review__left'>
				<RatingStars value={value.rating} />
				<p className='review__date text'>{value.date}</p>
			</div>
			<div className='review__middle'>
				<div className='review__avatar'>
					<AvatarReview location={value.location} name={value.name} />
					<p className='review__text text'>
						Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque
						dolorum error obcaecati reiciendis, voluptatibus quidem accusantium
						culpa voluptatum similique consequatur, perferendis repudiandae
						assumenda dolores quo! Officiis culpa voluptate dolorem sed nulla
						mollitia ipsam molestias ab officia reprehenderit cumque neque
						laudantium, veritatis dolor quia, odit corporis, sint distinctio
						recusandae doloribus ad?
					</p>
				</div>
			</div>
			<div className='review__right'>
				<div className='review__right-top'>
					<div className='review__travel-type text'>Я путешествовал с семьей</div>
					<span className='fi-rs-family'></span>
				</div>
				<div className='review__right-bottom'>
					
				</div>
			</div>
		</div>
	)
}

export default Review
