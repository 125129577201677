import { useQuery } from '@tanstack/react-query'
import { getCategories } from '../api/getCategories'


export const useQueryCategoies = () => {
	const { isPending, error, data, isFetching } = useQuery({
		queryKey: ['categories'],
		queryFn: getCategories
	})

	return { isPending, error, isFetching, data }
}
