import { Link } from 'react-router-dom'
import elephant from '../../assets/experts/elephant.png'
import group from '../../assets/experts/group.png'
import sun from '../../assets/experts/sun.png'
import worldwide from '../../assets/experts/worldwide.png'
import { CardExpertsItem } from '../../types/types'
import './Experts.scss'
import { FC } from 'react'

const expCards: CardExpertsItem[] = [
	{
		id: 1,
		image: worldwide,
		classPrefix:"--worldwide",
		text: 'Никогда не рано бронировать отпуск',
		title: 'Туры 2025 года',
		url: '/'
	},
	{
		id: 2,
		image: group,
		classPrefix:"--group",

		text: 'Откройте для себя новое направление',
		title: 'Новые направления',
		url: '/'
	},
	{
		id: 3,
		image: sun,
		classPrefix:"--sun",

		text: 'Никогда не поздно уехать',
		title: 'Горящие предложения',
		url: '/'
	},
	{
		id: 4,
		image: elephant,
		classPrefix:"--elephant",

		text: 'Запросите БЕСПЛАТНУЮ брошюру',
		title: '2025 год Слона',
		url: '/'
	}
]

const CardExp: FC<{
	text: string
	image: string
	title: string
	url: string
	classPrefix: string
}> = ({ image, text, title, url,classPrefix }) => {
	return (
		<Link to={url} className={`card-ex card-ex${classPrefix}`}>
			<img
				src={image}
				className='card-ex__image'
				alt=''
			/>

			<h3 className='card-ex__title subtitle center'>{title}</h3>
			<p className='card-ex__text hidden md:block text center'>{text}</p>
		</Link>
	)
}

function Experts() {
	return (
		<div className='experts section'>
			<div className='experts__container container'>
				<h2 className='experts__title title center blue'>
					Эксперты в организации групповых и тематических туров
				</h2>
				<div className='experts__inner'>
					{expCards.map(c => (
						<CardExp
							key={c.id}
							classPrefix={c.classPrefix}
							image={c.image}
							title={c.title}
							text={c.text}
							url={c.url}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default Experts
