import { FC, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select, { components } from 'react-select'
import { personOptions } from '../../constants'
import { useQueryPlaceBySlug } from '../../hooks/useQueryPlaceBySlug'
import { useQueryTourBySlug } from '../../hooks/useQueryTourBySlug'
import {
	EnumSearchUrl,
	EnumTourType
} from '../../types/types'
import './BookWidget.scss'

export const BookWidget: FC<{type:EnumTourType}> = ({type}) => {
	const [personCount, setPersonCount] = useState<number>(0)

	// @ts-ignore
	const Control = ({ children, ...props }) => (
		// @ts-ignore
		<components.Control {...props}>
			<span className=' custom-select__icon fi-rs-user'></span> {children}
		</components.Control>
	)

	const { slug } = useParams()

	const {data:tour,isPending,error}  = useQueryTourBySlug(slug||'')
	const {data:townTo}  = useQueryPlaceBySlug(tour?.location_slug||'')


	return (
		<div className='book-widget'>
			<h3 className='book-widget__title'>
				Забронировать
				<span> от &#8381;3444</span>
			</h3>

			<div className='book-widget__select custom-select'>
				<label className='book-widget__label'>Количество</label>
				<Select
					className='book-widget-select'
					classNamePrefix='select'
					defaultValue={personOptions[0]}
					isClearable={false}
					components={{ Control, IndicatorSeparator: () => null }}
					isSearchable={false}
					options={personOptions}
					onChange={e => {
						setPersonCount(parseInt(e?.value || ''))
					}}
					styles={{
						singleValue: base => ({
							...base,
							padding: 5,
							color: '#002d72',
							fontWeight: 600,
							fontSize: '20px',
							display: 'flex'
						}),

						control: base => ({
							...base,

							border: '1px solid rgb(0  0 0/ 22%)',
							boxShadow: 'none',
							'&:hover': {
								border: '1px solid rgb(0  0 0/ 22%)'
							}
						}),

						menu: base => ({
							...base,

							width: '88%',
							left: '50px'
						})
					}}
				/>
			</div>

			<div className='book-widget__btn'>
				<Link
					to={`/${type}/${slug}/booking-dates?${EnumSearchUrl.TownFrom}=london
				&${EnumSearchUrl.TownTo}=${townTo?.slug}`}
					className=' btn-widget'
				>
					Даты и цены
				</Link>
			</div>
		</div>
	)
}

export default BookWidget
