import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc' // ES 2015
import { FC, useEffect, useState } from 'react'
import './ModalDates.scss'
import { IDatesFilter } from './BookFilters'

// import cn from 'classnames'
dayjs.extend(isoWeek)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const uniqueMonths = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Okt',
	'Nov',
	'Dec'
]

const ModalDates: FC<{
	show: boolean
	onShow: (val: boolean) => void
	dates: dayjs.Dayjs[]
	datesOptions: dayjs.Dayjs[]
	onChange: (val: dayjs.Dayjs[]) => void
	onChangeFilterYear: (val: number) => void
	onChangeFilterMonths: (val: number[]) => void
}> = ({
	onShow,
	show,
	dates,
	onChange,
	datesOptions,
	onChangeFilterMonths,
	onChangeFilterYear
}) => {
	const [year, setYear] = useState<number>(2024)
	const [months, setMonths] = useState<number[]>([])
	const [monthsActive, setMonthsActive] = useState<number[]>([])

	const [days, setDays] = useState<dayjs.Dayjs[]>([])
	const years = datesOptions.map(d => d.year())
	const uniqueYears = Array.from(new Set(years))

	useEffect(() => {
		const arrMonths = datesOptions
			.filter(d => d.isSame(`${year}-01-01`, 'year'))
			.map(d => d.month())

		setMonths(Array.from(new Set(arrMonths)))
		onChangeFilterMonths([])
		onChangeFilterYear(year)
		setDays(datesOptions.filter(d => d.isSame(`${year}-01-01`, 'year')))
		setMonthsActive([])
	}, [year])

	return (
		<div
			className={show ? 'modal-dates open' : 'modal-dates'}
			onClick={() => onShow(false)}
		>
			<div
				className='modal-dates__content'
				onClick={e => {
					e.stopPropagation()

					// @ts-ignore
					if (e.target.classList.contains('search__btn')) {
						onShow(false)
					}
				}}
			>
				<div className='modal-dates__close' onClick={() => onShow(false)}>
					<span className='fi-rs-cross-small'></span>
				</div>
				<div className='modal-dates__border'></div>

				<div className='modal-dates__title left text-sm'>Выберите год</div>
				<div className='modal-dates__list-years'>
					{uniqueYears.map(el => (
						<div
							key={el}
							className={year === el ? 'm-year active' : 'm-year'}
							onClick={() => setYear(el)}
						>
							{el}
						</div>
					))}
				</div>
				<div className='modal-dates__title left text-sm'>Выберите месяц(ы)</div>
				<div className='modal-dates__list text-sm'>
					{uniqueMonths.map((el, index) => (
						<div
							key={el}
							className={
								monthsActive?.includes(index)
									? 'm-month active'
									: months?.includes(index)
										? 'm-month exist'
										: 'm-month'
							}
							onClick={() => {
								if (monthsActive?.includes(index)) {
									const newArr = monthsActive.filter(m => m !== index)
									if (newArr.length > 0) {
										let arrDays1: dayjs.Dayjs[] = []

										newArr.forEach(m => {
											const arrDays2 = datesOptions.filter(d =>
												d.isSame(`${year}-${m + 1}-01`, 'month')
											)
											arrDays1.push(...arrDays2)
										})

										setDays(arrDays1)
									} else {
										setDays(
											datesOptions.filter(d =>
												d.isSame(`${year}-01-01`, 'year')
											)
										)
									}
									setMonthsActive(monthsActive.filter(m => m !== index))
								} else {
									const newArr = [...monthsActive, index]
									if (newArr.length > 0) {
										let arrDays1: dayjs.Dayjs[] = []

										newArr.forEach(m => {
											const arrDays2 = datesOptions.filter(d =>
												d.isSame(`${year}-${m + 1}-01`, 'month')
											)
											arrDays1.push(...arrDays2)
										})

										setDays(arrDays1)
									} else {
										setDays(
											datesOptions.filter(d =>
												d.isSame(`${year}-01-01`, 'year')
											)
										)
									}
									setMonthsActive([...monthsActive, index])
								}
							}}
						>
							{el}
						</div>
					))}
				</div>
				<div className='modal-dates__title left text-sm'>Доступные даты</div>

				<div className='modal-dates__list text-sm'>
					{days.map((el, index) => (
						<div key={index} className='m-day'>
							{el.format('DD MMM YY')}
							{/* {el.format('DD.MM.YYYY')} */}
						</div>
					))}
				</div>
				{/* <div className=''>Отладка</div>

				<div className=''>
					{monthsActive.map((el,index) => (
						<div key={index} className='m-day'>
							{el}
						</div>
					))}
				</div> */}
				<div className='modal-dates__btn text-sm'>
					<button
						onClick={() => {
							onChange(days)
							onChangeFilterMonths(monthsActive)
							onShow(false)
						}}
					>
						Применить
					</button>
				</div>
			</div>
		</div>
	)
}

export default ModalDates
