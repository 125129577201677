import { FC } from 'react'
import './ButtonBook.scss'

const ButtonBook: FC<{
	title: string,
	
	className?:string,
}> = ({ title,className }) => {


	
	return (
		<button  className={`${className} btn-book text`}  type='submit'
		
		>{title}</button>

	)
}

export default ButtonBook