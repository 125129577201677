import { useEffect, useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import CardT from '../../components/card-t/CardT'
import { EnumTourType, TourItem } from '../../types/types'
import './PlacesBook.scss'
import { useQueryTours } from '../../hooks/useQueryTours'

function PlacesBook() {
	const navigation = {
		enabled: true,
		nextEl: '.places-book__next',
		prevEl: '.places-book__prev'
	}

	const { data, isPending, error } = useQueryTours(EnumTourType.Tours)

	if (isPending) return <div>'Loading...'</div>

	return (
		<div className='places-book section'>
			<div className='places-book__container container'>
				<h2 className='places-book__title title center blue'>
					Забронируйте экскурсионный тур на 2025 год
				</h2>
				<div className='places-book__wrapper'>
					<Swiper
						modules={[Navigation]}
						navigation={navigation}
						slidesPerView={'auto'}
						spaceBetween={10}
					>
						{data?.map((item, index) => (
							<SwiperSlide key={index} className='sm:max-w-[405px]'>
								<CardT
									image={item.image}
									slug={item.slug}
									location={item.location}
									price={item.price}
									pricePrev={item.pricePrev}
									title={item.name}
								/>
							</SwiperSlide>
						))}
					</Swiper>
					<button className='places-book__prev hidden lg:block '>
						<span className='fi-rs-angle-left'></span>
					</button>
					<button className='places-book__next  hidden lg:block'>
						<span className=' fi-rs-angle-right'></span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default PlacesBook
