import { FC } from 'react'
import spinnerImg from './spinner.gif'

import './ButtonLoading.scss'

const ButtonLoading: FC<{
}> = () => {


	
	return (
		<button  className='btn-loading'>
			<span className="btn-loading__loader"></span>
			{/* <img width={50} height={50} src={spinnerImg} alt="" /> */}
		</button>

	)
}

export default ButtonLoading