import { FC, useRef, useState } from 'react'
import './PlaceControl.scss'
import PlaceList from './PlaceList'
import { PlaceItem, TownsFromItem } from '../../types/types'

const PlaceControl: FC<{
	isShow:boolean,
	setShow:(val:boolean)=>void,
	options: TownsFromItem[] | PlaceItem[]
	value: string
	onChange: (val: string) => void
	labelName: string
	iconClass: string
	showError: boolean
}> = ({ labelName, iconClass, value, onChange, options, showError,isShow,setShow }) => {

	const refInput = useRef<HTMLInputElement>(null)

	const handleChangeList = (val: string) => {
		onChange(val)
	}


	return (
		<div className={showError ? 'place-control error' : 'place-control'}>
			{showError && (
				<div className='place-control__icon-error'>
					<span className='fi-rs-triangle-warning'></span>
				</div>
			)}
			<div
				className='place-control__inner'
				onClick={(e) => {
					refInput.current?.focus()
					onChange('')
					setShow(!isShow)
				}}
			>
				<span className={`fi ${iconClass} place-control__icon`}></span>
				<input
					ref={refInput}
					className='place-control__input text-sm'
					type='text'
					readOnly
					defaultValue={value}
				/>
				<span
					className={
						value !== '' || isShow
							? 'place-control__name place-control__name--top text-xs'
							: 'place-control__name text-sm'
					}
				>
					{labelName}
				</span>
			</div>
			<PlaceList
				options={options}
				onChange={handleChangeList}
				isShow={isShow}
				setShow={(val:boolean)=>setShow(val)}
			/>
		</div>
	)
}

export default PlaceControl
