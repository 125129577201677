import { FC, useState } from 'react'
import { Picker } from '../picker/Picker'
import './ModalDate.scss'
import { useBodyScrollLock } from '../../hooks/useBodyScrollLock '

const ModalDate: FC<{
	isOpen: boolean
	value: string
	onNext: (val: string) => void
	onBack: () => void
}> = ({ onNext, onBack, value, isOpen }) => {

	useBodyScrollLock()

	const [date, setDate] = useState<string>(value)

	return (
		<div className={isOpen ? 'modal-date open' : 'modal-date'}>
			<div className='modal-date__content'>
				<div className='modal-date__top'>
					<div className='modal-date__list'>
						<h3 className='modal-date__title title left blue'>Выберите дату</h3>
						<div className='modal-date__picker'>
							<Picker value={date} onChange={val => setDate(val)} />
						</div>
					</div>
				</div>
				<div className='modal-date__bottom'>
					<button className='modal-date__back text-sm' onClick={() => onBack()}>
						<span className='fi-rs-arrow-small-left'></span>
						Назад
					</button>
					<button className='modal-date__next text-sm' onClick={() => onNext(date)}>
						Искать
						<span className='fi-rs-arrow-small-right'></span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default ModalDate
