import { TourItem } from "../types/types"

export const getTours = async (): Promise<TourItem[]> => {
	const res = await fetch(`${process.env.PUBLIC_URL}/tours.json`)
	if (!res.ok) {
		throw new Error('Network response was not ok')
	}

	
	return res.json()
}