import { useParams } from 'react-router-dom'
import './Park.scss'
import bannerImg from './assets/banner-park.jpg'
import Banner from './components/banner/Banner'
import { CardPark } from './components/card-park/CardPark'
import { useQueryParkBySlug } from './hooks/useQueryParkBySlug'
import { useQueryParks } from './hooks/useQueryParks'
import ParkVideoBlock from './pages/park/ParkVideoBlock'

function Park() {
	let { slug } = useParams<'slug'>()

	const { data:park, error } = useQueryParkBySlug(slug || '')
	const { data:parksList,isPending } = useQueryParks()

	if (isPending) return <div>'Loading...'</div>


	return (
		<>
			<Banner image={bannerImg} />
			<div className='park'>
				<div className='park__container'>
					<span className='park__location'>{park?.location}</span>
					<h1 className='park__title title-big left'>{park?.name}</h1>

					<div className='park__inner'>
						<div className='park__left'>
							<p className='text-big'>
								Вы страстный путешественник с душой для животных? Если вы
								мечтаете о незабываемом приключении, которое сочетает в себе
								захватывающие дух пейзажи, культурные исследования и возможность
								общения с величественными существами, то Таиланд должен быть
								наверху вашего списка путешествий.
							</p>
							<p className='text-big'>
								Мы все разделяем глубокую привязанность к слонам — их
								интеллекту, их грации и их огромным размерам. Таиланд давно
								ассоциируется с этими великолепными созданиями, но важно
								выбирать свои впечатления от общения со слонами обдуманно.
								Этические заповедники для слонов предлагают альтернативу
								традиционным туристическим аттракционам, которые эксплуатируют
								этих нежных гигантов.
							</p>
							<p className='text-big'>
								Поддерживая этические заповедники, вы вносите вклад в
								благополучие слонов и поддерживаете ответственный туризм.
							</p>

							<h2 className='title left'>
								Погружаемся глубже в этические заповедники слонов в Таиланде
							</h2>
							<img
								className='park__img'
								src={`/images/parks/card1.jpg`}
								alt=''
							/>
							<p className='text-big'>
								В качестве вашего попутчика ElephantTour, туристическая
								компания, предлагающая дешевые авиабилеты и праздничные
								предложения из Самары, здесь, чтобы провести вас по лучшим
								этическим заповедникам слонов для посещения в Таиланде. Давайте
								окунемся и откроем для себя красоту ответственных и значимых
								встреч со слонами!
							</p>
							<p className='text-big'>
								Сосредоточившись на благополучии и свободе слонов, этот
								заповедник позволяет этим животным бродить, общаться и выражать
								свое естественное поведение. Посетители могут заниматься
								ненавязчивым наблюдением, понимая важность того, чтобы позволить
								слонам быть слонами.
							</p>
							<ul className='park__list'>
								<li className='park__item'>
									<span className='fi-rs-check-circle'></span>
									<p className='text'>Этический уход за слонами и ответственный туризм.</p>
								</li>
								<li className='park__item'>
									<span className='fi-rs-check-circle'></span>
									<p className='text'>
										Реабилитацией и предоставлением безопасного пространства для
										животных.
									</p>
								</li>
								<li className='park__item'>
									<span className='fi-rs-check-circle'></span>
									<p className='text'>
										Возможность понаблюдать за слонами в естественной среде
										обитания и узнать их истории.
									</p>
								</li>
								<li className='park__item'>
									<span className='fi-rs-check-circle'></span>
									<p className='text'>
										Приверженность святилища этическим нормам гарантирует
										незабываемые и впечатляющие впечатления.
									</p>
								</li>
							</ul>
							<h2 className='title left'>Ваш визит имеет значение</h2>
							<img
								className='park__img'
								src={`/images/parks/card2.jpg`}
								alt=''
							/>
							<p className='text-big'>
								Когда вы выбираете посещение этических заповедников слонов, вы
								активно поддерживаете сдвиг в туристической индустрии. Продвигая
								ответственные практики, эти заповедники демонстрируют, что
								этический туризм может быть как удовлетворяющим для
								путешественников, так и полезным для животных. Ваш визит
								напрямую способствует уходу, сохранению и защите слонов в
								Таиланде.
							</p>
							<h2 className='title left'>Этический туризм: чего ожидать</h2>
							<img
								className='park__img'
								src={`/images/parks/card3.jpg`}
								alt=''
							/>
							<p className='text-big'>
								В отличие от многих традиционных аттракционов со слонами,
								этические заповедники ставят благополучие животных на первое
								место. Катание на лошадях и выступления не допускаются, так как
								эти практики могут быть вредными и стрессовыми для слонов. В
								этических заповедниках акцент делается на наблюдении за слонами
								в их естественной среде обитания. Вы станете свидетелями их
								взаимодействия, социальной динамики и повседневной жизни, не
								прерывая и не беспокоя их.
							</p>
							<h2 className='title left'>
								Почему стоит выбрать природные приюты для слонов?
							</h2>
							<img
								className='park__img'
								src={`/images/parks/card4.jpg`}
								alt=''
							/>
							<p className='text-big'>
								Мы все разделяем глубокую привязанность к слонам — их
								интеллекту, их грации и их огромным размерам. Таиланд давно
								ассоциируется с этими великолепными созданиями, но важно
								выбирать свои впечатления от общения со слонами обдуманно.
								Этические заповедники для слонов предлагают альтернативу
								традиционным туристическим аттракционам, которые эксплуатируют
								этих нежных гигантов. Поддерживая этические заповедники, вы
								вносите вклад в благополучие слонов и поддерживаете
								ответственный туризм.
							</p>
						</div>
					</div>

					<div className='park-video__block section'>
						<ParkVideoBlock
							title={park?.name || ''}
							text='Этические заповедники слонов в Таиланде предлагают исключительную возможность для общения с этими великолепными созданиями ответственным и значимым образом. Выбирая посещение этих заповедников, вы вносите вклад в благополучие слонов и поддерживаете переход к ответственному туризму'
						/>
					</div>

					<div className='section'>
						<h2 className='title center'>Вам также может понравиться</h2>
						<div className='park__block'>
							{parksList?.filter((_, index) => index <= 3)
								.map((item, index) => (
									<CardPark
										key={index}
										image={item.image}
										name={item.name}
										location={item.location}
										slug={item.slug}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Park
