import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlaceItem } from '../../types/types'
import './MobileDestination.scss'
import { useBodyScrollLock } from '../../hooks/useBodyScrollLock '
import { destPlaceholder } from '../../constants'

const MobileDestination: FC<{
	show: boolean
	setShow: (val: boolean) => void
	options: PlaceItem[] | []
	onApply: (val: PlaceItem) => void
}> = ({ onApply, options, show, setShow }) => {
	useBodyScrollLock()

	const [dest, setDest] = useState<PlaceItem>(destPlaceholder)

	return (
		<div className={show ? 'mobile-destination open' : 'mobile-destination'}>
			<div className='mobile-destination__content'>
				<div
					className='mobile-destination__close'
					onClick={() => setShow(false)}
				>
					<span className='fi-rs-cross-small'></span>
				</div>
				<div className='mobile-destination__border'></div>
				<div className='mobile-destination__list'>
					<h3 className='mobile-destination__title title left blue'>
						Выберите направление
					</h3>
					{options.map(o => (
						<div
							key={o.id}
							className={
								dest.id === o.id
									? 'mobile-destination__item checked'
									: 'mobile-destination__item'
							}
							onClick={() => setDest(o)}
						>
							<span className='fi-rs-check'></span>
							<div className='text'>{o.name}</div>
						</div>
					))}
				</div>
				<div className='mobile-destination__bottom'>
					<button
						className='mobile-destination__btn text-sm'
						onClick={() => onApply(dest)}
					>
						Применить
					</button>
				</div>
			</div>
		</div>
	)
}

export default MobileDestination
