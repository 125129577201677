import { CSSProperties, FC, useState } from 'react'
import ClockLoader from 'react-spinners/ClockLoader'
import { useQueryToursByLocation } from '../../hooks/useQueryToursByLocation'
import CardSel from './CardSel'
import './ToursResult.scss'
import { EnumTourType } from '../../types/types'

const override: CSSProperties = {
	display: 'block',
	margin: 'auto',
	borderColor: 'red'
}

const ToursResult: FC<{
	townFrom: string
	townTo: string
	dateFrom: string
}> = ({ townFrom, townTo, dateFrom }) => {
	

	const { data, isPending, error } = useQueryToursByLocation(townTo,EnumTourType.Tours)



	return (
		<div className='tours-result section'>
			<div className='tours-result__container'>
				<h2 className='tours-result__title title center blue'>
					Доступно вариантов: {data?.length}
				</h2>
				<div className='tours-result__cards g-col-1 md:grid-cols-2'>
					{data?.map(item => (
						<CardSel
							key={item.id}
							townFrom={townFrom}
							date={dateFrom || '01.06.2025'}
							image={item.image}
							location={item.location}
							name={item.name}
							price={item.price}
							pricePrev={item.pricePrev}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default ToursResult
