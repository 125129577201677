import { FC, useState } from 'react'
import { Picker } from '../../components/picker/Picker'
import { useBodyScrollLock } from '../../hooks/useBodyScrollLock '
import './MobileDate.scss'
import dayjs from 'dayjs'

const MobileDate: FC<{
	show: boolean
	setShow: (val: boolean) => void
	onApply: (val: string) => void
}> = ({ onApply, show, setShow }) => {
	useBodyScrollLock()

	const [date, setDate] = useState<string>(dayjs().format('DD.MM.YYYY'))

	return (
		<div className={show ? 'mobile-date open' : 'mobile-date'}>
			<div className='mobile-date__content'>
				<div className='mobile-date__close' onClick={() => setShow(false)}>
					<span className='fi-rs-cross-small'></span>
				</div>
				<div className='mobile-date__border'></div>

				<div className='mobile-date__list'>
					<h3 className='mobile-date__title title left blue'>Выберите дату</h3>
					<div className='mobile-date__picker'>
						<Picker value={date} onChange={val => setDate(val)} />
					</div>
				</div>
				<div className='mobile-date__bottom'>
					<button
						className='mobile-date__btn text-sm'
						onClick={() => onApply(date)}
					>
						Применить
					</button>
				</div>
			</div>
		</div>
	)
}

export default MobileDate
