import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import airplaneImg from './assets/icons/airplane.svg'
import guidImg from './assets/icons/guide.svg'
import loopImg from './assets/icons/loop.svg'
import mapImg from './assets/icons/map.png'
import moonImg from './assets/icons/moon.svg'
import speedImg from './assets/icons/speed.png'
import BookWidgetTour, { BookWidget } from './components/book-widget/BookWidget'
import { CardTB } from './components/card-tb/CardTb'
import { useQueryBlogsByParams } from './hooks/useQueryBlogsByParams'
import { useQueryTourBySlug } from './hooks/useQueryTourBySlug'
import useScroll from './hooks/useScroll'
import Steps from './pages/tour/Steps'
import TourHeader from './pages/tour/TourHeader'
import './Tour.scss'
import { EnumTourType } from './types/types'
import BookWidgetMobile from './components/book-widget/BookWidgetMobile'



function Tour() {
	const images = [
		{ id: 0, src: '/images/places/card1.jpg', width: 700, height: 500 },
		{ id: 1, src: '/images/places/card2.jpg', width: 700, height: 500 },
		{ id: 2, src: '/images/places/card3.jpg', width: 700, height: 500 },
		{ id: 3, src: '/images/places/card4.jpg', width: 700, height: 500 }
	]

	const [headerShow, setHeaderShow] = useState<boolean>(false)

	let { slug } = useParams<'slug'>()

	const {data:tour,isPending,error}  = useQueryTourBySlug(slug||'')
	
	const {data:blogs}  = useQueryBlogsByParams('',tour?.location_slug)


	useEffect(() => {
		const galleryEl = document.querySelector('#galery')

		let lightbox = new PhotoSwipeLightbox({
			dataSource: images,
			initialZoomLevel: 'fit',
			secondaryZoomLevel: 1.5,
			maxZoomLevel: 1,
			pswpModule: () => import('photoswipe')
		})

		//@ts-ignore
		lightbox.addFilter('thumbEl', (thumbEl, data, index) => {
			//@ts-ignore
			const el = galleryEl.querySelector('[data-id="' + data.id + '"] img')

			if (el) {
				return el
			}
			return thumbEl
		})

		lightbox.init()

		//@ts-ignore
		window.pswpLightbox = lightbox

		return () => {
			lightbox.destroy()
			//  @ts-ignore
			lightbox = null
		}
	}, [])

	const scrollPosition = useScroll()

	if (scrollPosition > 500 && !headerShow) {
		setHeaderShow(true)
	}

	if (scrollPosition < 500 && headerShow) {
		setHeaderShow(false)
	}


	return (
		<div className='tour'>
			<div className='tour__galery'>
				<div
					className='tour__view'
					onClick={() => {
						//@ts-ignore
						pswpLightbox.loadAndOpen(0)
						return false
					}}
				>
					<span>Смотреть фото</span>
				</div>
				<div className='tour__images' id='galery'>
					{images.map(m => (
						<div
							key={m.id}
							className='tour__image'
							data-id={m.id}
							onClick={() => {
								//@ts-ignore
								pswpLightbox.loadAndOpen(m.id)
								return false
							}}
						>
							<img className='tour__img' src={m.src} alt='' />
							<div className='tour__shadow'>
								<img width={40} height={40} src={loopImg} alt='' />
							</div>
						</div>
					))}
				</div>
			</div>
			<TourHeader isShow={headerShow} />
			<div className='tour__container'>
				<div className='tour__block'>
					<h1 className='tour__title title-big left blue' id='#intro'>
						{tour?.name}
					</h1>
					<div className='tour__location'>
						<span className='fi-rs-land-layer-location'></span>
       
						<span className='text'>{tour?.location}</span>
					</div>
					<div className='tour__options'>
						<div className='tour__option'>
							<img
								width={30}
								height={30}
								className='tour__option-img'
								src={guidImg}
							/>
							<span className='tour__option-text text-big'>Fully Guided</span>
						</div>
						<div className='tour__option'>
							<img
								width={30}
								height={30}
								className='tour__option-img'
								src={moonImg}
							/>
							<span className='tour__option-text text'>7 Nights</span>
						</div>
						<div className='tour__option'>
							<img
								width={30}
								height={30}
								className='tour__option-img'
								src={airplaneImg}
							/>
							<span className='tour__option-text text'>Flights & Transfers</span>
						</div>
					</div>
					<ul className='tour__list'>
						<li className='tour__item'>
							<span className='fi-rs-check-circle'></span>
							<p className='text'>
								Единственное озеро в Таиланде, которое находится в обеих
								провинциях, Пхаттхалунг и Сонгкхла. Его площадь составляет около
								1000 квадратных километров.{' '}
							</p>
						</li>
						<li className='tour__item'>
							<span className='fi-rs-check-circle'></span>
							<p  className='text'>
								Самый большой остров, расположенный на озере Сонгкхла, и одно из
								туристических направлений, которое вам стоит посетить.
							</p>
						</li>
						<li className='tour__item'>
							<span className='fi-rs-check-circle'></span>
							<p className='text'>
								Еще одно место для хипстеров, расположенное в центре города. Не
								пропустите эту улицу, так как она полна интересных занятий
							</p>
						</li>
						<li className='tour__item'>
							<span className='fi-rs-check-circle'></span>
							<p  className='text'>
								Не пропустите эту достопримечательность, когда вы в Сонгкла.
								Красивая и знаменитая статуя русалки является изюминкой пляжа
								Самила.
							</p>
						</li>
						<li className='tour__item'>
							<span className='fi-rs-check-circle'></span>
							<p className='text'>
								Длинный, красивый пляж, который также соединен с пляжем Самила.
								Но этот пляж Чалатат более популярен среди местных жителей с
								муниципальным парком для занятий спортом, например, ходьбой или
								ездой на велосипеде.
							</p>
						</li>
						<li className='tour__item'>
							<span className='fi-rs-check-circle'></span>
							<p className='text'>
								Храм находится на острове Йо и является одним из интересных мест
								в Сонгкхле. Этот буддийский храм также является местом с самой
								большой статуей Лежащего Будды в Таиланде, и еще множеством
								других статуй.
							</p>
						</li>
						<li className='tour__item'>
							<span className='fi-rs-check-circle'></span>
							<p className='text'>
								Не забудьте посетить еще одно яркое место в Хатъяй, а именно
								парк Хатъяй. Зеленый парк, который охватывает всю гору, площадью
								около 316 акров. Запрыгните на единственную канатную дорогу,
								доступную в Таиланде, здесь
							</p>
						</li>
					</ul>
				</div>

				<div className='tour__widget hidden lg:block'>
					<BookWidget type={EnumTourType.Tours} />
				</div>
			</div>

			<div className='tour__container2'>
				<h2 className='tour__subtitle subtitle blue'>
					ElephantDomecticsFlights
				</h2>
				<p className='text-big'>
					Бронируйте дешевые авиабилеты на ElephantDomecticsFlights по различным
					маршрутам и получите еще больше скидок на авиабилеты!
				</p>
			</div>
			<div className='tour__itinerary itinerary' id='#itinerary'>
				<div className='itinerary__left'>
					<div className='itinerary__label subtitle left blue'>Карта тура</div>
					<img className='itinerary__image' src={mapImg} alt='' />
				</div>
				<div className='itinerary__right'>
					<div className='itinerary__top'>
						<h2 className='itinerary__title subtitle blue'>Маршрут тура</h2>
						<div className='itinerary__speed'>
							<img width={125} height={60} src={speedImg} />
							<span className='itinerary__speed-text text blue'>
								Уровень активности: Низкая
							</span>
						</div>
					</div>
					<div className='itinerary__steps'>
						<Steps />
					</div>
				</div>
			</div>
			<div className='tour__blog section' id='#blog'>
				<div className='tour__blog-title subtitle center blue'>
					Больше в нашем блоге
				</div>
				<div className='tour__blog-container'>
					{blogs?.map(item => (
						<CardTB
							key={item.id}
							image={item.image}
							imageSrc='places'
							name={item.title}
							slug={item.slug}
						/>
					))}
				</div>
			</div>
			<BookWidgetMobile type={EnumTourType.Tours} />

		</div>
	)
}

export default Tour
